
  import { ref, computed, onMounted } from 'vue'
  import store from '@/store'
  import SwiperCore, { Navigation } from 'swiper'
  import { Swiper, SwiperSlide } from 'swiper/vue'
  import { useI18n } from 'vue-i18n'

  import 'swiper/swiper.min.css'

  SwiperCore.use([Navigation])

  const statTranslates = {
    en: 'name_en',
    ru: 'name_ru',
    kz: 'name_kz'
  }

  export default {
    components: {
      Swiper,
      SwiperSlide
    },
    setup(props, context) {
      const { locale } = useI18n()
      const prev = ref(null)
      const next = ref(null)
      const perSlide = ref(2.5)
      const swiperRef = ref(null)

      const arrayPhoto = [
        'MaskGroup(3).png',
        'MaskGroup.png',
        'MaskGroup(1).png',
        'MaskGroup(2).png'
      ]

      const reports = computed(() => {
        return (
          store.getters._getFrequentlyReports?.map((e) => {
            return {
              ...e,
              image: getNextImg()
            }
          }) || []
        )
      })

      onMounted(async () => {
        await store.dispatch('_setFrequentlyReports')

        if (document.body.clientWidth < 576) perSlide.value = 1.2
        else perSlide.value = 2.5
      })

      function getRef(swiperInstance) {
        swiperRef.value = swiperInstance
      }

      function* getImage() {
        let i = 0

        while (true) {
          if (i >= arrayPhoto.length - 1) i = 0
          else i += 1
          yield arrayPhoto[i]
        }
      }

      const gen = getImage()

      function getNextImg() {
        return gen.next().value
      }

      function getStatTreeName(stat) {
        return stat[statTranslates[locale.value]]
      }

      function swiperKey(e) {
        if (e.keyCode == 9) swiperRef.value.scrollLeft = 0
      }

      return {
        getStatTreeName,
        prev,
        next,
        reports,
        perSlide,
        swiperKey,
        swiperRef,
        getRef
      }
    }
  }
