
  import { ref, computed, onMounted } from 'vue'
  import store from '@/store'

  export default {
    setup() {
      const count = ref(14)

      const genderValue = computed(() => {
        return store.getters._getMonthGender?.map(
          (el) => +el?.percentage.split('%')[0]
        )
      })
      const manArray = computed(() => {
        if (!genderValue.value) return []
        return new Array(count.value).fill(null).map((_, ind) => {
          if (ind < Math.round(genderValue.value[1] * count.value / 100))
            return '#00ead0'
          return 'rgba(103, 109, 115, 0.15)'
        })
      })
      const womanArray = computed(() => {
        if (!genderValue.value) return []
        return new Array(count.value).fill(null).map((_, ind) => {
          if (ind < Math.round(genderValue.value[0] * count.value / 100))
            return 'rgb(255, 41, 71)'
          return 'rgba(103, 109, 115, 0.15)'
        })
      })

      onMounted(() => {
        store.dispatch('_setGenders')
        const w = document.body.offsetWidth
        if (w < 768) count.value = 8
        else if (w < 1600) count.value = 10
        else count.value = 14
      })

      return {
        genderValue,
        womanArray,
        manArray
      }
    }
  }
