
  import { ref } from 'vue'

  export default {
    setup() {
      const CITIES = [
        { name: 'nur_sultan', href: '/astana' },
        { name: 'almaty', href: '/almaty' },
        { name: 'shymkent', href: '/shymkent' },
        { name: 'aqmola', href: '/akmola' },
        { name: 'aqtobe', href: '/aktobe' },
        { name: 'almatyObl', href: '/almatyobl' },
        { name: 'atyrau', href: '/atyrauobl' },
        { name: 'vko', href: '/vko' },
        { name: 'jambyl', href: '/zhambylobl' },
        { name: 'zko', href: '/ZKO' },
        { name: 'karagandy', href: '/Karaganda' },
        { name: 'qostanai', href: '/kostanay' },
        { name: 'qyzylorda', href: '/kyzylorda' },
        { name: 'mangystau', href: '/mangystau' },
        { name: 'pavlodar', href: '/pavlodar' },
        { name: 'sko', href: '/SKO' },
        { name: 'turkistan', href: '/Turkystan' }
      ]

      const activeButtonRegion = ref(false)

      const toggleActiveButton = () => {
        activeButtonRegion.value = !activeButtonRegion.value
      }

      return {
        CITIES,
        activeButtonRegion,
        toggleActiveButton
      }
    }
  }
