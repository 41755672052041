
  import { defineComponent, reactive, ref } from 'vue'
  import { api } from '@/api'

  import { QuillEditor } from '@vueup/vue-quill'
  import '@vueup/vue-quill/dist/vue-quill.snow.css'
  import router from '@/router'

  export default defineComponent({
    components: {
      QuillEditor
    },
    setup() {
      const imagePreview = ref()
      const errorMessage = ref(false)
      const changeactive = ref(false)
      let html = ref('')
      let errorExit = ref(false)

      const data = reactive({
        heading: '',
        Subtitle: '',
        message: '',
        newstext: '',
        date: '',
        image: '',
        seo_title: '',
        seo_description: '',
        seo_keywords: '',
        inputfiles: [],

        checked: false
      })

      var formData = new FormData()
      function fileChange(event) {
        formData.set('image', event.target.files[0])
        let file = event.target.files[0]

        let reader = new FileReader()
        reader.addEventListener('load', function () {
          imagePreview.value = reader.result
        })
        reader.readAsDataURL(file)
      }

      const createArticle = async () => {
        errorMessage.value = false
        errorExit.value = false
        try {
          formData.set('title', data.heading)
          formData.set('alias', data.Subtitle)
          formData.set('date', data.date)
          formData.set('content', html.value)
          formData.set('seo_description', data.seo_description)
          formData.set('seo_title', data.seo_title)
          formData.set('seo_keywords', data.seo_keywords)
          changeactive.value = false
          const response = await api.post('/v1/admin/article/create', formData)
          changeArticle()
          // window.location.reload()
        } catch (error) {
          console.error(error)
          errorMessage.value = true
          errorExit.value = true
        }
      }

      function changeArticle() {
        router.push({ name: 'adminnews' })
      }

      return {
        fileChange,
        createArticle,
        data,
        imagePreview,
        changeactive,

        errorMessage,
        html,
        errorExit,
        changeArticle
      }
    },
    render: function (createElement) {
      return createElement('h1', this.html)
    }
  })
