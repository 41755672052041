
  import { defineComponent, reactive, ref } from 'vue'
  import AdminMainTopLine from '@/components/AdminMainTopLine.vue'
  import LineChart from '@/components/LineChart.vue'
  import { api } from '@/api'
  import AdminAdaptiveMainTopLine from '@/components/AdminAdaptiveMainTopLine.vue'
  import router from '@/router'
  import { useRoute } from 'vue-router'

  export default defineComponent({
    components: {
      AdminMainTopLine,
      LineChart,
      AdminAdaptiveMainTopLine
    },
    setup() {
      let timer
      const articles = ref([])
      let changeactive = ref(false)
      let articleid = ref('')
      let data = reactive({
        word: ''
      })
      let linkPage = ref([])
      getArticles()
      async function getArticles() {
        try {
          const response = await api.get('/v1/admin/articles')
          articles.value = response.data.data.articles.data
          linkPage.value = response.data.data.articles.links
          linkPage.value[0].label = '&#11160;'
          linkPage.value[linkPage.value.length - 1].label = '&#11162;'
        } catch (error) {
          console.error(error)
        }
      }
      async function removeArticleById(id) {
        try {
          const response = await api.delete('/v1/admin/article/delete/' + id)
          articles.value = articles.value.filter((a) => a.id !== id)
          changeactive.value = !changeactive.value
        } catch (error) {
          console.error(error)
        }
      }
      function endAndStartTimer() {
        window.clearTimeout(timer)
        timer = window.setTimeout(function () {
          searchArticles()
        }, 300)
      }
      async function searchArticles() {
        try {
          const response = await api.post('/v1/admin/article/search', {
            word: data.word
          })
          articles.value = response.data.data.article.data
        } catch (error) {}
      }
      async function pageUser(url) {
        try {
          const response = await api.get(url)
          articles.value = response.data.data.articles.data
          linkPage.value = response.data.data.articles.links
          linkPage.value[0].label = '&#11160;'
          linkPage.value[linkPage.value.length - 1].label = '&#11162;'
        } catch (error) {
          console.error(error)
        }
      }
      const articleChange = async (id) => {
        await router.push({
          name: 'PanelAdminArticleChange',
          params: {
            articleId: id
          }
        })
      }
      return {
        articles,
        removeArticleById,
        changeactive,
        articleid,
        searchArticles,
        endAndStartTimer,
        data,
        pageUser,
        linkPage,
        articleChange
      }
    }
  })
