
  import { ref } from 'vue'

  export default {
    setup() {
      const items = ref([
        {
          icon: require('@/assets/icons/about-icon-1.png'),
          height: '129px',
          text: 'pages.home.systemInfo.0',
          y: '0px',
          color: '#944CEE',
          alt: 'Файл',
          title: 'Ведение статистического учета туристской деятельности',
        },
        {
          icon: require('@/assets/icons/about-icon-2.png'),
          height: '240px',
          text: 'pages.home.systemInfo.1',
          y: '35px',
          color: '#26DDBF',
          alt: 'Человек',
          title: 'Количество прибытий',
        },
        {
          icon: require('@/assets/icons/about-icon-3.png'),
          height: '131px',
          text: 'pages.home.systemInfo.2',
          y: '0px',
          color: '#92DAE5',
          alt: 'График',
          title: 'Аналитика туристического потока',
        },
        {
          icon: require('@/assets/icons/about-icon-4.png'),
          height: '240px',
          text: 'pages.home.systemInfo.3',
          y: '35px',
          color: '#DF8E44',
          alt: 'Экран',
          title: 'Доступность статистических данных',
        },
        {
          icon: require('@/assets/icons/about-icon-5.png'),
          height: '139px',
          text: 'pages.home.systemInfo.4',
          y: '0px',
          color: '#BCA26F',
          alt: 'Календар',
          title: 'Срок пребывания (трудности)',
        },
        {
          icon: require('@/assets/icons/about-icon-6.png'),
          height: '240px',
          text: 'pages.home.systemInfo.5',
          y: '35px',
          color: '#5D8BE6',
          alt: 'Копейка',
          title: 'Сумма расходов туристов в календарном году',
        }
      ])

      return {
        items
      }
    }
  }
