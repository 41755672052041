
  import {
    defineComponent,
    onBeforeMount,
    onBeforeUpdate,
    onMounted,
    onUpdated,
    ref
  } from 'vue'
  import MainTopLine from '@/components/MainTopLine.vue'
  import LineChart from '@/components/LineChart.vue'
  import AdaptiveMainTopLine from '@/components/AdaptiveMainTopLine.vue'
  import 'swiper/components/effect-fade/effect-fade.min.css'
  import RegionGraphics from '@/components/RegionGraphics.vue'
  import Downloaded from '@/components/download.vue'
  import SwiperCore, { Navigation, EffectFade } from 'swiper'
  import { Swiper, SwiperSlide } from 'swiper/vue'
  SwiperCore.use([Navigation, EffectFade])

  export default defineComponent({
    components: {
      AdaptiveMainTopLine,
      MainTopLine,
      LineChart,
      Swiper,
      SwiperSlide,
      RegionGraphics,
      Downloaded
    },
    setup() {
      let downloaded = ref(false)
      let graphics = ref('line')
      const active = ref(true)
      const toggleActive = () => {
        active.value = !active.value
      }

      onMounted(() => {
        let img = document.querySelector('.left-region-image').children[0]
        img.addEventListener('load', () => {
          downloaded.value = true
        })
      })
      return {
        active,
        toggleActive,
        graphics,
        downloaded
      }
    },
    methods: {
      onSwiper(swiper) {},
      onSlideChange() {}
    }
  })
