
  import {
    defineComponent,
    onMounted,
    onUpdated,
    reactive,
    ref,
    toRefs
  } from 'vue'
  import Modal from '@/components/Modal.vue'
  import { api } from '@/api'
  import { useRouter } from 'vue-router'
  import router from '@/router'
  import { AuthService } from '@/services/auth.service'

  export default defineComponent({
    components: { Modal },
    inject: [AuthService.TOKEN],
    setup() {
      let forgotEmail = ref('')
      let forgotIsActive = ref(false)
      let notFoundEmail = ref(false)
      const data = reactive({
        email: '',
        password: '',
        regisemail: '',
        regispassword: '',
        regispassword_repl: ''
      })
      let errorEmail = ref(false)
      const userInfo = reactive({ roles: '', first_name: '', email: '' })
      let success = ref(false)
      let active = ref(false)
      let loginactive = ref(false)
      let active1 = ref(false)
      globalThis.userInformation = {
        isadmin: false,
        fullName: '',
        role: '',
        email: ''
      }
      const inputPassword = ref<HTMLInputElement>()
      const togglePasswordAttribute = () => {
        if (inputPassword.value) {
          if (inputPassword.value.type == 'text') {
            inputPassword.value.type = 'password'
          } else {
            inputPassword.value.type = 'text'
          }
        }
      }

      async function signIn() {
        try {
          const response = await api.post('/auth/login', {
            email: data?.email,
            password: data?.password
          })
          const token = response.data.token
          localStorage.setItem('token', token)
          await getProfile()
        } catch (error) {
          console.error(error)
          loginactive.value = true
        }
      }
      async function getProfile() {
        try {
          const response = await api.get('/v1/profile')
          userInfo.roles = response.data.data.user.roles
          userInfo.first_name = response.data.data.user.first_name
          userInfo.email = response.data.data.user.email

          if (userInfo.roles == 'Admin') {
            await router.push({ name: 'admin' })
          } else {
            await router.push({ name: 'profile' })
          }
          localStorage.setItem('role', userInfo.roles)
        } catch (error) {
          console.error(error)
        }
      }
      const registor = async () => {
        try {
          const response = await api.post('/auth/register', {
            email: data.regisemail,
            password: data.regispassword,
            password_repl: data.regispassword_repl
          })
          active.value = !active.value
          active1.value = !active1.value
        } catch (error) {
          console.error(error)
        }
      }
      const inputPassword1 = ref<HTMLInputElement>()
      const inputPassword2 = ref<HTMLInputElement>()
      const togglePasswordAttribute1 = () => {
        if (inputPassword1.value) {
          if (inputPassword1.value.type == 'text') {
            inputPassword1.value.type = 'password'
          } else {
            inputPassword1.value.type = 'text'
          }
        }
      }
      const togglePasswordAttribute2 = () => {
        if (inputPassword2.value) {
          if (inputPassword2.value.type == 'text') {
            inputPassword2.value.type = 'password'
          } else {
            inputPassword2.value.type = 'text'
          }
        }
      }
      let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
    width=0,height=0,left=-1000,top=-1000`
      async function forgotPassword() {
        // try {
        //     forgotIsActive.value = false
        //     const response = await api.post('/auth/password/forgot', {
        //         email: data.email
        //     })
        //     forgotIsActive.value = true
        //     window.open('about:blank', 'forgot', params)
        // } catch (error) {
        //     console.error(error)
        // }
        forgotIsActive.value = true
      }
      async function getEmail() {
        try {
          const response = await api.post('/auth/password/forgot', {
            email: forgotEmail.value
          })
          notFoundEmail.value = false
          errorEmail.value = false
          if (response.status == 201) {
            success.value = true
          }
        } catch (error) {
          if (error.response.status == 401) {
            notFoundEmail.value = true
            errorEmail.value = false
          }
          if (error.response.status == 422) {
            errorEmail.value = true
            notFoundEmail.value = false
          }
        }
      }
      let message
      onMounted(() => {
        message = document.querySelectorAll('.error-message')
        console.log('message', message)
      })
      onUpdated(() => {
        message[0].addEventListener('animationend', () => {
          errorEmail.value = false
          notFoundEmail.value = false
        })
        message[1].addEventListener('animationend', () => {
          errorEmail.value = false
          notFoundEmail.value = false
        })
      })

      return {
        forgotIsActive,
        active,
        inputPassword,
        togglePasswordAttribute,
        ...toRefs(data),
        signIn,
        registor,
        loginactive,
        togglePasswordAttribute1,
        togglePasswordAttribute2,
        inputPassword1,
        inputPassword2,
        active1,
        forgotPassword,
        getEmail,
        notFoundEmail,
        errorEmail,
        success,
        forgotEmail
      }
    }
  })
