export default function getItemByOrder<T>(list: T[]): () => T {
  function* getItem(): Generator<T> {
    let i = 0

    while (true) {
      if (i >= list.length - 1) i = 0
      else i += 1
      yield list[i]
    }
  }

  const generator = getItem()

  function getNext() {
    return generator.next().value
  }

  return getNext
}
