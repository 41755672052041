
import MainHeader from '@/components/main/MainHeader.vue'
import MainFooter from '@/components/MainFooter.vue'
import FeedBack from '@/components/main/FeedBack.vue'

export default {
  components: {
    MainHeader,
    MainFooter,
    FeedBack,
  },
}
