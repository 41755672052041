
  import { defineComponent } from 'vue'

  import SectionTitle from '@/components/ui/SectionTitle.vue'
  import HomeBanner from '@/components/pages/home/HomeBanner.vue'
  import Top10Country from '@/components/pages/home/Top10Country.vue'
  import StatGender from '@/components/pages/home/StatGender.vue'
  import StatAge from '@/components/pages/home/StatAge.vue'
  import StatPlacement from '@/components/pages/home/StatPlacement.vue'
  import DigestLine from '@/components/pages/home/DigestLine.vue'
  import DigestCircle from '@/components/pages/home/DigestCircle.vue'
  import CreateReport from '@/components/pages/home/CreateReport.vue'
  import HomeAbout from '@/components/pages/home/HomeAbout.vue'
  import HomeNews from '@/components/pages/home/HomeNews.vue'
  import FaqBlock from '@/components/pages/home/FaqBlock.vue'
  
  export default defineComponent({
    components: {
      SectionTitle,
      HomeBanner,
      Top10Country,
      StatGender,
      StatAge,
      StatPlacement,
      DigestLine,
      DigestCircle,
      CreateReport,
      HomeAbout,
      HomeNews,
      FaqBlock,
    }
  })
