
  import {
    defineComponent,
    onMounted,
    onUnmounted,
    ref,
    watch,
    toRefs
  } from 'vue'
  import Chart from 'chart.js'

  export default defineComponent({
    props: {
      type: String,
      data: {
        type: Object,
        requred: true
      },
      options: {
        type: Object,
        requred: true
      }
    },
    setup(opts) {
      const { type } = toRefs(opts)
      const { data } = toRefs(opts)
      const canvasElem = ref()
      let myChart

      onMounted(() => {
        let ctx = canvasElem.value.getContext('2d')
        ctx.canvas.style.borderColor = 'white'
        ctx.canvas.style.fill = 'false'
        ctx.canvas.style.backgroundColor = 'white'
        myChart = new Chart(ctx, {
          type: opts.type,
          data: opts.data,
          options: opts.options
        })
        myChart.canvas.style.fillOpacity = 'none'
        myChart.canvas.style.fill = 'false'
      })

      watch(type, () => {
        let ctx = canvasElem.value.getContext('2d')
        ctx.canvas.style.fill = 'false'

        if (myChart) myChart.destroy()

        myChart = new Chart(ctx, {
          type: opts.type,
          data: opts.data,
          options: opts.options
        })

        myChart.canvas.style.fill = 'false'
      })

      watch(data, () => {
        var ctx = canvasElem.value.getContext('2d')

        if (myChart) myChart.destroy()

        myChart = new Chart(ctx, {
          type: opts.type,
          data: opts.data,
          options: opts.options
        })
      })

      onUnmounted(() => {
        if (myChart) myChart.destroy()
      })

      return {
        canvasElem
      }
    }
  })
