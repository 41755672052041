<template>
  <line-chart
    :key="reloadCharts"
    :type="chart === 'multi' ? 'line' : chart"
    :data="{
      labels: labels,
      datasets: data
    }"
    :options="
      chart !== 'multi'
        ? {
            legend: {
              display: false
            },
            responsive: true,
            maintainAspectRatio: false,
            scales:
              chart !== 'pie' && chart !== 'doughnut' && chart !== 'polarArea'
                ? {
                    yAxes: [
                      {
                        ticks: { beginAtZero: true }
                      }
                    ]
                  }
                : {}
          }
        : {
            responsive: true,
            interaction: {
              mode: 'index',
              intersect: false
            },
            stacked: false,
            plugins: {
              title: {
                display: true,
                text: 'Chart.js Line Chart - Multi Axis'
              }
            },
            scales: {
              y: {
                type: 'linear',
                display: true,
                position: 'left'
              },
              y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                  drawOnChartArea: false
                }
              }
            }
          }
    "
  />
</template>

<script>
  import LineChart from '@/components/LineChart.vue'
  import { onMounted, ref, watch } from '@vue/runtime-core'

  export default {
    name: 'AllCharts',
    components: {
      LineChart
    },
    props: {
      data: {
        type: Array,
        default: () => []
      },
      labels: {
        type: Array,
        default: () => []
      },
      chart: {
        type: String,
        default: 'line'
      }
    },
    setup(props) {
      // const reloadCharts = ref(0)

      // watch(
      //   () => props,
      //   () => {
      //     reloadCharts.value++
      //     console.log('asd', props.data)
      //   },
      //   {
      //     deep: true
      //   }
      // )
      return {}
    }
  }
</script>

<style scoped></style>
