<template>
  <div class="container mb-4">
    <div class="row">
      <div class="col-3">
        <router-link :to="{ name: 'statisticsthree' }">
          <div class="billutin-category">
            <svg-icon name="_2774131464368" />
            <p>{{ $t('breadCrumbs.TouristServices') }}</p>
          </div>
        </router-link>
      </div>
      <div class="col-3">
        <router-link :to="{ name: 'StatisticsExpensesOnTravels' }">
          <div class="billutin-category">
            <svg-icon name="iconexpe" />
            <p>{{ $t('breadCrumbs.TravelExpenses') }}</p>
          </div>
        </router-link>
      </div>
      <div class="col-3">
        <router-link :to="{ name: 'StatisticsVisitingVisitors' }">
          <div class="billutin-category">
            <SvgIcon name="iconmest" />
            <p>{{ $t('breadCrumbs.OutboundVisitors') }}</p>
          </div>
        </router-link>
      </div>
      <div class="col-3">
        <router-link :to="{ name: 'StatisticsRoutesAndAttractions' }">
          <div class="billutin-category">
            <SvgIcon name="StatisticsRoutesAndAttractions" />
            <p>{{ $t('breadCrumbs.StatisticsRoutesAndAttractions') }}</p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent, reactive } from '@vue/runtime-core'
  import { api } from '@/api/index.ts'
  import { ref } from 'vue'
  export default defineComponent({
    setup() {
      let category = ref([])
      async function getBulletinCAategory() {
        try {
          const response = await api.get('v1/bulletin-categories')
          category.value = response.data
        } catch (error) {}
      }
      getBulletinCAategory()
    }
  })
</script>
<style lang="scss" scoped>
  a {
    text-decoration: none;
  }
  .billutin-category {
    height: 70px;
    padding: 10px;
    transition: 1s ease;
    text-transform: uppercase;
    background: #f4f4f4;
    border-radius: 20px 20px 0px 0px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    .svg-icon {
      margin-right: 30px;
      color: rgba(103, 109, 115, 0.5);
      width: 50px;
      height: 40px;
    }
    img {
      margin: 17px 0;
      margin-right: 30px;
    }
    p {
      transition: 1s ease;
      font-family: 'Museo Sans Cyrl';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      /* identical to box height, or 175% */

      text-transform: uppercase;
      color: rgba(103, 109, 115, 0.5);
    }
    &:hover {
      cursor: pointer;
    }
  }
  .active-tourist {
    transition: 0.5s ease;
    background: #fdeacf;
    p {
      color: #f5980d !important;
      font-weight: 500;
    }
    svg {
      color: #f5980d !important;
    }
  }
  .active-expenses {
    transition: 0.5s ease;
    background: #e1dbff;
    p {
      color: #6a4bff !important;
      font-weight: 500;
    }
    svg {
      color: #6a4bff !important;
    }
  }
  .active-visiting {
    transition: 0.5s ease;
    background: #fedcdc !important;
    p {
      color: #f95050 !important;
      font-weight: 500;
    }
    svg {
      color: #f95050 !important;
    }
  }
  .StatisticsRoutesAndAttractions {
    transition: 0.5s ease;
    padding-left: 10px;
    background: #beb1ff !important;
    p {
      color: #6a4bff !important;
      font-weight: 500;
    }
    svg {
      color: #6a4bff !important;
    }
  }
</style>
