
  import { defineComponent, reactive, ref } from 'vue'
  import { api } from '@/api'

  import { Quill, QuillEditor } from '@vueup/vue-quill'
  import '@vueup/vue-quill/dist/vue-quill.snow.css'
  import { useRoute } from 'vue-router'
  import router from '@/router'
  export default defineComponent({
    components: {
      QuillEditor
    },
    setup() {
      const articleId = ref('')
      const imagePreview = ref()
      const errorMessage = ref(false)
      const changeactive = ref(false)
      let html = ref('begin text')
      let editorOption = ref({})
      let errorExit = ref(false)
      let render = ref(false)

      let data = reactive({
        heading: '',
        Subtitle: '',
        message: '',
        newstext: '',
        date: '',
        image: '',
        seo_title: '',
        seo_description: '',
        seo_keywords: '',
        inputfiles: [],

        checked: false
      })

      var formData = new FormData()
      function fileChange(event) {
        formData.set('file', event.target.files[0])
        let file = event.target.files[0]

        let reader = new FileReader()
        reader.addEventListener('load', function () {
          imagePreview.value = reader.result
        })
        reader.readAsDataURL(file)
      }

      const createArticle = async () => {
        errorMessage.value = false
        errorExit.value = false
        try {
          formData.set('title', data.heading)
          formData.set('alias', data.Subtitle)
          formData.set('date', data.date)
          formData.set('content', html.value)
          formData.set('seo_description', data.seo_description)
          formData.set('seo_title', data.seo_title)
          formData.set('seo_keywords', data.seo_keywords)

          const response = await api.put(
            'v1/admin/article/edit/' + articleId.value,
            formData
          )
          changeArticle()
          changeactive.value = false
          // await router.push({ name: 'adminnews' })
        } catch (error) {
          console.error(error)
          errorMessage.value = true
          errorExit.value = true
        }
      }
      const route = useRoute()
      articleId.value = route.params.articleId.toString()
      async function getArticle() {
        try {
          render.value = false
          const response = await api.get('/v1/news/' + articleId.value)
          let article = response.data.data.article
          let text = article.content
          await addBio(text)

          let str = article.created_at
          let from = str.search('T')
          article.created_at = str.substring(0, from)

          data.heading = article.title
          data.Subtitle = article.alias
          data.date = article.created_at
          data.seo_description = article.seo_description
          data.seo_title = article.seo_title
          data.seo_keywords = article.seo_keywords
          render.value = true
        } catch (error) {
          console.error(error)
        }
      }
      getArticle()

      function changeArticle() {
        router.push({ name: 'adminnews' })
      }
      function onEditorChange(e) {
        console.log('istep tur', e)
      }
      function addBio(bio) {
        html.value = bio
      }
      return {
        fileChange,
        createArticle,
        data,
        imagePreview,
        changeactive,
        errorMessage,
        html,
        errorExit,
        changeArticle,
        editorOption,
        onEditorChange,
        render
      }
    },
    render: function (createElement) {
      console.log('uistep jatir')
      return createElement('h1', this.html)
    }
  })
