
import { defineComponent, ref, computed, onMounted } from 'vue'
import { api } from '@/api'

import BreadCrumbs from '@/components/ui/BreadCrumbs.vue'
import SectionTitle from '@/components/ui/SectionTitle.vue'

export default defineComponent({
  components: {
    BreadCrumbs,
    SectionTitle,
  },
  setup() {
    let news = ref([])

    const getFile = computed(() => (file) => {
      return `${process.env.VUE_APP_FILES_PATH}${file}`
    })

    async function getArticles() {
      try {
        const response = await api.get('/v1/news')

        news.value = response.data.data.articles.map((a) => {
          return {
            ...a,
            created_at: a.created_at.split('T')[0]
          }
        })
      } catch (error) {
        console.error(error)
      }
    }

    onMounted(() => {
      getArticles()
    })

    return {
      news,
      getFile
    }
  }
})
