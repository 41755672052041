import { api } from '@/api/index.ts'

export async function getDigest(id) {
  const res = await api.get(`/v1/chart/categories/${id}/bulletins`)
  return res.data
}

export async function getDigestid(category = 1, id) {
  try {
    const res = await api.get(
      `/v1/chart/categories/${category}/bulletins/${id}`
    )
    return res.data
  } catch (error) {
    return { err: error, message: 'ошибка сервера' }
  }
}

export async function getDigestidPdf() {
  try {
    const res = await api.get('https://back.tourstat.kz/Kazakhstan_2022.pdf')
    console.log('pdf', res)
    return res.data
  } catch (error) {
    return { err: error, message: 'ошибка сервера' }
  }
}
