export const ru = {
  trour: '29 октября 2023 года – День общенационального траура в Республике Казахстан',
  pages: {
    tourBase: {
      title: 'База туроператоров',
      table: {
        order: '№',
        licensor: 'Лицензиар',
        number: 'Номер лицензии',
        name: 'Наименование',
        iin: 'ИИН/БИН',
        issue_at: 'Дата выдачи',
        address: 'Адрес',
        subspecies: 'Подвид',
      }
    },
    home: {
      banner: {
        title: 'Tourstat.kz',
        subtitle:
          'Информационная система по сбору и обработке данных в сфере туризма'
      },
      top10MonthCountry: 'ТОП 10 стран в этом месяце',
      title1: 'Портрет туриста в этом месяце',
      title2: 'Статистика туризма',
      title3: 'Дайджесты',
      title4: 'СОЗДАНИЕ ОТЧЕТА',
      title5: 'О системе',
      title6: 'Новости',
      title7: 'FAQ',
      createReportTitle:
        'В данном блоке вы можете сформировать отчет со статистическими данными в удобном для вас формате PDF или Excel.',
      aboutSystem:
        'Информационная система Турстат осуществляет статистический учёт туристской деятельности Республике Казахстан и предоставляет аналитику туристического потока в разных разрезах. \n Система создана в рамках реализации Государственной программы развития туристской отрасли на 2019–2025 годы Министерством культуры и спорта Республики Казахстан.',
      systemInfo: {
        0: 'Ведение статистического учета \n туристской деятельности',
        1: 'Количество прибытий',
        2: 'Аналитика \n туристического потока',
        3: 'Доступность \n статистических данных',
        4: 'Срок пребывания',
        5: 'Сумма расходов \n туристов в календарном \n году'
      }
    },
    statistics: {
      tabsMain: {
        title1: 'Туристические услуги',
        title2: 'Расходы на поездки',
        title3: 'Выездные посетители',
        title4: ' Маршруты и достопримечательности',
      },
      title1: 'Статистика туризма',
      title2: 'ЧАСТО ПРОСМАТРИВАЕМЫЕ ПОКАЗАТЕЛИ',
    },
    about: {
      title1: 'О системе',
      title2: 'СТАТИСТИКА ПО ТУРИЗМУ В КАЗАХСТАНЕ: ДЛЯ ЧЕГО ОНА НУЖНА?',
      text1: 'Информационная система Турстат осуществляет статистический учет туристской деятельности в Республике Казахстан и предоставляет аналитику туристического потока в разных разрезах.',
      text2: 'Система создана в рамках реализации Государственной программы развития туристской отрасли на 2019-2025 годы Министерством культуры и спорта Республики Казахстан.',
      list: {
        0: 'Ведение статистического учета туристской деятельности',
        1: 'Доступность статистических данных',
        2: 'Аналитика туристического потока',
        3: 'Количество прибытий',
        4: 'Срок пребывания (туродни)',
        5: 'Сумма расходов туристов в календарном году'
      },
      text3: 'Статистика туризма в Казахстане – это основа для принятия правильных стратегических решений как в рамках конкретного бизнеса, так и индустрии в целом. На базе этой информации строятся краткосрочные и долгосрочные планы развития, выбираются и появляются новые ниши.',
      text4: 'Статистика по туризму отражает то, что реально происходит на рынке. Всесторонняя объективная информация позволяет организациям отрасли анализировать рыночные тренды, строить прогнозы и принимать решения об инвестициях, расширении спектра туристских продуктов и услуг.',
      text5: 'Информация на портале будет полезна всем, кто связан с туристической индустрией. Статистические данные затрагивают международный и внутренний туризм в Казахстане, статистика Турстат позволяет изучить основные показатели на уровне страны в целом и отдельных регионов.',
      text6: 'Статистика туризма по регионамдает представление о состоянии туристского рынка для эффективного ведения бизнеса. Она будет полезна научным и учебным заведениям в качестве информации об основных итогах и тенденциях развития сферы туризма.Все данные, представленные на этом сайте статистики туризма, могут использоваться в коммерческих, научных и частных целях со ссылкой на источник.',
    },
    news: {
      title: 'Последние новости туризма: Будьте в курсе всех событий',
      title1: 'Новости',
      description:
        'Будьте в курсе всех последних событий в сфере туризма с нашим разделом новостей. Мы предоставляем свежие и актуальные новости о туристической индустрии, путешествиях, экскурсиях и интересных местах.'
    },
    digest: {
      title: 'Анализ международных прибытий и выездов',
      title1: 'Дайджест',
      description:
        'Узнайте количество выездов за границу, изучите данные о международных туристах и их основных целях поездки.'
    },

    default: {
      title: 'Информационная система база данных',
      description:
        'Информационная система по сбору и обработке статистических данных в сфере туризма.'
    },
    main: {
      title:
        'Добро пожаловать в Tourstat.kz - Ваш источник туристических данных и статистики',
      description:
        'Tourstat.kz - ваш надежный партнер в сфере туризма! Узнавайте актуальные статистические данные и информацию о туристической индустрии Казахстана. Присоединяйтесь к нам и расширяйте свои горизонты вместе с Tourstat.kz!'
    },
    touchBord: {
      title:
        'Откройте для себя портрет иностранного туриста, используя ИС eQonaq',
      description:
        'Получайте актуальные данные от различных мест размещения (отели, хостелы, базы отдыха) обеспечивает актуальную статистику от более 60% всех мест размещения в Казахстане.'
    },
    statisticsThree: {
      title:
        'Исследование туристической статистики: Места размещения, посетители и длительность пребывания',
      description:
        'Погрузитесь в мир статистики туризма! Узнайте количество мест размещения, анализируйте поток посетителей и изучайте длительность их пребывания.'
    },
    createReport: {
      title:
        'Статистика туризма: Создайте удобный отчет в формате PDF или Excel',
      description:
        'Исследуйте мир туристической статистики с помощью нашего сервиса. Здесь вы можете легко создать отчет с подробными статистическими данными в формате PDF или Excel.'
    }
  },
  commands: {
    enter: 'Войти',
    download: 'Скачать'
  },
  labels: {
    more: 'Подробнее',
    steps: 'ШАГ {0}',
    enterSearchData:
      'Введите в поле “Поиск” данные, которые вы хотели бы найти',
    selectPeriod: 'Выберите период',
    downloadReport: 'Скачать отчет',
    fromTourstat: 'от TourStat {0}',
    next: 'далее',
    yetStat: 'Еще статистика',
    year: '{0} год',
    feedback: 'Обратная связь',
    feedbackText: 'Если вы обнаружили какую-либо ошибку или проблему, пожалуйста, сообщите нам об этом, написав на электронный адрес:',
    noDataInDB: 'Нет данных в базе',
    ageMore55: ' 55 и старше',
    ageRest: ' 17 и менее',
    ageYears: ' лет',
    searchTourControlers: 'Поиск туроператора',
    find: 'Найти...',
    pageCounter: '{0} из {1}',
    noData: 'Нет данных',
  },
  placeholders: {
    enterName: 'Введите название'
  },
  footer: {
    author: 'Авторское право 2023 ⓒ Tourstat. Все права защищены.',
    sublinks: {
      1: 'Туристические услуги',
      2: 'Расходы на поездки',
      3: 'Места размещения',
      4: 'Маршруты и достопримечательности'
    },
  },
  menu: {
    mainSystem: 'Главная',
  },

  message: {
    greet: 'Привет.',
    title: 'Заголовок',
    banner: 'В cистеме ведутся технические работы',
    authorized:
      'Вы не авторизованы. Для скачивания отчета необходимо авторизоваться'
  },
  mainHeader: {
    buttons: {
      forBusiness: 'Для бизнеса',
      forTourist: 'Для туриста'
    },
    business: {
      link1: {
        title: 'Разместите свой бизнес на Национальном туристском портале',
        subtitle: 'Модуль «Коммерческая платформа»',
        link: 'https://kazakhstan.travel/marketplace'
      },
      link2: {
        title: 'Туристическая экосистема',
        subtitle: 'Модуль «Туризм онлайн»',
        link: 'https://www.tourismonline.kz/'
      },
      link3: {
        title: 'Стать гидом или экскурсоводом',
        subtitle: 'Модуль «Гиды»',
        link: 'https://guides.kazakhstan.travel/ru'
      },
      link4: {
        title: 'посмотреть статистику в сфере туризма',
        subtitle: 'Модуль «Tourstat»',
        link: 'https://tourstat.kz/'
      },
      link5: {
        title: 'Стать инвестором туристских проектов',
        subtitle: 'Модуль «Инвестиции»',
        link: 'https://invest.kazakhstan.travel/'
      },
      link6: {
        title: 'Отправить уведомление о прибытии иностранного гостя',
        subtitle: 'Информационная система «eQonaq»',
        link: 'https://eqonaq.kz'
      },
      link7: {
        title: 'Подать заявку на субсидирование',
        subtitle: 'Модуль «Субсидирование» ',
        link: 'https://subsidy.kazakhstan.travel/ru'
      },
      link8: {
        title: 'Стать участником mice мероприятий ',
        subtitle: 'Модуль «MICE»',
        link: 'https://kazakhstan.travel/mice'
      }
    },
    tourist: {
      link1: {
        title: 'О Казахстане',
        subtitle: 'Национальный туристский портал',
        link: 'https://kazakhstan.travel/'
      },
      link2: {
        title: 'Забронируйте лучшие экскурсии, отели, автомобили, кемпинги',
        subtitle: 'Модуль «Коммерческая платформа»',
        link: 'https://kazakhstan.travel/marketplace'
      },
      link3: {
        title: 'Выбрать гида или экскурсовода',
        subtitle: 'Модуль «Гиды»',
        link: 'https://guides.kazakhstan.travel/ru'
      },
      link4: {
        title: '750-летие Золотой ордЫ  ',
        subtitle: 'Модуль «Золотая орда»',
        link: 'https://altynorda.kazakhstan.travel/'
      },
      link5: {
        title: 'пейзажи казахстана в лучшем качестве  ',
        subtitle: 'Модуль «Photobank»',
        link: 'https://kazakhstan.travel/photobank'
      },
      link6: {
        title: 'Отправить уведомление о прибытии иностранного гостя',
        subtitle: 'Информационная система «eQonaq»',
        link: 'https://eqonaq.kz'
      }
    }
  },
  adaptiveTop: {
    mainSystem: 'Главная',
    about: 'О системе',
    createReport: 'Создать отчет',
    digest: 'Дайжесты',
    statistics: 'Статистика туризма',
    touchBord: 'Портрет туриста',
    region: 'Регион',
    articles: 'Новости',
    faq: 'FAQ',
    exit: 'Выйти',
    account: 'Личный кабинет',
    adminPanel: 'Админ панель',
    import: 'Импорт',
    bulletin: 'булетин',
    tourBase: 'База туроператоров',
  },
  mobileTop: {
    mainSystem: 'Главная',
    about: 'О системе',
    createReport: 'Создать отчет',
    statistics: 'Статистика',
    region: 'Регион',
    articles: 'Новости',
    faq: 'Faq',
    exit: 'Выйти'
  },
  main: {
    mainAboutSystem:
      'Информационная система Турстат осуществляет статистический учет туристской деятельности в Республике Казахстан и предоставляет аналитику туристического потока в разных разрезах. Система создана в рамках реализации Государственной программы развития туристской отрасли на 2019-2025 годы Министерством культуры и спорта Республики Казахстан.',
    welcome:
      '<b>Добро пожаловать</b><br> в информационную систему<br> базы данных Tourstat.kz!',
    information:
      'Информационная система по сбору и обработке статистических данных в сфере туризма.',
    enter: 'Войти',
    frequently: 'ЧАСТО ПРОСМАТРИВАЕМЫЕ ПОКАЗАТЕЛИ',
    aboutSystem:
      '  Информационная система Турстат осуществляет статистический учет туристской деятельности в Республике Казахстан и предоставляет аналитику туристического потока в разных разрезах. Система создана в рамках реализации Государственной программы развития туристской отрасли на 2019-2025 годы Министерством культуры и спорта Республики Казахстан. Информационная система Турстат осуществляет статистический учет туристской деятельности в республике и предоставляет аналитику туристического потока в разных разрезах. Статистика туризма охватывает все стороны этой деятельности, что позволяет получить объективную картину рынка. Индустрия туризма в Казахстане является одной из приоритетных отраслей национальной экономики. Она способна стать драйвером роста для развития регионов: стимулировать деятельность малого и среднего бизнеса, создать новые рабочие места и увеличить поступления в бюджет.',
    next: 'далее',
    search: 'Поиск по сайту',
    statistics: 'Статистика',
    mainTask: 'Основные задачи и показатели',
    tour: 'Ведение статистического учета туристской деятельности',
    stat: 'Доступность статистических данных',
    analitics: 'Аналитика туристического потока',
    count: 'Количество прибытий',
    bulletinstat: 'Количество мест размещения',
    bulletinCount: 'Всего по Республики Казахстан - 3488 единиц',
    date: 'Cрок пребывания (туродни)',
    sum: 'Cумма расходов туристов в календарном году',
    otherArticles: 'Другие новости',
    linkArticles: 'перейти в раздел новости',
    address: 'Адрес :',
    email: 'E-mail:',
    city: 'город Астана',
    place: 'пр. Туран, 1 д',
    phone: 'Телефон:',
    bulletins: 'Создание отчета',
    dynamic: 'Статистические данные',
    summary: 'Сравнить по нескольким показателям',
    selectOrInput: 'Введите либо выберите нужный показатель из списка',
    inputName: 'Введите',
    selectTypeDiagramm: 'Выберите вид диаграмм',
    selectAttribute: 'Выберите параметр согласно показателю',
    selectCity: 'Укажите город',
    noData: 'Выберите нужный показатель для вывода данных',
    fromDate: 'Выберите дату от',
    toDate: 'Выберите дату до',
    title: 'Выберите бюллетень',
    statisticText:
      'Статистика — отрасль знаний, наука, в которой излагаются общие вопросы сбора, измерения, мониторинга, анализа массовых статистических (количественных или качественных) данных и их сравнение изучение количественной стороны массовых общественных явлений в числовой форме. Статистика туризма очень важна как для государства так и для бизнеса. Туризм это большая отрасль экономики которая нуждается в маркетинге и серьезном планировании. Туризм может давать стране очень высокий процент ВВП. Государством в ближайшие годы планируется поднять влияние отрасли туризма на ВВП до 15%.',
    numberOfPlaces: 'Количество мест размещения',
    totalOfRepublicKazakhstan: 'Всего по Республике Казахстан',
    units: 'единиц',
    percent: 'процент',
    occupancyHotels: 'Заполняемость гостиниц',

    namebulletin: {
      name: '',
      text: 'Всего по Республики Казахстан - <strong>{0} единиц</strong>'
    },
    taskName: 'СТАТИСТИКА ПО ТУРИЗМУ В КАЗАХСТАНЕ: ДЛЯ ЧЕГО ОНА НУЖНА?',
    aboutSystemSystem: {
      p1: {
        b: 'Статистика туризма в Казахстане',
        p: ' – это основа для принятия правильных стратегических решений как в рамках конкретного бизнеса, так и индустрии в целом. На базе этой информации строятся краткосрочные и долгосрочные планы развития, выбираются и появляются новые ниши.'
      },
      p2: {
        b: 'Статистика по туризму',
        p: ' отражает то, что реально происходит на рынке. Всесторонняя объективная информация позволяет организациям отрасли анализировать рыночные тренды, строить прогнозы и принимать решения об инвестициях, расширении спектра туристских продуктов и услуг.'
      },
      p3: {
        p1: 'Информация на портале будет полезна всем, кто связан с туристической индустрией. Статистические данные затрагивают международный и',
        b: 'внутренний туризм в Казахстане',
        p2: ', статистика Турстат позволяет изучить основные показатели на уровне страны в целом и отдельных регионов.'
      },
      p4: {
        b1: 'Статистика туризма по регионам',
        p1: 'дает представление о состоянии туристского рынка для эффективного ведения бизнеса.Она будет полезна научным и учебным заведениям в качестве информации об основных итогах и тенденциях развития сферы туризма.Все данные, представленные на этом',
        b2: 'сайте статистики туризма',
        p2: ', могут использоваться в коммерческих, научных и частных целях со ссылкой на источник.'
      }
    }
  },
  statistics: {
    types: 'Виды Диаграмм',
    bar: 'Столбчатая (BarGraph)',
    line: 'Линейная (LineChart)',
    histogram: 'Гистограмма (BarHistograms)',
    pie: 'Круговая (PieCharts)',
    donut: 'Интерактивная кольцевая (DoughnutCharts)',
    sun: 'Лучевая (Sunburts)',
    multi: 'Гистограмма с несколькими осями (Multi-AxisCharts)',
    linkStatistics: 'перейти в раздел статистики ',
    onerParameter: 'Выберите один параметр',
    parameter: 'Выберите параметр',
    table: 'Таблица',
    selectLocation: 'Выберите локацию',
    selectOption:
      'Для отображения других видов диаграмм выберите параметры "Один атрибут" или "Одну локацию"',
    byRegion: 'По регионам',
    ByIndicators: 'По показателям',
    OnYears: 'По годам'
  },
  app: {
    download: 'СКАЧАТЬ',
    region: 'ОБЛАСТЬ',
    aboutSystem: 'О СИСТЕМЕ',
    mainSystem: 'ГЛАВНАЯ',
    adaptiveClose: 'Закрыть',
    createReport: 'СОЗДАТЬ ОТЧЕТ',
    statistic: 'СТАТИСТИКА',
    articles: 'НОВОСТИ',
    faq: 'FAQ',
    nur_sultan: 'Астана',
    almaty: 'Алматы',
    shymkent: 'Шымкент',
    aqmola: 'Акмолинская область',
    aqtobe: 'Актюбинская область',
    almatyObl: 'Алматинская область',
    atyrau: 'Атырауская область',
    vko: 'Восточно-Казахстанская область',
    jambyl: 'Жамбылская область',
    zko: 'Западно-Казахстанская область',
    karagandy: 'Карагандинская область',
    qostanai: 'Костанайская область',
    qyzylorda: 'Кызылординская область',
    mangystau: 'Мангистауская область',
    pavlodar: 'Павлодарская область',
    sko: 'Северо-Казахстанская область',
    turkistan: 'Туркестанская область',
    lichKab: 'Личный кабинет',
    adminPanel: 'Перейти в админ панель',
    exit: 'ВЫЙТИ',
    creatingReport: 'СОЗДАНИЕ ОТЧЕТА',
    currentPage:
      'На данной странице вы можете сформировать отчет со статистическими данными в удобном для вас формате PDF или Excel.',
    stepOne: 'ШАГ 1',
    insertDataToSearch:
      'Введите в поле “Поиск” данные, которые вы хотели бы найти',
    insertText: 'Поиск',
    stepTwo: 'ШАГ 2',
    selectPeriod: 'Выберите период',
    stepThree: 'ШАГ 3',
    downloadReport: 'Скачайте данный отчет'
  },
  profile: {
    errorLoginMassage:
      'Старый пароль введен неверно и/или пароли не совпадают. Попробуйте снова.',
    errorMessage: ' Ошибка запроса. Попробуйте снова.',
    phone: 'Номер телефона:',
    gender: 'Пол:',
    mail: 'почта:',
    edit: 'редактировать',
    editData: 'Редактировать данные',
    FIO: 'Фамилия Имя Отчество ',
    man: 'Мужской',
    woman: 'Женский',
    email: 'e-mail ',
    save: 'сохранить',
    cancel: 'Отмена',
    changePwd: 'изменить пароль',
    changePwdModal: 'Изменить пароль',
    oldPwd: 'Старый пароль  ',
    newPwd: 'Новый пароль ',
    confirmPwd: 'Подтвердите новый пароль',
    reports: 'Мои Отчеты',
    reportsSubTitle: 'Сформируйте отчет в удобном для вас формате',
    newReports: 'создать новый отчет',
    logIn: 'Авторизоваться'
  },
  admin: {
    createUser: 'Добавить пользователя',
    role: 'Роль',
    admin: 'Админ',
    guide: 'Гид',
    content_manger: 'Контент-Менеджер',
    fullName: 'Фамилия Имя Отчество',
    pwd: 'Пароль',
    confirmPwd: 'Подтвердите пароль',
    save: 'сохранить',
    cancel: 'отмена',
    name: 'Имя',
    mail: 'Почта',
    operation: 'Операции',
    change: 'Изменить',
    delete: 'Удалить',
    changeData: 'изменение данных',
    yes: 'да',
    no: 'нет',
    createArticle: 'Добавить новость',
    title: 'Заголовок',
    subtitle: 'Подзаголовок',

    alias: 'Краткое описание',
    content: 'Контент',
    image: 'Изображение',
    deleteArticle: 'Удалить новость',
    visualization: 'Визуализация',
    import: 'Импорт',
    importManual: 'Ручной импорт',
    importStat: 'Импорт от StatGov',
    continue: 'Продолжить',
    dynamic: 'Динамические таблицы',
    edit: 'Редактировать'
  },
  login: {
    back: 'назад',
    lk: 'Войти в личный кабинет',
    pwd: 'Пароль',
    enter: 'войти',
    create: 'Создать личный кабинет',
    reg: 'зарегистрироваться',
    regis: 'Регистрация',
    confirmPwd: 'Подтвердите новый пароль',
    confirmPwdRegistration: 'Подтвердите пароль',
    rpwd: 'Придумайте пароль',

    forgotPassword: 'Забыли пароль? ',
    errorLogin: ' Неверный логин или пароль',
    loginSuccessful: 'Успешно! проверьте почту'
  },
  adminApp: {
    main: 'Главная',
    toSite: 'Перейти на сайт',
    users: 'Пользователи',
    statistics: 'Статистика',
    articles: 'Новости',
    online: 'На сайте',
    viewed: 'Посмотрели за месяц',
    accounts: 'Зарегистрировано',
    download: 'Скачано файлов',
    staticPages: 'Статичные страницы'
  },
  panelAdminArticles: {
    createArticle: 'Создать новость',
    saveNews: ' Сохранить новость',
    Preview: 'Предпросмотр',
    LoadingWait: 'Загружается! Подождите',
    errorOccurred: 'произошла ошибка'
  },
  panelAdminFaq: {
    AddaFaq: 'Добавить FAQ',
    changeFaq: 'Изменить FAQ',
    title: 'Название',
    operations: 'Операции',
    reallyWantMessage: 'Вы действительно хотите удалить данные?',
    editData: ' Изменить данные',
    question: 'Вопрос',
    Answer: 'Ответ',
    CreateTab: 'Создать вкладку'
  },
  multiLogo: {
    logoMCS: 'MCS_Logos_RU.png'
  },
  touchbord: {
    total: 'Всего',
    title: 'Портрет иностранного туриста',
    darkStyle: 'Темный режим',
    lightStyle: 'Светлый режим',
    discription: `Данные в режиме реального времени
                                предоставляются информационной системой «eQonaq»,
                                за счет автоматизированного сбора данных от мест
                                размещений (отели, хостелы, базы отдыха). На
                                данный момент в системе ведут учет иностранных
                                туристов более 60% мест размещений Казахстана.`,
    region: 'по областям',
    city: 'по городам',
    low: 'Низкое',
    normal: 'Среднее',
    high: 'Высокое',
    more: 'более',
    touristsByCountry: 'Количество туристов в разрезе стран',
    hintContent:
      'На данной инфографике вы можете посмотреть с каких стран прибывали туристы в места размещения, которые зарегистрированы в системе «eQonaq».',
    popularCountries: 'ТОП 10 популярных стран',
    popularRegions: 'ТОП 10 популярных регионов',
    hintContentTop:
      'На данной диаграмме отображен рейтинг ТОП-10 стран откуда прибывали туристы в места размещения, которые зарегистрированы в системе «eQonaq».',
    hintContentTopRegion:
      'На данной диаграмме отображен рейтинг ТОП-10 регионов куда прибывали туристы в места размещения, которые зарегистрированы в системе «eQonaq».',
    Portrait: 'Портрет туриста',
    hintContentPortrait:
      'Данная диаграмма показывает гендерные данные по туристам, а также их возрастную группу в местах размещения, которые зарегистрированы в системе «eQonaq».',
    gender: 'Пол',
    man: 'Мужчина',
    woman: 'Женщина',
    TouristAge: 'Возраст туриста',
    purpose: 'Цель визитов туристов в Казахстан',
    hintContentPurpose:
      'Данные по целям визитам туристов, которые прибыли в места размещения зарегистрированные в системе «eQonaq».',
    placements: 'Топ 10 мест размещения ',
    hintContentPlacements:
      'На данной диаграмме отображен рейтинг ТОП-10 мест размещения, которые посетили наибольшее количество туристов. Рейтинг составлен по местам размещения, которые зарегистрированы в системе «eQonaq».',
    hintTitle: 'Количество прибывших иностранных туристов в разрезе областей',
    hintDescription:
      'На данной инфографике вы можете посмотреть соотношение прибытий иностранных туристов по областям или городам. Данные в режиме реального времени передаются информационной системой «eQonaq».'
  },
  breadCrumbs: {
    main: 'Главная',
    TourismStatistics: 'Статистика туризма',
    TouristServices: 'Туристические услуги',
    TravelExpenses: 'Расходы на поездки',
    OutboundVisitors: 'Места размещения',
    StatisticsRoutesAndAttractions: ' Маршруты и достопримечательности'
  },
  fequently: {
    FrequentlyViewed: 'ЧАСТО ПРОСМАТРИВАЕМЫЕ ПОКАЗАТЕЛИ'
  },
  statisticsResult: {
    indicator: 'Показатель',
    region: 'Регион',
    year: 'Год',
    download: 'Скачать отчет',
    table: 'Таблица',
    chart: 'График',
    prediction: 'На графике есть дополнительно предсказанные данные'
  },
  day: 'Сегодня',
  week: 'Неделя',
  month: 'Месяц',
  quater: '{0} квартал',
  quaterTitle: 'Квартал',
  period: 'Период',
  year: 'Год',
  regions: {
    'Западно-Казахстанская область': 'Западно-Казахстанская область',
    'Павлодарская область': 'Павлодарская область',
    'Алматинская область': 'Алматинская область',
    'Жамбылская область': 'Жамбылская область',
    'Акмолинская область': 'Акмолинская область',
    'Карагандинская область': 'Карагандинская область',
    'Северо-Казахстанская область': 'Северо-Казахстанская область',
    'Восточно-Казахстанская область': 'Восточно-Казахстанская область',
    'Актюбинская область': 'Актюбинская область',
    'Атырауская область': 'Атырауская область',
    'Кызылординская область': 'Кызылординская область',
    'Костанайская область': 'Костанайская область',
    'Мангистауская область': 'Мангистауская область',
    'Туркестанская область': 'Туркестанская область',
    'Южно-Казахстанская область': 'Южно-Казахстанская область'
  }
}
