
  import { defineComponent, onMounted, onUpdated, reactive, ref } from 'vue'
  import AdminMainTopLine from '@/components/AdminMainTopLine.vue'
  import AdminImport from '@/components/Adminimport.vue'
  import AdaptiveMainTopLine from '@/components/AdaptiveMainTopLine.vue'
  import AdminAdaptiveMainTopLine from '@/components/AdminAdaptiveMainTopLine.vue'
  import { api } from '@/api'
  import { useRoute } from 'vue-router'
  import { i18n } from '@/locales'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    components: {
      AdminMainTopLine,
      AdminImport,
      AdminAdaptiveMainTopLine
    },

    setup() {
      let animated
      let bulletinCategories = ref([])
      let checkboxBulletinStaus = ref(true)
      let selectBulletinCategory = ref()
      let errorIsActive = ref(false)
      let inputFile
      let changeActive = ref(false)
      let attrs = reactive([])
      let isActiveList
      const i18n = useI18n()
      api.interceptors.request.use(
        (config) => {
          config.headers.common['Accept-Language'] = 'ru'
          return config
        },
        (error) => {
          return Promise.reject(error)
        }
      )
      globalThis.attrsX = attrs
      let array = ref([])
      const data = reactive({
        attributeIndexes: {},
        dataTable: [],
        locationIndexes: {},
        nameBulletenIndex: {},
        batchList: []
      })
      let postData = ref({
        id: '',
        attrs: [],
        locations: [],
        name: '',
        fromDate: '',
        toDate: '',
        typeUnits: [],
        values: [],
        status: +checkboxBulletinStaus.value,
        reportCategoryId: selectBulletinCategory.value
      })
      let fileName = ref('')
      const route = useRoute()
      // fileName.value = route.params.fileName.toString()
      let saveFileName = ''
      async function getImportFile() {
        const response = await api.get(
          '/v1/admin/import/next?id=' + route.params.importFileId
        )

        data.attributeIndexes = response.data.attrIndexes
        data.dataTable = response.data.dataImport
        data.locationIndexes = response.data.locationIndexes
        data.nameBulletenIndex = response.data.nameBulletenIndex
        data.batchList = response.data.batchList

        let i = 0
        for (let r = 0; r < data.dataTable.length; r++) {
          for (let c = 0; c < data.dataTable[r].length; c++) {
            array.value[i] = data.dataTable[r][c]
            i++
          }
        }
      }
      onMounted(() => {
        let i = 0
        for (let r = 0; r < data.dataTable.length; r++) {
          for (let c = 0; c < data.dataTable[r].length; c++) {
            array.value[i] = data.dataTable[r][c]
            i++
          }
        }
      })
      async function downloadCheacked() {
        await getImportFile()

        await selectedBulletin()

        addIsActive(route.params.importFileId)
      }

      downloadCheacked()
      function selectedBulletin() {
        let m = 0
        for (let r = 0; r < data.dataTable.length; r++) {
          for (let c = 0; c < data.dataTable[r].length; c++) {
            if (
              data.nameBulletenIndex['index-column'] == c &&
              data.nameBulletenIndex['index-row'] == r
            ) {
              attrs[m] = 'bilut'
            }
            if (
              data.locationIndexes['index-column'] == c &&
              data.locationIndexes['index-end-row'] >= r &&
              data.locationIndexes['index-start-row'] <= r
            ) {
              attrs[m] = 'loc'
            }
            if (
              data.attributeIndexes['index-row'] == r &&
              data.attributeIndexes['index-end-column'] >= c &&
              data.attributeIndexes['index-start-column'] <= c
            ) {
              attrs[m] = 'attr'
            }
            m++
          }
        }
      }
      async function takeBulletin() {
        await postBulletin()
        await postValue()
        await importBulletin()

        await cleanVariables()
        postData.value.typeUnits.splice(0, postData.value.typeUnits.length)
        postData.value.values.splice(0, postData.value.values.length)
        postData.value.id = ''
        postData.value.locations.splice(postData.value.locations.length)
        postData.value.attrs.splice(postData.value.attrs.length)
        postData.value.name = ''
      }
      async function importBulletin() {
        try {
          postData.value.status = +checkboxBulletinStaus.value
          postData.value.reportCategoryId = selectBulletinCategory.value
          const response = await api.post(
            '/v1/admin/import/now',
            postData.value
          )

          changeActive.value = true
        } catch (error) {
          errorIsActive.value = true

          cleanVariables()
          console.error(error)
        }

        await cleanVariables()
        postData.value.typeUnits.splice(0, postData.value.typeUnits.length)
        postData.value.values.splice(0, postData.value.values.length)
        postData.value.id = ''
        postData.value.locations.splice(postData.value.locations.length)
        postData.value.attrs.splice(postData.value.attrs.length)
        postData.value.name = ''
      }
      function postBulletin() {
        postData.value.typeUnits.splice(0, postData.value.typeUnits.length)
        postData.value.values.splice(0, postData.value.values.length)
        postData.value.id = ''
        postData.value.locations.splice(postData.value.locations.length)
        postData.value.attrs.splice(postData.value.attrs.length)
        postData.value.name = ''

        postData.value.id = route.params.importFileId.toString()
        let arrtd

        arrtd = document.querySelectorAll('.import-box')

        let m = 0
        for (let r = 0; r < data.dataTable.length; r++) {
          for (let c = 0; c < data.dataTable[r].length; c++) {
            if (arrtd[m].children[1].value == 'attr') {
              let value = arrtd[m].children[0].value

              postData.value.attrs.push({
                name: value,
                ['index-row']: r,
                ['index-column']: c
              })

              if (
                arrtd[m].children[2].children[0].value &&
                arrtd[m].children[2].children[0].value != ''
              ) {
                postData.value.typeUnits.push({
                  name: arrtd[m].children[2].children[0].value,
                  ['index-row']: r,
                  ['index-column']: c
                })
              }
            }
            if (arrtd[m].children[1].value == 'loc') {
              let value = arrtd[m].children[0].value
              postData.value.locations.push({
                name: value,
                ['index-row']: r,
                ['index-column']: c
              })
            }
            if (arrtd[m].children[1].value == 'bilut') {
              let value = arrtd[m].children[0].value
              postData.value.name = value
            }

            m++
          }
        }
      }
      function cleanVariables() {
        try {
          postData.value.id = ''
          postData.value.attrs = []
          postData.value.typeUnits = []
          postData.value.values = []
          postData.value.locations = []
          postData.value.name = ''
        } catch (error) {
          console.error(error)

          postData.value.id = ''
          postData.value.attrs = []
          postData.value.typeUnits = []
          postData.value.values = []
          postData.value.locations = []
          postData.value.name = ''
        }
      }

      function postValue() {
        try {
          let m = 0
          let arrtd
          let indexLocation = 0
          let indexAttribute = 0

          arrtd = document.querySelectorAll('.import-box')

          for (let r = 0; r < data.dataTable.length; r++) {
            for (let c = 0; c < data.dataTable[r].length; c++) {
              if (arrtd[m].children[1].value == 'loc') {
                let tc = c
                let i = 1
                indexAttribute = 0

                for (tc; tc < postData.value.attrs.length; tc++) {
                  postData.value.values.push({
                    value: arrtd[m + i].children[0].value,
                    ['index-location']: indexLocation,
                    ['index-attr']: indexAttribute
                  })
                  i++
                  indexAttribute++
                }
                indexLocation++
              }
              m++
            }
          }
        } catch (error) {
          console.error(error)
        }
      }
      function addIsActive(id) {
        isActiveList = data.batchList.findIndex((item) => item.id == id)

        let buttonList = document.querySelectorAll('.import-page__button')
        buttonList.forEach((button) => {
          button.classList.remove('is-active')
        })
        buttonList[isActiveList].classList.add('is-active')
      }
      async function bulletinList(id) {
        const response = await api.get('/v1/admin/import/next?id=' + id)

        data.attributeIndexes = response.data.attrIndexes
        data.dataTable = response.data.dataImport
        data.locationIndexes = response.data.locationIndexes
        data.nameBulletenIndex = response.data.nameBulletenIndex
        data.batchList = response.data.batchList
        let i = 0
        for (let r = 0; r < data.dataTable.length; r++) {
          for (let c = 0; c < data.dataTable[r].length; c++) {
            array.value[i] = data.dataTable[r][c]
            i++
          }
        }
        addIsActive(id)
        attrs.length = 0
        await selectedBulletin()
      }
      const checkingCells = (row, column) => {
        let place = data.dataTable[0].length * row + column
        let checkedPlace = data.dataTable[0].length * row + 0
        let cells = document.querySelectorAll('.import-box')

        let cell = (cells[place].children[1] as any).value
        let checkedCell = (cells[checkedPlace].children[1] as any).value
        let checkedAtribute = false

        if (checkedCell == '' || checkedCell == 'none') {
        } else if (checkedCell != cell) {
          alert('Нельзя выбрать на той же столбце где атрибут')
          attrs[place] = 'none'
        }

        for (let i = 0; i < data.dataTable[0].length; i++) {
          let checkedAttributeValue = (
            cells[checkedPlace + i].children[1] as any
          ).value
          if (checkedAttributeValue == 'attr') {
            checkedAtribute = true

            break
          }
        }
        if (checkedCell == '' || checkedCell == 'none') {
        } else if (cell == 'loc' && checkedAtribute) {
          alert('Нельзя выбрать на той же колоенке где атрибут')
          attrs[place] = 'none'
        }
        let checked = 0
        for (let item of cells) {
          let value = (item.children[1] as any).value
          if (value == 'bilut') {
            checked++
          }
          if (checked > 1 && value == 'bilut') {
            alert('нельзя выбрать названия  больше одного')
            attrs[place] = 'none'
          }
        }
      }
      let importBox
      onUpdated(() => {
        inputFile = document.querySelector(
          '.date-from__Bulletin-date'
        ) as HTMLInputElement
        importBox = document.querySelectorAll('.import-box')

        let m = 0
        for (let i = 0; i < importBox.length; i++)
          if (importBox[i].children[1].value == 'bilut') {
            let value = importBox[i].children[0].value

            inputFile.value = value
          }

        document
          .querySelector('.import-error-messsage')
          .addEventListener('animationend', () => {
            errorIsActive.value = false
          })
      })
      async function bulletinCategory() {
        try {
          const response = await api.get('v1/bulletin-categories')

          bulletinCategories.value = response.data
        } catch (error) {
          console.error(error)
        }
      }

      bulletinCategory()
      return {
        errorIsActive,
        attrs,
        data,
        postBulletin,
        postData,
        selectedBulletin,
        bulletinList,
        fileName,
        takeBulletin,
        checkingCells,
        changeActive,
        array,
        bulletinCategories,
        selectBulletinCategory,
        checkboxBulletinStaus
      }
    }
  })
