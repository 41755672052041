
import { PropType } from 'vue'

export default {
  props: {
    links: {
      type: Array as PropType<{ to: string, name: string; }[]>,
      default() {
        return []
      }
    }
  }
}
