
import { defineComponent, ref, reactive } from 'vue'
import MainTopLine from '@/components/MainTopLine.vue'
import MainFooter from '@/components/MainFooter.vue'
import AdaptiveMainTopLine from '@/components/AdaptiveMainTopLine.vue'
import { api } from '@/api'
import router from '@/router'
import SectionTitle from '@/components/ui/SectionTitle.vue'
import BreadCrumbs from '@/components/ui/BreadCrumbs.vue'

export default defineComponent({
  components: {
    AdaptiveMainTopLine,
    MainTopLine,
    MainFooter,
    SectionTitle,
    BreadCrumbs,
  },
  setup() {
    let step = reactive({
      isActive1: 'active',
      isActive2: '',
      isActive3: ''
    })
    let data = reactive({
      search: '',
      bulletin: '',
      period: { fromDate: '', toDate: '' }
    })
    let bulletins = ref([])
    let chacked = ref(true)
    let currentBulletinId = ref()
    let bulletinPeriod = ref([])
    let bulletinDate = ref()
    let BulletinName = ref('')
    let authorization = ref(false)
    let changeactive1 = ref(false)

    let timer
    function searchBulletinTimer() {
      window.clearTimeout(timer)
      timer = window.setTimeout(function () {
        searchBulletin()
      }, 300)
    }
    async function searchBulletin() {
      step.isActive1 = 'active'
      step.isActive2 = ''
      step.isActive3 = ''
      chacked.value = true
      bulletinPeriod.value = []
      bulletinDate.value = null
      try {
        const response = await api.post('/v1/chart/search/bulletin', {
          search: data.search
        })
        bulletins.value = response.data.data.listBulletins
      } catch (error) {
        bulletins.value = null
      }
    }
    async function clickBulletinById(bulletinId) {
      step.isActive1 = 'active'
      step.isActive2 = 'active'
      step.isActive3 = ''
      try {
        currentBulletinId.value = bulletinId
        const response = await api.post(
          '/v1/chart/search/period',

          {
            bulletin_id: bulletinId
          }
        )
        bulletinPeriod.value = response.data
        BulletinName.value = bulletins.value.find(
          (item) => item.id == currentBulletinId.value
        ).name
      } catch (error) {
        console.error(error)
      }
    }

    async function downloadFileExel() {
      if (window.localStorage.getItem('token') !== null) {
        authorization.value = true

        try {
          const response = await api.get(
            '/v1/excel-download?' +
            'id=' +
            currentBulletinId.value +
            '&fromDate=' +
            bulletinPeriod.value[0].from_date +
            '&toDate=' +
            bulletinPeriod.value[0].to_date
          )
          let path = 'https://back.tourstat.kz/' + response.data.file_path

          window.open(path)
        } catch (error) { }
      } else {
        authorization.value = false
        changeactive1.value = true
      }
    }
    async function downloadFilePDF() {
      if (window.localStorage.getItem('token') !== null)  {
        authorization.value = false
        try {
          const response = await api.get(
            '/v1/pdf-download?' +
            'id=' +
            currentBulletinId.value +
            '&fromDate=' +
            data.period.fromDate +
            '&toDate=' +
            data.period.toDate
          )
          let path = 'https://back.tourstat.kz/' + response.data.file_path

          window.open(path)
        } catch (error) { }
      } else {
        authorization.value = false
        changeactive1.value = true
      }
    }
    function changePeriod() {
      step.isActive1 = 'active'
      step.isActive2 = 'active'
      step.isActive3 = 'active'
      let [startDate, endDate] = bulletinDate.value.split('=')
      data.period.fromDate = startDate
      data.period.toDate = endDate
    }
    async function routerLink() {
      await router.push({ name: 'login' })
    }
    return {
      bulletins,
      data,
      searchBulletinTimer,
      chacked,
      clickBulletinById,
      bulletinPeriod,
      bulletinDate,
      BulletinName,
      authorization,
      downloadFileExel,
      changeactive1,
      downloadFilePDF,
      step,
      changePeriod,
      routerLink
    }
  }
})
