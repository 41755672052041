import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import DigestId from '@/views/digest/DigestId.vue'
import DigestPage from '@/views/digest/DigestPage.vue'
import ItemDigestPdf from '@/views/digest/DigestIdPdf.vue'
import AboutSystem from '@/views/AboutSystem.vue'
import CreateReport from '@/views/CreateReport.vue'
import FAQ from '@/views/FAQ.vue'
import HomePage from '@/views/HomePage.vue'
import ImportFileStatGov from '@/views/ImportFileStatGov.vue'
import Login from '@/views/Login.vue'
import NewsPage from '@/views/NewsPage.vue'
import PanelAdmin from '@/views/PanelAdmin.vue'
import PanelAdminArticleChange from '@/views/PanelAdminArticleChange.vue'
import PanelAdminArticleCreate from '@/views/PanelAdminArticleCreate.vue'
import PanelAdminBulletin from '@/views/PanelAdminBulletin.vue'
import PanelAdminBulletinChange from '@/views/PanelAdminBulletinChange.vue'
import PanelAdminFAQ from '@/views/PanelAdminFAQ.vue'
import PanelAdminFAQChange from '@/views/PanelAdminFAQChange.vue'
import PanelAdminFAQCreate from '@/views/PanelAdminFAQCreate.vue'
import PanelAdminImport from '@/views/PanelAdminImport.vue'
import PanelAdminImportManual from '@/views/PanelAdminImportManual.vue'
import PanelAdminImportNext from '@/views/PanelAdminImportNext.vue'
import PanelAdminImportStatGov from '@/views/PanelAdminImportStatGov.vue'
import PanelAdminNews from '@/views/PanelAdminNews.vue'
import PanelAdminUser from '@/views/PanelAdminUser.vue'
import Profile from '@/views/Profile.vue'
import RegionAkmola from '@/views/RegionAkmola.vue'
import RegionAktobe from '@/views/RegionAktobe.vue'
import RegionAlmaty from '@/views/RegionAlmaty.vue'
import RegionAlmatyObl from '@/views/RegionAlmatyObl.vue'
import RegionAstana from '@/views/RegionAstana.vue'
import RegionAtyrauObl from '@/views/RegionAtyrauObl.vue'
import RegionKaraganda from '@/views/RegionKaraganda.vue'
import RegionKostanay from '@/views/RegionKostanay.vue'
import RegionKyzylorda from '@/views/RegionKyzylorda.vue'
import RegionMangystau from '@/views/RegionMangystau.vue'
import RegionPavlodar from '@/views/RegionPavlodar.vue'
import RegionShymkent from '@/views/RegionShymkent.vue'
import RegionSKO from '@/views/RegionSKO.vue'
import RegionTurkystan from '@/views/RegionTurkystan.vue'
import RegionVKO from '@/views/RegionVKO.vue'
import RegionZhambylObl from '@/views/RegionZhambylObl.vue'
import RegionZKO from '@/views/RegionZKO.vue'
import SingleNews from '@/views/SingleNews.vue'
import Statistics from '@/views/Statistics.vue'
import StatisticsExpensesOnTravels from '@/views/StatisticsExpensesOnTravels.vue'
import StatisticsRoutesAndAttractions from '@/views/StatisticsRoutesAndAttractions.vue'
import StatisticsThree from '@/views/StatisticsThree.vue'
import StatisticsTwo from '@/views/StatisticsTwo.vue'
import StatisticsVisitingVisitors from '@/views/StatisticsVisitingVisitors.vue'
import StatTotalPage from '@/views/StatTotalPage.vue'
import SummaryTable from '@/views/SummaryTable.vue'
import TouchBord from '@/views/TouchBord.vue'
import TourBase from '@/views/TourBase.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Main',
    component: HomePage,
    meta: {
      name: 'main',
      requiresAuth: true
    }
  },
  {
    path: '/touch-bord',
    name: 'touchBord',
    component: TouchBord,
    meta: {
      name: 'touchBord'
    }
  },
  {
    path: '/statistics-routes-and-attractions',
    name: 'StatisticsRoutesAndAttractions',
    component: StatisticsRoutesAndAttractions
  },
  {
    path: '/statistics-visiting-visitors',
    name: 'StatisticsVisitingVisitors',
    component: StatisticsVisitingVisitors
  },
  {
    path: '/statistics-expenses-on-travels',
    name: 'StatisticsExpensesOnTravels',
    component: StatisticsExpensesOnTravels
  },
  {
    path: '/statistics-three',
    name: 'statisticsthree',
    component: StatisticsThree,
    meta: {
      name: 'statisticsThree'
    }
  },
  {
    path: '/statistics-result/:categoryId/:newBulletinId/:newBulletinName',
    name: 'statisticsResult',
    component: StatTotalPage
  },
  {
    path: '/admin/import-file-statgov',
    name: 'ImportFileStatGov',
    meta: {
      layout: 'AdminLayout'
    },
    component: ImportFileStatGov
  },
  { path: '/statisticstwo', name: 'Statisticstwo', component: StatisticsTwo },
  {
    path: '/faq-change',
    name: 'PanelAdminFAQChange',
    component: PanelAdminFAQChange
  },

  {
    path: '/admin/bulletin-change',
    name: 'PanelAdminBulletinChange',
    meta: {
      layout: 'AdminLayout'
    },
    component: PanelAdminBulletinChange
  },
  {
    path: '/admin/bulletin',
    name: 'PanelAdminBulletin',
    meta: {
      layout: 'AdminLayout'
    },
    component: PanelAdminBulletin
  },
  {
    path: '/article-change',
    name: 'PanelAdminArticleChange',
    component: PanelAdminArticleChange
  },
  {
    path: '/about-system',
    name: 'AboutSystem',
    component: AboutSystem
  },
  {
    path: '/faq',
    name: 'faq',
    component: FAQ
  },
  {
    path: '/news',
    name: 'news',
    component: NewsPage,
    meta: {
      name: 'news'
    }
  },
  {
    path: '/tour-base',
    name: 'tourBase',
    component: TourBase,
    meta: {
      name: 'tourBase'
    }
  },
  {
    path: '/singlenews/:newsId',
    name: 'singlenews',
    component: SingleNews
  },
  {
    path: '/create-report',
    name: 'create-report',
    component: CreateReport,
    meta: {
      name: 'createReport'
    }
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: Statistics
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/almaty',
    name: 'almaty',
    component: RegionAlmaty
  },
  {
    path: '/astana',
    name: 'astana',
    component: RegionAstana
  },
  {
    path: '/shymkent',
    name: 'shymkent',
    component: RegionShymkent
  },
  {
    path: '/akmola',
    name: 'akmola',
    component: RegionAkmola
  },
  {
    path: '/aktobe',
    name: 'aktobe',
    component: RegionAktobe
  },
  {
    path: '/almatyobl',
    name: 'almatyobl',
    component: RegionAlmatyObl
  },
  {
    path: '/atyrauobl',
    name: 'atyrauobl',
    component: RegionAtyrauObl
  },
  {
    path: '/vko',
    name: 'vko',
    component: RegionVKO
  },
  {
    path: '/ZhambylObl',
    name: 'ZhambilObl',
    component: RegionZhambylObl
  },
  {
    path: '/ZKO',
    name: 'zko',
    component: RegionZKO
  },
  {
    path: '/karaganda',
    name: 'karaganda',
    component: RegionKaraganda
  },
  {
    path: '/kostanay',
    name: 'kostanay',
    component: RegionKostanay
  },
  {
    path: '/kyzylorda',
    name: 'kyzylorda',
    component: RegionKyzylorda
  },
  {
    path: '/Mangystau',
    name: 'Mangystau',
    component: RegionMangystau
  },
  {
    path: '/pavlodar',
    name: 'pavlodar',
    component: RegionPavlodar
  },
  {
    path: '/SKO',
    name: 'SKO',
    component: RegionSKO
  },
  {
    path: '/turkystan',
    name: 'turkystan',
    component: RegionTurkystan
  },
  {
    path: '/admin',
    name: 'admin',
    meta: {
      layout: 'AdminLayout'
    },
    component: PanelAdmin
  },
  {
    path: '/admin/user',
    name: 'adminuser',
    meta: {
      layout: 'AdminLayout'
    },
    component: PanelAdminUser
  },
  {
    path: '/admin/articles',
    name: 'adminnews',
    meta: {
      layout: 'AdminLayout'
    },
    component: PanelAdminNews
  },
  {
    path: '/admin/FAQ',
    name: 'adminfaq',
    meta: {
      layout: 'AdminLayout'
    },
    component: PanelAdminFAQ
  },
  {
    path: '/admin/article/create',
    name: 'adminnewscreate',
    meta: {
      layout: 'AdminLayout'
    },
    component: PanelAdminArticleCreate
  },
  {
    path: '/admin/faq/create',
    name: 'adminfagcreate',
    meta: {
      layout: 'AdminLayout'
    },
    component: PanelAdminFAQCreate
  },
  {
    path: '/admin/import',
    name: 'adminimport',
    meta: {
      layout: 'AdminLayout'
    },
    component: PanelAdminImport
  },
  {
    path: '/admin/import-manual',
    name: 'adminimportmanual',
    meta: {
      layout: 'AdminLayout'
    },
    component: PanelAdminImportManual
  },
  {
    path: '/admin/import-statgov',
    name: 'adminimportstatgov',
    meta: {
      layout: 'AdminLayout'
    },
    component: PanelAdminImportStatGov
  },
  {
    path: '/admin/import-next',
    name: 'adminimportnext',
    meta: {
      layout: 'AdminLayout'
    },
    component: PanelAdminImportNext
  },
  {
    path: '/statisticstwo/summary-table',
    name: 'SummaryTable',
    component: SummaryTable
  },
  {
    path: '/digest',
    name: 'digest',
    meta: {
      name: 'digest',
    },
    component: DigestPage,
  },
  {
    path: '/digest/:id/:categoryId',
    name: 'digestId',
    component: DigestId
  },
  {
    path: '/digest/pdf',
    name: 'digestIdPdf',
    component: ItemDigestPdf
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: () => {
    return {
      top: 0
    }
  }
})

export default router
