
import { computed, onMounted } from 'vue'
import store from '@/store'
import { useI18n } from 'vue-i18n'

import QuesHint from '@/components/ui/QuesHint.vue'

const countryTranslates = {
  en: 'country_name_eng',
  ru: 'country_name',
  kz: 'country_name_kaz'
}

export default {
  components: { QuesHint },
  setup() {
    const i18n = useI18n()
    const countries = computed(() => {
      return store.getters?._getTopMonthCountries || []
    })

    function getCountryName(country) {
      return country[countryTranslates[i18n.locale.value]]
    }

    onMounted(() => {
      store.dispatch('_setCountries')
    })

    return {
      countries,
      getCountryName
    }
  }
}
