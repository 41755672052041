
  import { defineComponent, ref, reactive } from 'vue'
  import { api } from '@/api'
  import router from '@/router'
  import { useI18n } from 'vue-i18n'
  import SwitchLanguage from '@/components/main/SwitchLanguage.vue'

  export default defineComponent({
    components: { SwitchLanguage },
    setup() {
      const i18n = useI18n()
      const isAdmin = ref(false)
      const localActive = ref(false)
      const locals = ref(['kz', 'ru', 'en'])
      const data = reactive({
        name: 'Профиль',
        url: '/login'
      })

      const active = ref(false)
      const activeButton = ref(false)
      const activeOblost = ref(false)
      const logoutactive = ref(false)

      const toggleActiveButton = () => {
        activeButton.value = !activeButton.value
        active.value = !active.value
        if (active.value == true) {
          document.body.style.overflowY = 'hidden'
        } else {
          document.body.style.overflowY = 'auto'
        }
      }

      if (active.value == true) {
        document.body.style.overflowY = 'hidden'
      } else {
        document.body.style.overflowY = 'auto'
      }

      async function logout() {
        try {
          const response1 = await api.get('/auth/logout')
          localStorage.removeItem('token')
          localStorage.removeItem('role')
          await router.push({ name: 'Main' })
          window.location.reload()
        } catch (error) {
          console.error(error)
        }
      }

      async function getProfile() {
        try {
          const token = localStorage.getItem('token')
          if (token) {
            data.url = '/profile'
            logoutactive.value = true
            if ('admin' == localStorage.getItem('role')) {
              isAdmin.value = true
            } else {
              isAdmin.value = false
            }
          } else {
            data.url = '/login'
            logoutactive.value = false
          }
        } catch (error) {
          console.error(error)
        }
      }
      getProfile()
      function switchLocal(local) {
        i18n.locale.value = local
      }
      return {
        activeButton,
        active,
        toggleActiveButton,
        activeOblost,
        logout,
        logoutactive,
        data,
        localActive,
        locals,
        switchLocal
      }
    }
  })
