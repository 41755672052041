
  import { ref } from 'vue'

  export default {
    setup() {
      const isOpen = ref(false)

      return {
        isOpen
      }
    }
  }
