import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_adaptive_main_top_line = _resolveComponent("adaptive-main-top-line")
  const _component_faq_list = _resolveComponent("faq-list")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_adaptive_main_top_line),
    _createVNode(_component_faq_list)
  ], 64 /* STABLE_FRAGMENT */))
}