<template>
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-7">
          <ul class="footer-nav">
            <li class="footer-link">
              <router-link
                class="link menu__link"
                active-class="active"
                :to="{ name: 'AboutSystem' }"
                exact
              >
                {{ $t('adaptiveTop.about') }}
              </router-link>
            </li>
            <li class="footer-link">
              <router-link
                class="link menu__link"
                active-class="active"
                :to="{ name: 'digest' }"
                exact
              >
                {{ $t('adaptiveTop.digest') }}
              </router-link>
            </li>
            <!-- <li class="footer-link">
              <router-link
                class="link menu__link"
                active-class="active"
                :to="{ name: 'news' }"
                exact
              >
                {{ $t('adaptiveTop.articles') }}
              </router-link>
            </li> -->
            <li class="footer-link">
              <router-link
                class="link menu__link"
                active-class="active"
                :to="{ name: 'faq' }"
                exact
              >
                {{ $t('adaptiveTop.faq') }}
              </router-link>
            </li>
          </ul>
          <ul class="contacts">
            <li class="contacts-item">
              <h4 class="title">{{ $t('main.phone') }}</h4>
              <div class="text">+7 (7172) 91-95-00</div>
            </li>
            <li class="contacts-item">
              <h4 class="title">{{ $t('main.email') }}</h4>
              <div class="text">info@qaztourism.kz</div>
            </li>
          </ul>
        </div>

        <div class="col-12 col-sm-5">
          <div class="links">
            <a
              href="https://qaztourism.kz/"
              style="min-width: 50px"
              class="link"
            >
              <div class="footer-logo">
                <img
                  :src="require(`@/assets/images/kazakh_tourism.png`)"
                  alt=""
                />
              </div>
            </a>
            <a
              href="https://www.gov.kz/memleket/entities/mcs?lang=ru"
              class="link linkcms"
              v-if="$i18n.locale == 'ru'"
            >
              <img
                v-bind:src="
                  require(`@/assets/images/` + $t('multiLogo.logoMCS'))
                "
                :class="$t('multiLogo.logoMCS').split('.png')"
                alt=""
              />
            </a>
            <a
              href="https://www.gov.kz/memleket/entities/mcs?lang=en"
              class="link linkcms"
              v-if="$i18n.locale == 'en'"
            >
              <img
                v-bind:src="
                  require(`@/assets/images/` + $t('multiLogo.logoMCS'))
                "
                :class="$t('multiLogo.logoMCS').split('.png')"
                alt=""
              />
            </a>
            <a
              href="https://www.gov.kz/memleket/entities/mcs?lang=kk"
              class="link linkcms"
              v-if="$i18n.locale == 'kz'"
            >
              <img
                v-bind:src="
                  require(`@/assets/images/` + $t('multiLogo.logoMCS'))
                "
                :class="$t('multiLogo.logoMCS').split('.png')"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent, ref } from 'vue'

  export default defineComponent({})
</script>
<style lang="scss">
  .footer-logo {
    width: auto !important;
    & svg {
      max-height: 65px;
      width: 73px;
    }
  }

  .MCS_Logos {
    &_KAZlat {
      width: 100%;
      display: block;
      max-height: 65px;
      height: auto;
    }
    &_RU {
      max-height: 65px;
      display: block;
      width: 100%;
      height: auto;
    }
    &_ENG {
      max-height: 65px;
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .footer {
    background-color: $main-blue;
    min-height: 160px;
    padding-top: 40px !important;
    margin-top: auto;

    &-nav {
      display: flex;
      margin-bottom: 35px;

      & .footer-link:last-child {
        margin-right: 0;
      }
    }

    &-link {
      margin-right: 30px;

      & a.active {
        color: #ca9e67 !important;
      }

      & a {
        color: #fff;
        font-size: 14px;
        text-decoration: none;

        &:hover {
          color: #ca9e67;
        }
      }
    }

    .links {
      display: flex;
      justify-content: flex-end;

      .link {
        height: 160px;
        margin-left: 50px;
        & img {
          width: 100%;
        }

        &:first-child {
          flex-shrink: 0;
        }
      }
      & .linkcms {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
      }
    }

    .contacts {
      color: #fff;
      display: flex;

      &-item {
        margin-right: 40px;
      }

      &-item:last-child {
        margin-right: 0;
      }

      .title,
      .text {
        color: #fff;
      }

      .title {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 5px;
      }

      .text {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
  // @include media-breakpoint-down(sm) {
  //     .contacts {
  //         text-align: left;
  //         margin-top: 16px;
  //         margin-left: 10px;
  //         &::after {
  //             height: 156px !important;
  //         }
  //     }
  //     .links {
  //         justify-content: space-between !important;
  //     }
  //     .link {
  //         width: auto !important;
  //         height: auto !important;
  //     }
  // }
</style>
