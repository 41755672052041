
import { defineComponent, ref, computed, watch, onMounted } from 'vue'
import store from '@/store'

import BreadCrumbs from '@/components/ui/BreadCrumbs.vue'
import SectionTitle from '@/components/ui/SectionTitle.vue'
import TabHead from '@/components/ui/TabHead.vue'

export default defineComponent({
  name: 'DigestLayout',
  components: {
    BreadCrumbs,
    SectionTitle,
    TabHead,
  },
  setup() {
    const arrayPhoto = ['digest.svg', 'digest1.svg']
    let leftRow = false

    const activeTab = ref(0)
    const digestCateg = computed(() => {
      return store.getters._getDigestCategorys || []
    })
    const digests = computed(() => {
      leftRow = false
      return store.state.digest.digests?.map((e, ind) => {
        return {
          ...e,
          image: getNextImg(),
          col: getClass(ind)
        }
      }) || []
    })
    const isPdf = computed(() => store.state.digest.isPdf)

    watch(() => activeTab.value, () => {
      dispatchDigest()
    })

    onMounted(async () => {
      await store.dispatch('_fetchDigestCategorys')
      await dispatchDigest()
    })

    async function dispatchDigest() {
      const id = digestCateg.value[activeTab.value]?.id
      if (!id) return

      await store.dispatch('_fetchDigestList', id)
    }

    function* getImage() {
      let i = 0

      while (true) {
        if (i >= arrayPhoto.length - 1) i = 0
        else i += 1
        yield arrayPhoto[i]
      }
    }

    const gen = getImage()

    function getNextImg() {
      return gen.next().value
    }

    function getClass(ind) {
      if (ind % 3 === 0) leftRow = !leftRow
      const isLong = leftRow ? ind % 3 === 0 : (ind + 1) % 6 === 0

      return isLong ? 'col-12 mb-3 col-md-12 mb-md-3 col-lg-6 mb-lg-4' : 'col-12 mb-3 col-md-6 mb-md-3 col-lg-3 mb-lg-4'
    }

    return {
      activeTab,
      digestCateg,
      digests,
      isPdf,
      getClass
    }
  }
})
