import axios from 'axios'
import { api } from '@/api'

export default {
  state: {
    countries: null,
    genders: null,
    age: null,
    digest: null,
    bulletins: null,
    homeArticles: null,
    faqs: null,
    regions: null,
    digestCategorys: null
  },
  mutations: {
    SET_COUNTRIES(state, payload) {
      state.countries = payload
    },
    SET_GENDERS(state, payload) {
      state.genders = payload
    },
    SET_AGE(state, payload) {
      state.age = payload
    },
    SET_DIGEST(state, payload) {
      state.digest = payload
    },
    SET_BULLETINS(state, payload) {
      state.bulletins = payload
    },
    SET_HOME_ARTICLES(state, payload) {
      state.homeArticles = payload
    },
    SET_FAQS(state, payload) {
      state.faqs = payload
    },
    SET_REGIONS(state, payload) {
      state.regions = payload
    },
    SET_DIGEST_CATEGORIES(state, payload) {
      state.digestCategorys = payload
    }
  },
  actions: {
    async _setCountries({ commit }) {
      try {
        const response = await axios.get(
          'https://api.eqonaq.kz/api/v1/stat/top-countries'
        )
        commit('SET_COUNTRIES', response.data)
      } catch (error) {
        console.error(error)
      }
    },
    async _setGenders({ commit }) {
      try {
        const response = await axios.get(
          'https://api.eqonaq.kz/api/v1/stat/genders'
        )
        commit('SET_GENDERS', response.data)
      } catch (error) {
        console.error(error)
      }
    },
    async _setAge({ commit }) {
      try {
        const response = await axios.get(
          'https://api.eqonaq.kz/api/v1/stat/age-groups'
        )
        commit('SET_AGE', response.data)
      } catch (error) {
        console.error(error)
      }
    },
    async _setFrequentlyReports({ commit }) {
      try {
        const response = await api.get('/v1')
        commit('SET_DIGEST', response.data.data)
      } catch (error) {
        console.error(error)
      }
    },
    async _setBulletins({ commit }, payload) {
      try {
        const response = await api.post('/v1/chart/search/bulletin', {
          search: payload
        })
        commit('SET_BULLETINS', response.data.data.listBulletins)
      } catch (error) {
        console.error(error)
      }
    },
    async _setHomeArticles({ commit }, payload) {
      try {
        const response = await api.get('/v1/news', {
          params: {
            limit: payload?.limit || 3
          }
        })
        commit('SET_HOME_ARTICLES', response.data.data.articles)
      } catch (error) {
        console.error(error)
      }
    },
    async _fetchFAQS({ commit }) {
      try {
        const response = await api.get('/v1/FAQ')
        commit('SET_FAQS', response.data.data.staticPages)
      } catch (error) {
        console.error(error)
      }
    },
    async _fetchRegions({ commit }) {
      try {
        const response = await api.post('/v1/chart/search/location', {
          bulletins: [
            {
              id: 1
            }
          ]
        })
        commit('SET_REGIONS', response.data.data.listLocations)
      } catch (error) {
        console.error(error)
      }
    },
    async _fetchDigestCategorys({ commit }, length) {
      try {
        const response = await api.get('v1/bulletin-categories')
        const val = length ? response.data.slice(length) : response.data
        val.push({
          id: 8,
          name: 'World Travel & Tourism Council'
        })
        commit('SET_DIGEST_CATEGORIES', val)
      } catch (error) {
        console.error(error)
      }
    }
  },
  getters: {
    _getTopMonthCountries(state) {
      return state.countries?.top_ten_by_month
    },
    _getMonthGender(state) {
      return state.genders?.genders_stat_by_month
    },
    _getMonthAge(state) {
      return state.age?.age_group_stat_by_month
    },
    _getFrequentlyReports(state) {
      return state.digest?.frequentlyDownloadedReports
    },
    _getBulletins(state) {
      return state.bulletins
    },
    _getHomeArticles(state) {
      return state.homeArticles
    },
    _getFAQS(state) {
      return state.faqs
    },
    _getRegions(state) {
      return state.regions
    },
    _getDigestCategorys(state) {
      return state.digestCategorys
    }
  }
}
