
import { defineComponent, onMounted } from 'vue'
export default defineComponent({
  setup() {
    onMounted(() => {
      let chartButtons = document.querySelectorAll(
        '.import-tab__button-change'
      )

      chartButtons.forEach((button) => {
        button.addEventListener('click', function (e) {
          chartButtons.forEach(function (item) {
            item.classList.remove('import-tab__button-change--active')
          })

          button.classList.add('import-tab__button-change--active')
        })
      })
    })
  }
})
