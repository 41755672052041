
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    sides: {
      type: String,
      default: 'top left'
    }
  }
}
