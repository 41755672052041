import { ref } from 'vue'

import HttpService from '@/services/api'

export const useBulletin = () => {
  const bulletins = ref([])
  const loading = ref(false)

  const getBulletins = async () => {
    loading.value = true
    try {
      const resp = await HttpService.get<{ data: [] }>('/chart/search/bulletin')

      bulletins.value = [...(resp.data.data || [])]
    } catch (err) {
      console.error(err)
    } finally {
      loading.value = false
    }
  }

  const resetBuletins = () => {
    bulletins.value = []
  }

  return {
    bulletins,
    loading,
    getBulletins,
    resetBuletins
  }
}
