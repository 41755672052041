
  import * as am4core from '@amcharts/amcharts4/core'
  import * as am4charts from '@amcharts/amcharts4/charts'
  import am4themes_animated from '@amcharts/amcharts4/themes/animated'
  am4core.useTheme(am4themes_animated)
  import {
    defineComponent,
    onMounted,
    onUnmounted,
    PropType,
    ref,
    watch,
    toRefs
  } from 'vue'

  export default defineComponent({
    props: {
      type: String,

      data: {
        type: Object,
        requred: true
      }
    },

    setup(opts) {
      const { type } = toRefs(opts)
      const { data } = toRefs(opts)
      let chart
      let valueAxis
      let series
      let hoverState
      let categoryAxis

      chart = am4core.create('chartdiv', am4charts.XYChart)
      chart.scrollbarX = new am4core.Scrollbar()
      chart.data = opts.data
      categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      categoryAxis.dataFields.category = 'country'
      categoryAxis.renderer.grid.template.location = 0
      categoryAxis.renderer.minGridDistance = 30
      categoryAxis.renderer.labels.template.horizontalCenter = 'right'
      categoryAxis.renderer.labels.template.verticalCenter = 'middle'
      categoryAxis.renderer.labels.template.rotation = 270
      categoryAxis.tooltip.disabled = true
      categoryAxis.renderer.minHeight = 110
      chart.colors.list = [
        am4core.color('#4DB6AC'),
        am4core.color('#81C784'),
        am4core.color('#4DD0E1'),
        am4core.color('#AED581'),
        am4core.color('#DCE775'),
        am4core.color('#F06292'),
        am4core.color('#BA68C8'),
        am4core.color('#E57373'),
        am4core.color('#FF8A65'),
        am4core.color('#BA68C8'),
        am4core.color('#FFB74D'),
        am4core.color('#4FC3F7'),
        am4core.color('#78DFEC'),
        am4core.color('#4FC3F7')
      ]
      valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
      valueAxis.renderer.minWidth = 50
      series = chart.series.push(new am4charts.ColumnSeries())
      series.sequencedInterpolation = true
      series.dataFields.valueY = 'visits'
      series.dataFields.categoryX = 'country'
      series.tooltipText = '[{categoryX}: bold]{valueY}[/]'
      series.columns.template.strokeWidth = 0
      series.tooltip.pointerOrientation = 'vertical'
      series.columns.template.column.cornerRadiusTopLeft = 10
      series.columns.template.column.cornerRadiusTopRight = 10
      series.columns.template.column.fillOpacity = 0.8
      hoverState = series.columns.template.column.states.create('hover')
      hoverState.properties.cornerRadiusTopLeft = 0
      hoverState.properties.cornerRadiusTopRight = 0
      hoverState.properties.fillOpacity = 1

      series.columns.template.adapter.add('fill', function (fill, target) {
        return chart.colors.getIndex(target.dataItem.index)
      })
      chart.cursor = new am4charts.XYCursor()
      onMounted(() => {
        chart = am4core.create('chartdiv', am4charts.XYChart)
        chart.scrollbarX = new am4core.Scrollbar()
        chart.data = opts.data
        categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
        categoryAxis.dataFields.category = 'country'
        categoryAxis.renderer.grid.template.location = 0
        categoryAxis.renderer.minGridDistance = 30
        categoryAxis.renderer.labels.template.horizontalCenter = 'right'
        categoryAxis.renderer.labels.template.verticalCenter = 'middle'
        categoryAxis.renderer.labels.template.rotation = 270
        categoryAxis.tooltip.disabled = true
        categoryAxis.renderer.minHeight = 110
        valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
        valueAxis.renderer.minWidth = 50
        series = chart.series.push(new am4charts.ColumnSeries())
        series.sequencedInterpolation = true
        series.dataFields.valueY = 'visits'
        series.dataFields.categoryX = 'country'
        series.tooltipText = '[{categoryX}: bold]{valueY}[/]'
        series.columns.template.strokeWidth = 0
        series.tooltip.pointerOrientation = 'vertical'
        series.columns.template.column.cornerRadiusTopLeft = 10
        series.columns.template.column.cornerRadiusTopRight = 10
        series.columns.template.column.fillOpacity = 0.8
        hoverState = series.columns.template.column.states.create('hover')
        hoverState.properties.cornerRadiusTopLeft = 0
        hoverState.properties.cornerRadiusTopRight = 0
        hoverState.properties.fillOpacity = 1

        series.columns.template.adapter.add('fill', function (fill, target) {
          return chart.colors.getIndex(target.dataItem.index)
        })
        chart.cursor = new am4charts.XYCursor()
      })
      watch(type, () => {})
      watch(data, () => {
        if (chart) {
          chart.dispose()
        }
        chart = am4core.create('chartdiv', am4charts.XYChart)
        chart.scrollbarX = new am4core.Scrollbar()
        chart.data = opts.data
        categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
        categoryAxis.dataFields.category = 'country'
        categoryAxis.renderer.grid.template.location = 0
        categoryAxis.renderer.minGridDistance = 30
        categoryAxis.renderer.labels.template.horizontalCenter = 'right'
        categoryAxis.renderer.labels.template.verticalCenter = 'middle'
        categoryAxis.renderer.labels.template.rotation = 270
        categoryAxis.tooltip.disabled = true
        categoryAxis.renderer.minHeight = 110
        valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
        valueAxis.renderer.minWidth = 50
        series = chart.series.push(new am4charts.ColumnSeries())
        series.sequencedInterpolation = true
        series.dataFields.valueY = 'visits'
        series.dataFields.categoryX = 'country'
        series.tooltipText = '[{categoryX}: bold]{valueY}[/]'
        series.columns.template.strokeWidth = 0
        series.tooltip.pointerOrientation = 'vertical'
        series.columns.template.column.cornerRadiusTopLeft = 10
        series.columns.template.column.cornerRadiusTopRight = 10
        series.columns.template.column.fillOpacity = 0.8
        hoverState = series.columns.template.column.states.create('hover')
        hoverState.properties.cornerRadiusTopLeft = 0
        hoverState.properties.cornerRadiusTopRight = 0
        hoverState.properties.fillOpacity = 1

        series.columns.template.adapter.add('fill', function (fill, target) {
          return chart.colors.getIndex(target.dataItem.index)
        })
        chart.cursor = new am4charts.XYCursor()
      })

      onUnmounted(() => {
        if (chart) {
          chart.dispose()
        }
      })

      return {}
    }
  })
