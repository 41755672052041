
  import { defineComponent, ref, onBeforeMount, onMounted } from 'vue'
  import MainTopLine from '@/components/MainTopLine.vue'
  import LineChart from '@/components/LineChart.vue'
  import AdaptiveMainTopLine from '@/components/AdaptiveMainTopLine.vue'
  import RegionGraphics from '@/components/RegionGraphics.vue'
  import Downloaded from '@/components/download.vue'
  import SwiperCore, { Navigation } from 'swiper'
  import { Swiper, SwiperSlide } from 'swiper/vue'
  SwiperCore.use([Navigation])

  export default defineComponent({
    components: {
      MainTopLine,
      LineChart,
      AdaptiveMainTopLine,
      Swiper,
      SwiperSlide,
      RegionGraphics,
      Downloaded
    },
    setup() {
      let graphics = ref('line')
      const active = ref(true)
      let downloaded = ref(false)
      const toggleActive = () => {
        active.value = !active.value
      }

      onMounted(() => {
        downloaded.value = false
        let img = document.querySelector('.left-region-image').children[0]
        img.addEventListener('load', () => {
          downloaded.value = true
        })
      })
      return {
        active,
        toggleActive,
        graphics,
        downloaded
      }
    }
  })
