import axios from 'axios'
// import { useI18n } from 'vue-i18n'

export const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

const LANGUAGES = {
  'kz': 'kk-KZ',
  'ru': 'ru-RU',
  'en': 'en-EN',
}

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    // const i18n = useI18n()
    
    if (token) {
      config.headers.common['Authorization'] = `Bearer ${token}`
    }
    config.headers.common['Accept-Language'] = LANGUAGES[localStorage.getItem('lang') || 'ru']

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
