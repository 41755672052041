
import FeedBack from '@/components/main/FeedBack.vue'
import MainHeader from '@/components/main/MainHeader.vue'
import MainFooter from '@/components/MainFooter.vue'
import AdminMainTopLine from '@/components/AdminMainTopLine.vue'
import AdminAdaptiveMainTopLine from '@/components/AdminAdaptiveMainTopLine.vue'

export default {
  components: {
    FeedBack,
    MainHeader,
    MainFooter,
    AdminMainTopLine,
    AdminAdaptiveMainTopLine,
  },
  setup() { }
}
