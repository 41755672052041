
  import { defineComponent, ref, reactive } from 'vue'
  import { api } from '@/api'
  import router from '@/router'
  import SwitchLanguage from '@/components/main/SwitchLanguage.vue'
  export default defineComponent({
    components: { SwitchLanguage },
    setup() {
      const activeButton = ref(false)
      const authProfile = ref(false)
      const isAdmin = ref(false)
      const data = reactive({
        name: 'профиль',
        url: '/login'
      })

      const toggleActiveButton = () => {
        activeButton.value = !activeButton.value
      }
      async function getProfile() {
        try {
          const token = localStorage.getItem('token')
          if (token) {
            authProfile.value = true
            data.url = '/profile'

            if ('Admin' == localStorage.getItem('role')) {
              isAdmin.value = true
            } else {
              isAdmin.value = false
            }
          } else {
            data.url = '/login'
            authProfile.value = false
          }
        } catch (error) {
          data.url = '/login'
          authProfile.value = false
        }
      }
      getProfile()
      async function logout() {
        try {
          const response1 = await api.get('/auth/logout')
          localStorage.removeItem('token')
          localStorage.removeItem('role')
          await router.push({ name: 'Main' })
          window.location.reload()
        } catch (error) {
          console.error(error)
        }
      }
      let dispalyActive = ref('container')
      if (window.innerWidth > 1200) {
        dispalyActive.value = 'container'
      } else {
        dispalyActive.value = 'container-fluid'
      }

      return {
        activeButton,
        toggleActiveButton,
        authProfile,
        isAdmin,
        data,
        logout,
        dispalyActive
      }
    }
  })
