import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "statistics" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row mb-4" }
const _hoisted_4 = { class: "col-12 col-sm-6" }
const _hoisted_5 = { class: "col-12 col-sm-6" }
const _hoisted_6 = { class: "row content" }
const _hoisted_7 = { class: "col-12 col-sm-6" }
const _hoisted_8 = { class: "label" }
const _hoisted_9 = { class: "statistic-list" }
const _hoisted_10 = { class: "col-12 col-sm-4" }
const _hoisted_11 = { class: "label" }
const _hoisted_12 = { class: "statistic-list" }
const _hoisted_13 = { class: "col-12 col-sm-2" }
const _hoisted_14 = { class: "label" }
const _hoisted_15 = { class: "statistic-list" }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "col results mb-4" }
const _hoisted_18 = { class: "button-bulletin_name_div" }
const _hoisted_19 = { class: "row diagram" }
const _hoisted_20 = { class: "col" }
const _hoisted_21 = { class: "text diagram__title" }
const _hoisted_22 = { class: "diagram__tabs tabs mb-4" }
const _hoisted_23 = { class: "diagram__content" }
const _hoisted_24 = { class: "chart" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_adaptive_main_top_line = _resolveComponent("adaptive-main-top-line")
  const _component_router_link = _resolveComponent("router-link")
  const _component_svg_icon = _resolveComponent("svg-icon")
  const _component_line_chart = _resolveComponent("line-chart")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_adaptive_main_top_line),
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode("div", _hoisted_4, [
            _createVNode(_component_router_link, {
              to: "/statisticstwo",
              class: "button statistics__button button--primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('main.dynamic')), 1 /* TEXT */)
              ]),
              _: 1
            })
          ]),
          _createVNode("div", _hoisted_5, [
            _createVNode(_component_router_link, {
              to: "/summary-table",
              class: "button statistics__button statistics__button--active button--primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('main.summary')), 1 /* TEXT */)
              ]),
              _: 1
            })
          ])
        ]),
        _createVNode("div", _hoisted_6, [
          _createVNode("div", _hoisted_7, [
            _createVNode("label", _hoisted_8, [
              _createTextVNode(_toDisplayString(_ctx.$t('main.selectOrInput')) + " ", 1 /* TEXT */),
              _withDirectives(_createVNode("input", {
                type: "text",
                class: "input",
                onKeyup: _cache[1] || (_cache[1] = (...args) => (_ctx.endAndStartTimer(...args))),
                onFocus: _cache[2] || (_cache[2] = (...args) => (_ctx.endAndStartTimer(...args))),
                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (_ctx.data.search = $event)),
                placeholder: _ctx.inputPlaceBulletinName,
                onClick: _cache[4] || (_cache[4] = $event => {;(_ctx.activeLocation = false), (_ctx.activeAttribute = false)})
              }, null, 40, ["placeholder"]), [
                [_vModelText, _ctx.data.search]
              ]),
              _createVNode(_component_svg_icon, { name: "triangle-down" })
            ]),
            _withDirectives(_createVNode("ul", _hoisted_9, [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.searchBuletins, (bul) => {
                return (_openBlock(), _createBlock("li", {
                  key: bul.id,
                  onClick: $event => (_ctx.clickbullById(bul.id, bul.name))
                }, _toDisplayString(bul.name), 9, ["onClick"]))
              }), 128 /* KEYED_FRAGMENT */))
            ], 512), [
              [_vShow, _ctx.changeactive]
            ])
          ]),
          _createVNode("div", _hoisted_10, [
            _createVNode("label", _hoisted_11, [
              _createTextVNode(_toDisplayString(_ctx.$t('main.selectAttribute')) + " ", 1 /* TEXT */),
              _createVNode("input", {
                type: "text",
                class: "input",
                onClick: _cache[5] || (_cache[5] = $event => {
                ;(_ctx.activeAttribute = true),
                  (_ctx.activeLocation = false),
                  (_ctx.changeactive = false)
              })
              }),
              _createVNode(_component_svg_icon, { name: "triangle-down" })
            ]),
            _withDirectives(_createVNode("ul", _hoisted_12, [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.bulletinAttributesMap, (bulletinAttribute) => {
                return (_openBlock(), _createBlock("li", {
                  key: bulletinAttribute.id,
                  onClick: $event => (
                _ctx.setbulletinAttributes(
                  bulletinAttribute.id,
                  bulletinAttribute.name
                )
              )
                }, _toDisplayString(bulletinAttribute.name), 9, ["onClick"]))
              }), 128 /* KEYED_FRAGMENT */))
            ], 512), [
              [_vShow, _ctx.activeAttribute]
            ])
          ]),
          _createVNode("div", _hoisted_13, [
            _createVNode("label", _hoisted_14, [
              _createTextVNode(_toDisplayString(_ctx.$t('main.selectCity')) + " ", 1 /* TEXT */),
              _createVNode("input", {
                type: "text",
                class: "input",
                onClick: _cache[6] || (_cache[6] = $event => {
                ;(_ctx.activeLocation = true),
                  (_ctx.activeAttribute = false),
                  (_ctx.changeactive = false)
              })
              }),
              _createVNode(_component_svg_icon, { name: "triangle-down" })
            ]),
            _withDirectives(_createVNode("ul", _hoisted_15, [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.bulletinLocationsMap, (bulletinLocation) => {
                return (_openBlock(), _createBlock("li", {
                  key: bulletinLocation.id,
                  onClick: $event => (
                _ctx.setbulletinLocation(bulletinLocation.id, bulletinLocation.name)
              )
                }, _toDisplayString(bulletinLocation.name), 9, ["onClick"]))
              }), 128 /* KEYED_FRAGMENT */))
            ], 512), [
              [_vShow, _ctx.activeLocation]
            ])
          ])
        ]),
        _createVNode("div", _hoisted_16, [
          _createVNode("div", _hoisted_17, [
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.bulletinNameButton, (bulletin, index) => {
              return (_openBlock(), _createBlock("button", {
                key: index,
                class: "button-bulletin button-bulletin_name"
              }, [
                _createVNode("div", _hoisted_18, _toDisplayString(bulletin.name), 1),
                _createVNode(_component_svg_icon, {
                  name: "dagger",
                  onClick: $event => (_ctx.deleteBulletin(bulletin.id))
                }, null, 8, ["onClick"])
              ]))
            }), 128 /* KEYED_FRAGMENT */)),
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.setbulletinAttributesMap, (attribute) => {
              return (_openBlock(), _createBlock("button", {
                key: attribute.id,
                class: "button-bulletin button-bulletin_attribute"
              }, [
                _createTextVNode(_toDisplayString(attribute.name) + " ", 1 /* TEXT */),
                _createVNode(_component_svg_icon, {
                  name: "dagger",
                  onClick: $event => (_ctx.deletebulletinAttributesMap(attribute.id))
                }, null, 8, ["onClick"])
              ]))
            }), 128 /* KEYED_FRAGMENT */)),
            (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.setbulletinLocationsMap, (location) => {
              return (_openBlock(), _createBlock("button", {
                key: location.id,
                class: "button-bulletin button-bulletin_location"
              }, [
                _createTextVNode(_toDisplayString(location.name) + " ", 1 /* TEXT */),
                _createVNode(_component_svg_icon, {
                  name: "dagger",
                  onClick: $event => (_ctx.deletebulletinLocationsMap(location.id))
                }, null, 8, ["onClick"])
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]),
        _createVNode("div", _hoisted_19, [
          _createVNode("div", _hoisted_20, [
            _createVNode("p", _hoisted_21, _toDisplayString(_ctx.$t('main.selectTypeDiagramm')), 1),
            _createVNode("div", _hoisted_22, [
              _createVNode("div", {
                class: "tabs__item tabs__item--active",
                onClick: _cache[7] || (_cache[7] = $event => (_ctx.setChart('bar')))
              }, [
                _createVNode(_component_svg_icon, { name: "bar-chart-activ" })
              ]),
              _createVNode("div", {
                class: "tabs__item",
                onClick: _cache[8] || (_cache[8] = $event => (_ctx.setChart('line')))
              }, [
                _createVNode(_component_svg_icon, { name: "line-chart-activ" })
              ]),
              _createVNode("div", {
                class: "tabs__item",
                onClick: _cache[9] || (_cache[9] = $event => (_ctx.setChart('polarArea')))
              }, [
                _createVNode(_component_svg_icon, { name: "multiaxis-chart-activ" })
              ]),
              _createVNode("div", {
                class: "tabs__item",
                onClick: _cache[10] || (_cache[10] = $event => (_ctx.setChart('pie')))
              }, [
                _createVNode(_component_svg_icon, { name: "pie-chart-activ" })
              ]),
              _createVNode("div", {
                class: "tabs__item",
                onClick: _cache[11] || (_cache[11] = $event => (_ctx.setChart('doughnut')))
              }, [
                _createVNode(_component_svg_icon, { name: "donught-chart-activ" })
              ])
            ]),
            _createVNode("div", _hoisted_23, [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.bulletinNameButton, (bulletin) => {
                return (_openBlock(), _createBlock("p", {
                  class: "text diagram__text",
                  key: bulletin.id
                }, _toDisplayString(bulletin.name + ' ' + bulletin.fromDate.split('-')[0]), 1))
              }), 128 /* KEYED_FRAGMENT */)),
              _createVNode("div", _hoisted_24, [
                _createVNode(_component_line_chart, {
                  type: _ctx.graphics.chart,
                  data: {
                  labels: _ctx.graphics.labels,
                  datasets: _ctx.dataValues
                },
                  options: {
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true
                        }
                      }
                    ]
                  }
                }
                }, null, 8, ["type", "data"])
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}