
  import { ref, computed, onMounted, onUnmounted } from 'vue'
  import store from '@/store'
  import Chart from 'chart.js'
  import { useI18n } from 'vue-i18n'

  export default {
    setup() {
      const i18n = useI18n()

      const canvasElem = ref()
      let myChart

      const ids = [1, 11, 13, 18, 22, 23, 255]
      const bgColors = {
        1: '#944CEE',
        11: '#2FFC8D',
        13: '#55DCE7',
        18: '#FB8832',
        22: '#C99F69',
        23: '#0194EB',
        255: '#F9D451'
      }
      const colorArray = ids.map((e) => bgColors[e])

      const filteredFrequentlyReports = computed(() => {
        return store.getters._getFrequentlyReports?.filter((e) =>
          ids.includes(e.id)
        )
      })

      const labels = computed(() => {
        return filteredFrequentlyReports.value?.map((d) => d[`name_` + i18n.locale.value]) || []
      })

      const frequentlyReports = computed(() => {
        return (
          filteredFrequentlyReports.value?.map((e) => {
            return e.count
          }) || []
        )
      })

      const getColorsList = computed(() => {
        return (
          filteredFrequentlyReports.value?.map((e) => {
            return bgColors[e.id]
          }) || []
        )
      })

      const data = computed(() => {
        return {
          labels: labels.value,
          datasets: [
            {
              data: frequentlyReports.value,
              backgroundColor: getColorsList.value
            }
          ]
        }
      })

      const getColor = computed(() => (id) => {
        return bgColors[id]
      })

      onMounted(async () => {
        await store.dispatch('_setFrequentlyReports')
        const ctx = canvasElem.value.getContext('2d')
        ctx.canvas.style.borderColor = 'white'
        ctx.canvas.style.fill = 'false'
        ctx.canvas.style.backgroundColor = 'white'

        myChart = new Chart(ctx, {
          type: 'doughnut',
          data: data.value,
          options: {
            legend: {
              display: false
            },
            responsive: true,
            aspectRatio: 1,
            scales: {
              xAxes: [
                {
                  display: false,
                  gridLines: {
                    display: false
                  }
                }
              ],
              yAxes: [
                {
                  display: false,
                  gridLines: {
                    display: false
                  }
                }
              ]
            },
            cutoutPercentage: 65
          }
        })

        onUnmounted(() => {
          myChart.destroy()
        })
      })

      function getName(obj) {
        if (!obj) return ''
        return obj['name_' + i18n.locale.value] || ''
      }

      return {
        getColor,
        filteredFrequentlyReports,
        canvasElem,
        frequentlyReports,
        getName
      }
    }
  }
