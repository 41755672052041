
  import { defineComponent, ref, toRefs } from 'vue'
  export default defineComponent({
    props: {
      active: { required: true, default: false }
    },
    setup(opts) {
      const { active } = toRefs(opts)
      const toggleActive = () => {
        active.value = !active.value
      }
      return {
        active,
        toggleActive
      }
    }
  })
