<template>
  <adaptive-main-top-line />
  <div class="container-fluid intro">
    <div class="row">
      <div class="col-12 col-sm-7">
        <div class="left-region-image">
          <img src="@/assets/images/Astana.png" />
        </div>
      </div>
      <div class="col-12 col-sm-5">
        <h2 class="right-region-paragraf intro-title">
          Достопримечательности Нур-Султана (Астаны)
        </h2>
        <div class="left-region-text">
          Нур-Султан (Астана) – молодая столица страны, известная далеко за
          пределами своего региона. Среди местных достопримечательностей много
          не только интересных, но и уникальных объектов, например, единственное
          в мире шатровое сооружение с собственным пляжем «Хан Шатыр». Немалый
          интерес вызывает голубое сферическое здание музея энергии будущего
          «Нур-Алем».
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row-2">
      <div class="col">
        <div class="region-way-paragraf">Такая Разная Столица!</div>
      </div>
      <div class="col">
        <div class="region-way-text">
          Рекой Ишим город Нур-Султан поделен на две части. Левобережье – это
          широкие проспекты, современные здания. Тут находится символ столицы
          Казахстана «Байтерек» – почти стометровый монумент, изображающий
          «Древо жизни» из восточных легенд. Здесь же знаменитый бульвар
          «Нуржол», близ которого расположена Резиденция Президента. Эти места
          любят посещать иностранные туристы, как утверждает статистика туризма,
          Нур-Султан для них – необычный суперсовременный мегаполис в необъятных
          казахстанских степях. На правом берегу Ишима раскинулся спокойный и
          уютный Старый город, разительно отличающийся от того, что туристы
          видят на левом берегу реки. Здесь немало достопримечательностей
          советского периода и множество зданий XIX века. В этой части столицы
          чаще можно встретить не иностранцев, а местных туристов. Туризм в
          Нур-Султане непрерывно развивается. Ежегодно в городе открываются
          новые места размещения, и это не только отели, но и небольшие частные
          гостевые дома. Интересна и насыщенна спортивная жизнь столицы,
          международные турниры и матчи по различным видам спорта можно
          наблюдать с трибун:
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="region-table">
          <table id="city-table">
            <tr>
              <th>
                Единовременная вместимость (койко-мест) мест размещения, шт
              </th>
              <td>13902</td>
            </tr>
            <tr>
              <th>Заполняемость гостиниц (койко-мест), %</th>
              <td>24,1</td>
            </tr>
            <tr>
              <th>
                Обслужено посетителей местами размещения по внутреннему туризму
                (резиденты), чел
              </th>
              <td>635571</td>
            </tr>
            <tr>
              <th>
                Обслужено посетителей местами размещения по въездному туризму
                (нерезиденты). чел
              </th>
              <td>236280</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row-2">
      <div class="col">
        <div class="region-way-paragraf">Поездки В Столицу На Мероприятия</div>
      </div>
      <div class="col">
        <div class="region-way-text">
          Туризм в Нур-Султане невозможен без культурных мероприятий. Гости
          города с удовольствием посещают концертный зал «Астана», не менее
          популярны два местных театра – «Астана Опера» и «Астана Балет». В их
          репертуаре – разножанровые шедевры мировой и отечественной классики, а
          также оригинальные современные постановки. Статистика туризма в
          Казахстане по столице будет неполной без упоминания активной
          спортивной жизни города, ведь здесь проходят не только региональные,
          но и международные соревнования по разным дисциплинам. Поклонники
          спорта едут сюда, чтобы наблюдать за состязаниями с трибун стадиона
          «Астана Арена», масштабного комплекса «Барыс Арена», Ледового дворца
          «Алау».
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="container">
        <div class="row">
            <div class="col">
                <div class="bo-region-text">ПОКАЗАТЕЛИ</div>
            </div>
        </div>
    </div> -->

  <!-- <div class="container">
        <div class="row">
            <div class="col">
                <region-graphics local="Астана қ." />
            </div>
        </div>
    </div> -->
  <div class="container">
    <div class="row">
      <div class="col">
        <h2 class="section__title">ФОТОГАЛЕРЕЯ</h2>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="regio-swiper">
          <swiper
            :slides-per-view="1"
            :space-between="40"
            :loop="false"
            navigation
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
            <swiper-slide>
              <img class="region-photo" src="@/assets/images/astana1.jpg"
            /></swiper-slide>
            <swiper-slide>
              <img class="region-photo" src="@/assets/images/astana2.jpg"
            /></swiper-slide>
            <swiper-slide>
              <img class="region-photo" src="@/assets/images/astana3.jpg"
            /></swiper-slide>
            <swiper-slide>
              <img class="region-photo" src="@/assets/images/astana4.jpg"
            /></swiper-slide>
            <swiper-slide>
              <img class="region-photo" src="@/assets/images/astana5.jpg"
            /></swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
  </div>
  <Downloaded v-if="!downloaded" />
</template>
<script>
  import { defineComponent, ref, onBeforeMount, onMounted } from 'vue'
  import MainTopLine from '@/components/MainTopLine.vue'
  import LineChart from '@/components/LineChart.vue'
  import AdaptiveMainTopLine from '@/components/AdaptiveMainTopLine.vue'
  import SwiperCore, { Navigation } from 'swiper'
  import RegionGraphics from '@/components/RegionGraphics.vue'
  import Downloaded from '@/components/download.vue'
  import { Swiper, SwiperSlide } from 'swiper/vue'
  SwiperCore.use([Navigation])
  export default defineComponent({
    components: {
      MainTopLine,
      LineChart,
      AdaptiveMainTopLine,
      Swiper,
      SwiperSlide,
      RegionGraphics,
      Downloaded
    },
    setup() {
      let graphics = ref('line')
      const active = ref(true)
      let downloaded = ref(false)
      const toggleActive = () => {
        active.value = !active.value
      }

      onMounted(() => {
        downloaded.value = false
        let img = document.querySelector('.left-region-image').children[0]
        img.addEventListener('load', () => {
          downloaded.value = true
        })
      })
      return {
        downloaded,
        active,
        toggleActive,
        graphics
      }
    }
  })
</script>
<style lang="scss" scoped>
  .top-line {
    margin-bottom: 0 !important;
  }
  .intro {
    margin-top: -22px;
    & .col-12 {
      padding: 0 !important;
    }
    &-title {
      padding-top: 40px !important;
    }
  }
  .region-table {
    width: 100%;
    margin: auto;
  }
  .left-region-image {
    img {
      width: 100%;
    }
  }
  .right-region-paragraf {
    font-weight: 700;
    font-size: 3em;
    word-wrap: break-word;
    margin-bottom: 50px;
  }
  .left-region-text {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #16355b;
  }
  .bo-region-text {
    margin: 45px 0;
    color: #16355b;
    font-size: 30px;
    line-height: 35px;
    text-transform: uppercase;
    font-weight: 500;
  }
  .region-period {
    border-radius: 6px;
    border: 1px solid #ca9e67;
    padding: 8px 10px;
    margin-bottom: 16px;
  }
  .region-period-input {
    width: 100%;
    border: none;
    text-transform: uppercase;
    text-align: center;
    font-size: 15px;
    font-weight: 500;
  }
  .region-way-paragraf {
    margin: 20px 0;
    color: #16355b;
    font-weight: 700;
    font-size: 26px;
    text-decoration-line: underline;
  }
  .region-way-text {
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 20px;
    color: #303030;
    line-height: 30px;
  }
  #city-table {
    font-size: 18px;
    border: 1px solid black;
    border-collapse: collapse;
    margin: 0 auto;
    margin-top: 20px;
    color: #303030;
    margin-bottom: 20px;
    & th,
    td {
      font-weight: 300;
      border: 1px solid black;
      border-collapse: collapse;
      padding: 10px;
      color: #303030;
    }
  }
</style>
