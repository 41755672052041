import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex","flex-direction":"column","min-height":"100vh"} }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_admin_main_top_line = _resolveComponent("admin-main-top-line")
  const _component_admin_adaptive_main_top_line = _resolveComponent("admin-adaptive-main-top-line")
  const _component_FeedBack = _resolveComponent("FeedBack")
  const _component_MainFooter = _resolveComponent("MainFooter")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_admin_main_top_line),
    _createVNode(_component_admin_adaptive_main_top_line),
    _createVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createVNode(_component_FeedBack),
    _createVNode(_component_MainFooter)
  ], 64 /* STABLE_FRAGMENT */))
}