
  import { ref, onMounted } from 'vue'

  export default {
    setup() {
      const userActive = ref(false)

      onMounted(() => {
        userActive.value = !!localStorage.getItem('token')
      })

      return {
        userActive
      }
    }
  }
