
  import { defineComponent, ref, watch } from 'vue'
  import MainTopLine from '@/components/MainTopLine.vue'
  import MainFooter from '@/components/MainFooter.vue'
  import AdaptiveMainTopLine from '@/components/AdaptiveMainTopLine.vue'
  import { useRoute } from 'vue-router'
  import { api } from '@/api'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    components: {
      MainTopLine,
      MainFooter,
      AdaptiveMainTopLine
    },
    setup() {
      let article = ref({})
      let articles = ref([])
      let watchArticles = ref(false)
      const route = useRoute()
      route.params.newsId

      async function getArticle() {
        try {
          const response = await api.get('/v1/news/' + route.params.newsId)

          article.value = response.data.data.article
          articles.value = response.data.data.articles.data
          for (let article of articles.value) {
            let str = article.created_at
            let from = str.search('T')
            article.created_at = str.substring(0, from)
          }
          await switchArticles()
        } catch (error) {
          console.error(error)
        }
      }
      getArticle()
      async function reload(id) {
        try {
          const response = await api.get('/v1/news/' + id)

          article.value = response.data.data.article
          articles.value = response.data.data.articles.data
          for (let article of articles.value) {
            let str = article.created_at
            let from = str.search('T')
            article.created_at = str.substring(0, from)
          }
          await switchArticles()
        } catch (error) {
          console.error(error)
        }
      }
      const i18n = useI18n()
      watch(i18n.locale, getArticle)
      function switchArticles() {
        if (articles.value.length == 0) {
          watchArticles.value = false
        } else {
          watchArticles.value = true
        }
      }
      watch(
        () => route.params.newsId,
        () => {
          getArticle()
        }
      )
      return { article, articles, reload, watchArticles }
    }
  })
