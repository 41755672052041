import axios from 'axios'
import router from '@/router'

const HttpService = axios.create({
  baseURL: `/api`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

HttpService.defaults.timeout = 10000

HttpService.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token')
    if (token) {
      config.headers.common['Authorization'] = token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

HttpService.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        case 400:
          //do something
          break

        case 401:
          alert('session expired')
          break
        case 403:
          router.replace({
            path: '/login',
            query: { redirect: router.currentRoute.value.fullPath }
          })
          break
        case 404:
          alert('page not exist')
          break
        case 502:
          setTimeout(() => {
            router.replace({
              path: '/login',
              query: {
                redirect: router.currentRoute.value.fullPath
              }
            })
          }, 1000)
      }
      return Promise.reject(error.response)
    }
  }
)

export default HttpService
