
  export default {
    emits: ['change-route'],
    setup() {
      const menuList = [
        { to: '/touch-bord', i18n: 'adaptiveTop.touchBord' },
        { to: { name: 'statisticsthree' }, i18n: 'adaptiveTop.statistics' },
        { to: '/create-report', i18n: 'adaptiveTop.createReport' },
        // { to: '/news', i18n: 'mobileTop.articles' },
        { to: { name: 'digest' }, i18n: 'adaptiveTop.digest' },
        { to: { name: 'tourBase' }, i18n: 'adaptiveTop.tourBase' }
      ]

      return {
        menuList
      }
    }
  }
