
import MainLogo from '@/components/main/MainLogo.vue'
import SwitchLanguage from '@/components/main/SwitchLanguage.vue'
import ProfileMenu from '@/components/main/ProfileMenu.vue'
import RegionSelect from '@/components/main/RegionSelect.vue'
import NavMenu from '@/components/main/NavMenu.vue'
import NavMobileMenu from '@/components/main/NavMobileMenu.vue'

export default {
  components: {
    MainLogo,
    SwitchLanguage,
    ProfileMenu,
    RegionSelect,
    NavMenu,
    NavMobileMenu
  }
}
