
  import Vue, { defineComponent, ref, reactive } from 'vue'

  import SwiperCore, { Navigation } from 'swiper'
  import { Swiper, SwiperSlide } from 'swiper/vue'
  SwiperCore.use([Navigation])
  export default defineComponent({
    components: {
      Swiper,
      SwiperSlide
    },
    setup() {
      function aboutSwiper() {
        const mySwiper = new Swiper({
          slidesPerView: 5,
          slidesPerColumn: 2,
          spaceBetween: 50
        })
        mySwiper.init()
      }
      return { aboutSwiper }
    }
  })
