<template>
  <div class="container">
    <div class="row">
      <div class="bread_crumbs col-12">
        <router-link :to="{ name: 'Main' }"
          ><span class="cooki">{{ $t('breadCrumbs.main') }}</span></router-link
        >
        <div class="bread_crumbs-line">|</div>
        <router-link :to="{ name: 'statisticsthree' }"
          ><span class="cooki">{{ $t('breadCrumbs.TourismStatistics') }}</span>
        </router-link>
        <div class="bread_crumbs-line">|</div>
        <router-link :to="{ name: 'StatisticsRoutesAndAttractions' }"
          ><span class="cooki">{{
            $t('breadCrumbs.StatisticsRoutesAndAttractions')
          }}</span>
        </router-link>
      </div>
    </div>
  </div>
  <StatisticsCategory />
  <div class="container mb-4">
    <div class="row mb-4">
      <div class="col-6 mb-4" v-for="bulletin in bulletins" :key="bulletin.id">
        <router-link
          style="text-decoration: none"
          :to="{
            name: 'statisticsResult',
            params: {
              newBulletinId: bulletin.id,
              categoryId: category,
              newBulletinName: bulletin.name
            }
          }"
        >
          <div class="billutin-name">
            <div>
              <div class="svg-size">
                <SvgIcon :name="BulletinIcons.getIndex()" />
              </div>
              <p>{{ bulletin.name }}</p>
            </div>
            <svg-icon name="arrow-right" />
          </div>
        </router-link>
      </div>
    </div>
  </div>
  <div class="container mb-5" v-if="bulletinLMore">
    <div class="row">
      <div class="col-12">
        <div class="watch-more">
          <p>ПРОСМОТРЕТЬ ЕЩЕ</p>
          <svg-icon name="arrow-right" />
        </div>
      </div>
    </div>
  </div>
  <FrequentlyReports />
  <transition name="fade">
    <Downloaded v-if="!isEnd" />
  </transition>
</template>
<script>
  import MainTopLine from '@/components/MainTopLine.vue'
  import { defineComponent, ref, onMounted, watch } from 'vue'
  import StatisticsCategory from '@/components/StatisticsCategory.vue'
  import { onBeforeRouteLeave } from 'vue-router'
  import { onCreated } from 'vue'
  import { api } from '@/api/index.ts'
  import FrequentlyReports from '@/components/FrequentlyReports.vue'
  import Downloaded from '@/components/download.vue'
  import { useI18n } from 'vue-i18n'
  export default defineComponent({
    components: {
      MainTopLine,
      StatisticsCategory,
      FrequentlyReports,
      Downloaded
    },
    setup() {
      let isEnd = ref(false)
      let BulletinIcons = [
        'koyko',
        'obem',
        'obslujen',
        'kol_nomer',
        'kol_mest',
        'kol_obs',
        'edin_vmes',
        'obs_pos',
        'zap_gost'
      ]
      let i = 0
      let bulletinLMore = ref(false)

      BulletinIcons.getIndex = function () {
        if (i < BulletinIcons.length - 1) {
          i++
        } else {
          i = 0
        }
        return BulletinIcons[i]
      }
      let icons = ref([])
      let active
      let category = 4
      let frequentlyDownloadedReports = ref([])
      let bulletins = ref([])

      onMounted(() => {
        active = document.querySelectorAll('.billutin-category')
        active[3].classList.add('StatisticsRoutesAndAttractions')
      })
      onBeforeRouteLeave(() => {
        active[3].classList.remove('StatisticsRoutesAndAttractions')
      })
      async function getFrequentlyViewed() {
        try {
          isEnd.value = false
          const response = await api.get(
            `/v1/chart/categories/${category}/bulletins`
          )
          bulletins.value = response.data
          if (bulletins.value.length > 6) {
            bulletinLMore.value = true
          } else {
            bulletinLMore.value = false
          }
          setTimeout(() => {
            isEnd.value = true
          }, 500)
        } catch (error) {
          console.error(error)
        }
      }
      getFrequentlyViewed()
      const i18n = useI18n()
      watch(i18n.locale, getFrequentlyViewed)
      return {
        frequentlyDownloadedReports,
        bulletins,
        icons,
        BulletinIcons,
        bulletinLMore,
        isEnd,
        category
      }
    }
  })
</script>
<style lang="scss" scoped>
  .billutin-name {
    border: 1px solid rgba(248, 181, 83, 0.5);
    box-sizing: border-box;
    padding: 31px 37px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.5s ease;
    &:hover {
      // box-shadow: 0 0 10px 1px #f5980d;
      background: #6a4bff;
      cursor: pointer;
      .svg-icon--arrow-right {
        width: 50px;
        fill: white;
      }
      p {
        color: white;
      }
      svg {
        fill: white;
      }
    }
    div {
      display: flex;
      width: 80%;
      align-items: center;
    }
    svg {
      transition: 0.6s ease-in-out;
      width: 55px;
      height: 55px;
      fill: #6a4bff;
    }
    .svg-icon--Vectorstatistics {
      stroke: #6a4bff;
      fill: white;
    }
    .svg-icon--arrow-right {
      transition: 0.5s;
      margin-right: 0px;
      width: 40px;
      fill: #6a4bff;
    }
    p {
      transition: 0.4s ease;
      font-family: 'Museo Sans Cyrl';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      margin-left: 23px;
      color: #676d73;
    }
    .svg-size {
      width: 55px;
    }
  }
  .watch-more {
    font-family: 'Museo Sans Cyrl';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
      svg {
        width: 50px;
      }
    }

    /* identical to box height, or 187% */
    p {
      margin-right: 20px;
    }
    color: #ca9e67;
    svg {
      transition: 0.5s ease-in-out;
      width: 40px;
    }
  }

  .bread_crumbs {
    display: flex;
    margin-bottom: 24px;
    margin-top: 20px;
    & a,
    &-line {
      margin-right: 20px;
    }
    & a {
      display: block;
      text-decoration: none;
    }

    & a:hover {
      text-decoration: underline;
    }

    & a:last-child {
      margin-right: 20px;
    }

    &-line,
    & .cooki {
      color: #676d73;
      font-size: 14px;
      font-weight: 300;
    }
  }

  .td {
    margin-top: 20px;
    :hover {
      color: black;
    }
    a {
      text-decoration: none !important;
    }
  }
</style>
