<template>
  <div class="statistics">
    <div class="container">
      <div class="row content">
        <div class="col-12 col-sm-5">
          <label class="label label-ul">
            {{ $t('main.selectOrInput') }}
            <input
              type="text"
              class="input"
              v-on:keyup="searchBulletinTimer"
              v-on:focus="searchBulletinTimer"
              v-model="data.search"
              v-bind:placeholder="inputPlaceBulletinName"
            />
            <svg-icon name="triangle-down" />
          </label>
          <ul class="statistic-list" v-show="changeactive">
            <li
              v-for="bulletin in bulletins"
              :key="bulletin.id"
              @click="clickBulletinById(bulletin.id, bulletin.name)"
            >
              {{ bulletin.name }}
            </li>
          </ul>
        </div>

        <div class="col-12 col-sm-3">
          <label class="label label-ul">
            {{ $t('app.selectPeriod') }}
            <label class="label label-ul">
              <select
                id="select-period"
                @change="showValuesByBulletin()"
                v-model="bulletinDate"
                class="custom-select"
              >
                <option
                  v-for="(period, index) in bulletinPeriod"
                  :key="index"
                  :value="index"
                >
                  {{ period.from_date }} |
                  {{ period.to_date }}
                </option>
                <svg-icon name="calendar" />
              </select>
            </label>
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col results mb-4">
          <button
            v-for="(bulletin, index) in bulletinNameButton"
            :key="index"
            class="button-bulletin button-bulletin_name"
          >
            <div class="button-bulletin_name_div">
              {{ bulletin.name }}
            </div>
            <svg-icon name="dagger" @click="deleteBulletin()" />
          </button>
          <button
            v-for="bulletinAttribute in bulletinAttributesMap"
            :key="bulletinAttribute.id"
            class="button-bulletin button-bulletin_attribute"
          >
            {{ bulletinAttribute.name }}

            <svg-icon
              name="dagger"
              @click="deleteAttributes(bulletinAttribute.id)"
            />
          </button>

          <button
            v-for="bulletinLocation in bulletinLocationsMap"
            :key="bulletinLocation.id"
            class="button-bulletin button-bulletin_location"
          >
            {{ bulletinLocation.name }}
            <svg-icon
              name="dagger"
              @click="deletelocations(bulletinLocation.id)"
            />
          </button>
        </div>
      </div>
      <div class="row diagram">
        <div class="col">
          <p class="text diagram__title">
            {{ $t('main.selectTypeDiagramm') }}
          </p>
          <div class="diagram__tabs tabs mb-4">
            <div class="tabs__item tabs__item--active" @click="setChart('bar')">
              <svg-icon name="bar-chart-activ" />
            </div>
            <div class="tabs__item tabs__item" @click="setAmCharts('bar')">
              <svg-icon name="bar-chart-activ" />
            </div>
            <div class="tabs__item" @click="setChart('line')">
              <svg-icon name="line-chart-activ" />
            </div>
            <div class="tabs__item" @click="setChart('polarArea')">
              <svg-icon name="multiaxis-chart-activ" />
            </div>
            <div class="tabs__item" @click="setChart('pie')">
              <svg-icon name="pie-chart-activ" />
            </div>
            <div class="tabs__item" @click="setChart('doughnut')">
              <svg-icon name="donught-chart-activ" />
            </div>
          </div>
          <h2 class="bulletin-name">{{ graphics.name }}</h2>
          <div class="diagram__content" v-if="!amchartIsActive">
            <p class="text diagram__text"></p>
            <div class="chart">
              <line-chart
                :type="graphics.chart"
                :data="{
                  labels: graphics.labels,
                  datasets: dataValues
                }"
                :options="{
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: [
                      {
                        ticks: { beginAtZero: true }
                      }
                    ]
                  }
                }"
              />
            </div>
          </div>
          <div class="diagram__content" v-if="amchartIsActive">
            <p class="text diagram__text"></p>
            <am-charts :data="amChartsData" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent, reactive, ref, unref, watch, onMounted } from 'vue'

  import MainTopLine from '@/components/MainTopLine.vue'
  import MainFooter from '@/components/MainFooter.vue'
  import LineChart from '@/components/LineChart.vue'
  import { api } from '@/api'
  import AdaptiveMainTopLine from '@/components/AdaptiveMainTopLine.vue'
  import { useRoute, useRouter } from 'vue-router'
  import AmCharts from '@/components/AmCharts.vue'
  export default defineComponent({
    components: {
      AdaptiveMainTopLine,
      MainTopLine,
      MainFooter,
      LineChart,
      AmCharts
    },
    props: {
      local: String
    },
    setup(opts) {
      const amchartIsActive = ref(false)
      let amChartsData = ref([])
      let graphics = reactive({
        labels: [],
        date: '',
        values: [],
        color: [],
        name: '',
        chart: 'bar'
      })
      let data = reactive({
        search: '',
        bulletin: ''
      })
      let currentBulletinId = ref()
      let bulletins = ref([])
      let bulletinPeriod = ref([])
      let bulletinLocations = ref([])
      let bulletinLocationsMap = ref([])
      let bulletinAttributes = ref([])
      let bulletinAttributesMap = ref([])
      let bulletinPeriodSelect = ref()
      let bulletinDate = ref('')
      let bulletinValues = ref([])
      let bulletinArr = ref([])
      let bullettinTable = ref([])
      let changeactive = ref(true)
      let inputPlaceBulletinName = ref('')
      let dataValues = ref([])
      let bulletinNameButton = ref([])
      let frequentlyReports = ref('')
      const route = useRoute()
      let active = ref(false)
      let checkedBulletin = ref(false)
      let arrayColor = [
        '#4DB6AC',
        '#81C784',
        '#4DD0E1',
        '#AED581',
        '#DCE775',
        '#F06292',
        '#BA68C8',
        '#E57373',
        '#FF8A65',
        '#FFB74D',
        '#FFB74D',
        '#4FC3F7',
        '#78DFEC',
        '#6AAECC'
      ]
      let timer
      function searchBulletinTimer() {
        window.clearTimeout(timer)
        timer = window.setTimeout(function () {
          searchBulletin()
          deleteBulletin()
        }, 300)
      }
      async function searchBulletin() {
        try {
          const response = await api.post('/v1/chart/search/bulletin', {
            search: data.search
          })
          bulletins.value = response.data.data.listBulletins
          changeactive.value = true
        } catch (error) {
          bulletins.value = null
        }
      }
      async function clickBulletinById(bulletinId, bulletinName) {
        checkedBulletin.value = true
        try {
          currentBulletinId.value = bulletinId
          const response = await api.post(
            '/v1/chart/search/period',

            {
              bulletin_id: bulletinId
            }
          )
          bulletinPeriod.value = response.data
          bulletinDate.value = '0'
          changeactive.value = !changeactive.value
          inputPlaceBulletinName.value = bulletins.value.find(
            (item) => item.id == currentBulletinId.value
          ).name

          bulletinNameButton.value.push({ name: bulletinName })

          showValuesByBulletin()
        } catch (error) {
          console.error(error)
        }
      }

      async function showValuesByBulletin() {
        try {
          const response = await api.post('/v1/chart/search/location', {
            bulletins: [
              {
                id: currentBulletinId.value
              }
            ]
          })

          bulletinLocations.value = response.data.data.listLocations

          bulletinLocationsMap.value = bulletinLocations.value.map(
            function (obj) {
              return {
                name: obj.name,
                id: obj.location_id,
                values: obj.location_values
              }
            }
          )
          let local = bulletinLocationsMap.value.find(
            (item) => item.name == opts.local
          )

          if (local) {
            bulletinLocationsMap.value[0] = local
            try {
              const response = await api.post('/v1/chart/search/attr', {
                bulletins: [
                  {
                    id: currentBulletinId.value
                  }
                ]
              })
              bulletinAttributes.value = response.data.data.listAttribute

              bulletinAttributesMap.value = bulletinAttributes.value.map(
                function (obj) {
                  return {
                    name: obj.name,
                    id: obj.report_attribute_id,
                    values: obj.attribute_values
                  }
                }
              )
              getValues()
            } catch (error) {
              console.error(error)
            }
          } else {
            alert(
              `В выбранном вами справочнике нет информации о данном городе.`
            )
            deleteBulletin()
            return
          }
          bulletinLocationsMap.value = []
          bulletinLocationsMap.value[0] = local
        } catch (error) {
          console.error(error)
        }
      }

      async function getValues() {
        dataValues.value = []
        let bulletinLocationsId = bulletinLocationsMap.value.map(
          (item) => item.id
        )
        let bulletinAttributesId = bulletinAttributesMap.value.map(
          (item) => item.id
        )

        try {
          // let [startDate, endDate] = bulletinDate.value.split('=')

          const response = await api.post('/v1/chart/values', {
            attrs: bulletinAttributesId,
            locations: bulletinLocationsId,
            from_date: bulletinPeriod.value[bulletinDate.value].from_date,
            to_date: bulletinPeriod.value[bulletinDate.value].to_date
          })

          bulletinValues.value = response.data.data.slice()

          const vals = []
          let bv = []

          for (const bl of bulletinLocationsMap.value) {
            for (const ba of bulletinAttributesMap.value) {
              let pushed = false
              for (const val of bulletinValues.value) {
                if (
                  val.report_attribute_id == ba.id &&
                  val.location_id == bl.id
                ) {
                  vals.push(val)
                  bv.push(val.value)

                  pushed = true
                }
              }

              if (!pushed) {
                vals.push({ value: '-' })
              }
            }
          }

          for (const item of bulletinLocationsMap.value) {
            let row
            let findRow = []
            let backgroundColor = []
            for (const bl of bulletinAttributesMap.value) {
              row = []

              for (const ba of bl.values) {
                if (ba.location_id == item.id) {
                  row.push(ba.value)
                }
              }

              if (row.length == 1) {
                findRow.push(row[0])
              } else {
                findRow.push('-')
              }
              backgroundColor.push(dynamicColors())
            }
            // dataValues.value.push({
            //     data: findRow,
            //     label: item.name,
            //     backgroundColor: backgroundColor,
            //     borderColor: backgroundColor
            // })
            dataValues.value = [
              ...dataValues.value,
              {
                data: findRow,
                label: item.name,
                backgroundColor: backgroundColor,
                borderColor: backgroundColor
              }
            ]
            findRow = []

            graphics.labels = bulletinAttributesMap.value.map(
              (item) => item.name
            )
          }

          bullettinTable.value = []
          for (let i = 0; i < bulletinLocationsMap.value.length; i++) {
            bullettinTable.value[i] = {
              name: bulletinLocationsMap.value[i].name,
              values: vals.slice(0, bulletinAttributesMap.value.length)
            }
            vals.splice(0, bulletinAttributesMap.value.length)
          }

          editGraphics()
        } catch (error) {
          console.error(error)
        }
      }
      function editGraphics() {
        graphics.date =
          bulletinLocations.value[0].location_values[0].from_date +
          '-' +
          bulletinLocations.value[0].location_values[0].to_date

        graphics.name = bulletins.value.find(
          (item) => item.id == currentBulletinId.value
        ).name
      }
      let index = 0
      let loop
      function dynamicColors() {
        if (index == arrayColor.length) {
          loop = true
        } else if (index == 0) {
          loop = false
        }
        if (loop) {
          index = index - 1
        } else {
          index = index + 1
        }
        let color = arrayColor[index]
        return color
      }
      function setChart(typeChart) {
        graphics.chart = typeChart
        active.value = true
        amchartIsActive.value = false
      }

      function setCurrentAttribute(id) {
        bulletinAttributesMap.value = bulletinAttributesMap.value.filter(
          (a) => a !== id
        )
      }
      function deleteAttributes(id) {
        bulletinAttributesMap.value = bulletinAttributesMap.value.filter(
          (a) => a.id !== id
        )

        getValues()
      }
      function deletelocations(id) {
        bulletinLocationsMap.value = bulletinLocationsMap.value.filter(
          (a) => a.id !== id
        )

        getValues()
      }
      function deleteBulletin() {
        bulletinLocationsMap.value = []
        bulletinAttributesMap.value = []
        bulletinNameButton.value = []
        bulletinPeriod.value = []
        bulletinDate.value = ''
        inputPlaceBulletinName.value = ''
        bullettinTable.value = []
        getValues()
      }
      onMounted(() => {
        let chartButtons = document.querySelectorAll('.tabs__item')

        chartButtons.forEach((button) => {
          button.addEventListener('click', function (e) {
            chartButtons.forEach(function (item) {
              item.classList.remove('tabs__item--active')
            })

            button.classList.add('tabs__item--active')
          })
        })
      })
      function setAmCharts(type) {
        amchartIsActive.value = true
        watchAmchgartsData
      }
      let watchAmchgartsData = () => {
        let data = []
        for (const dataValue of dataValues.value) {
          for (let i = 0; i < dataValue.data.length; i++) {
            data.push({
              country: bulletinAttributesMap.value[i].name,
              visits: dataValue.data[i]
            })
          }
        }
        amChartsData.value = data
      }
      watch(dataValues, watchAmchgartsData)
      return {
        amChartsData,
        setAmCharts,
        deletelocations,
        deleteAttributes,
        setCurrentAttribute,
        changeactive,
        searchBulletin,
        bulletins,
        data,
        searchBulletinTimer,
        clickBulletinById,
        bulletinPeriod,
        bulletinLocations,
        bulletinAttributes,
        showValuesByBulletin,
        bulletinPeriodSelect,
        bulletinDate,
        bulletinValues,
        bulletinArr,
        dataValues,
        bullettinTable,
        graphics,
        setChart,
        bulletinLocationsMap,
        bulletinAttributesMap,
        inputPlaceBulletinName,
        bulletinNameButton,
        deleteBulletin,
        active,
        amchartIsActive
      }
    }
  })
</script>
<style lang="scss">
  .bulletin-name {
    color: #16355b;
    margin: 20px 0;
    font-size: 24px;
  }
  .button-bulletin {
    position: relative;

    font-size: 14px;
    white-space: nowrap;
    font-weight: 500;

    border-radius: 7px;
    text-transform: none;
    line-height: 40px;
    border: none;
    & .svg-icon {
      height: 10px;
    }
    &_attribute {
      margin-right: 10px;
      margin-bottom: 10px;
      background: #ffe8da;
      border: #ffe8da;
      color: #ffa26b;
      &:hover {
        background: #ffe8da80;
      }
    }
    &_location {
      margin-right: 10px;
      margin-bottom: 10px;
      background: #d5f2ea;
      border: #d5f2ea;
      color: #00c48c;
      &:hover {
        background: #d5f2ea8f;
      }
    }
    &_name {
      max-width: 400px;

      margin-right: 10px;
      margin-bottom: 10px;
      background: #da74e780;
      border: #da74e780;
      color: #d654e7;
      &:hover {
        background: #da74e748;
      }
      & .svg-icon {
        position: absolute;
        right: 3px;
        top: 16px;
      }
      &_div {
        max-width: 350px;
        margin-right: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
  .custom-select {
    background: white;
    border: 1px solid #ededee;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 12px 15px;
    font-size: 16px;
    color: rgb(22, 53, 91);
    font-weight: 300;
    margin: 5px 0;
    -webkit-appearance: initial;
  }
  ::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
    border-radius: 5px;
    &:hover {
      background: rgb(236, 236, 236);
    }
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(255, 255, 255);
    border-radius: 7px;
    border: 2px solid #c5ab8b;
    &:hover {
      border: 2px solid #e2c4a0;
    }
  }
  ::-webkit-scrollbar {
    width: 15px;
  }
  .statistic-list {
    box-shadow: rgba(0, 0, 0, 0.07) 0px 7px 40px 0px;
    position: absolute;
    width: 40%;
    background: white;
    z-index: 10;
    max-height: 200px;
    overflow-y: scroll;
    padding: 0 5px 5px 10px;
    & li {
      padding: 5px 0;
      &:hover {
        color: #ca9e67;
        cursor: pointer;
      }
    }
  }
  .pop-up {
    max-height: 200px;
    overflow-y: auto;
  }
  .statistics {
    &__button {
      text-decoration: none;
      border-radius: 5px 5px 0 0;
      text-align: center;

      &--active {
        background-color: $main-yellow;
        color: #fff;
      }
    }

    .content {
      .label {
        color: #676d73;
        font-weight: 300;
        position: relative;

        .svg-icon--triangle-down {
          color: $main-yellow;
          width: 12px;
          height: 12px;
          position: absolute;
          top: 40px;
          right: 15px;
        }

        .svg-icon--calendar {
          color: $main-yellow;
          width: 14px;
          height: 14px;
          position: absolute;
          top: 38px;
          right: 15px;
        }
      }
      .input {
        width: 100%;
        margin: 5px 0;
        border: 1px solid #ededee;
      }
    }

    .results {
      &__item {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 4px;
        font-size: 14px;
        line-height: 13px;
        border: none;
        margin-right: 5px;

        &--blue {
          color: #6979f8;
          background-color: #e5e7fa;
        }

        &--green {
          color: #00c48c;
          background-color: #d5f2ea;
        }

        &--orange {
          color: #ffa26b;
          background-color: #ffe8da;
        }

        .svg-icon--dagger {
          width: 8px;
          height: 8px;
          margin-left: 10px;
          fill: $main-blue;
        }
      }
    }

    .diagram {
      margin-bottom: 40px;
      &__title {
        color: $main-yellow;
      }

      &__tabs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;

        .tabs__item {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          width: 145px;
          height: 70px;
          border-radius: 6px;

          .svg-icon {
            fill: #a3a3a3;
          }

          &--active {
            box-shadow: 0px 0px 20px rgb(130 130 130 / 11%);

            .svg-icon {
              fill: $main-blue;
            }
          }
        }
      }

      &__text {
        color: $main-blue;
        font-size: 18px;
        line-height: 28px;
        font-weight: 500;
      }

      &__content {
        position: relative;
        background-color: #fff;
        box-shadow: 0px 0px 20px rgb(130 130 130 / 11%);
        padding: 20px;

        .chart {
          height: 400px;
        }
      }
    }
    table {
      border: 1px;
      text-align: center;
      & th,
      td {
        align-content: center;
        width: 200px;
      }
    }
  }
  // @include media-breakpoint-down(sm) {
  //     .diagram {
  //         &__content {
  //             overflow-x: scroll;
  //         }
  //         & .chart {
  //             width: 1000px;
  //         }
  //         & .statistics__table {
  //             width: 1000px;
  //         }
  //     }
  //     .statistic-list {
  //         width: unset;
  //     }
  //     .button-bulletin_name_div {
  //         max-width: 312px;
  //     }
  // }
</style>
