
  import { ref } from 'vue'

  export default {
    props: {
      placeholder: {
        type: String,
        default: ''
      },
      modelValue: {
        type: String,
        required: true
      },
      field: {
        type: Function,
        default() {
          return (opt) => opt
        }
      },
      disabled: {
        type: Boolean,
        default: false
      },
      icon: {
        type: String,
        default: 'search-normal'
      },
      options: {
        type: Array,
        default() {
          return []
        }
      },
      timeout: {
        type: Number,
        default: 500
      }
    },
    emits: [
      'update:modelValue',
      'update:index',
      'fetch',
      'blur',
      'focus',
      'select'
    ],
    setup(props, context) {
      const timerId = ref()
      const focus = ref(false)

      function input(e) {
        if (timerId.value) clearTimeout(timerId.value)

        timerId.value = setTimeout(
          () => {
            context.emit('fetch')
          },
          props?.timeout || 500
        )

        context.emit('update:modelValue', e.target.value)
      }

      function onFocus() {
        if (props.disabled) return

        focus.value = true

        context.emit('focus')
      }

      function onBlur() {
        focus.value = false

        context.emit('blur')
      }

      function clickOption(option) {
        context.emit('select', option)

        context.emit('update:modelValue', props.field(option))
        focus.value = false
      }

      return {
        input,
        focus,
        onFocus,
        onBlur,
        clickOption
      }
    }
  }
