import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col main-import" }
const _hoisted_4 = { class: "file-select" }
const _hoisted_5 = { class: "main-import__next" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_admin_import = _resolveComponent("admin-import")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_admin_import),
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode("div", _hoisted_4, [
            _createVNode("input", {
              type: "file",
              class: "file-select__file",
              onChange: _cache[1] || (_cache[1] = (...args) => (_ctx.fileChange(...args)))
            }, null, 32)
          ]),
          _createVNode("div", _hoisted_5, [
            (_ctx.active)
              ? (_openBlock(), _createBlock("button", {
                  key: 0,
                  class: "button-using",
                  onClick: _cache[2] || (_cache[2] = $event => (_ctx.postImportFile()))
                }, _toDisplayString(_ctx.$t('admin.continue')), 1))
              : _createCommentVNode("", true),
            (_ctx.changeFile)
              ? (_openBlock(), _createBlock("button", {
                  key: 1,
                  class: "button-change",
                  onClick: _cache[3] || (_cache[3] = $event => (_ctx.DeleteFile()))
                }, _toDisplayString(_ctx.$t('profile.cancel')), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}