import { AxiosInstance } from 'axios'

export class AuthService {
  static readonly TOKEN = 'authService'

  constructor(private readonly api: AxiosInstance) {}

  async login(data: { name: string; pass: string }) {
    const resp = await this.api.post('', data)
    
    return resp
  }
}
