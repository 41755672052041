import { watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export const useTitle = () => {
  const i18n = useI18n()
  const route = useRoute()
  
  const setTitle = () => {
    document.title =
      i18n.t(`pages.${route.meta.name || 'default'}.title`) + ' | Tourstat'

    const metaDesc = document.head.querySelector('meta[name="description"]')

    metaDesc.setAttribute(
      'content',
      i18n.t(`pages.${route.meta.name || 'default'}.description`)
    )
  }

  watch(i18n.locale, setTitle)
  watch(route, setTitle)

  setTitle()
}
