
  import { defineComponent, reactive, ref } from 'vue'
  import AdminMainTopLine from '@/components/AdminMainTopLine.vue'
  import LineChart from '@/components/LineChart.vue'
  import { api } from '@/api'
  import AdminAdaptiveMainTopLine from '@/components/AdminAdaptiveMainTopLine.vue'

  export default defineComponent({
    components: {
      AdminMainTopLine,
      LineChart,
      AdminAdaptiveMainTopLine
    },

    setup() {
      let timer
      let search = ref()
      const users = ref([])
      let changeactive = ref(false)
      let user1 = ref('')
      let userinfo = ref('')
      getUsers()
      const active = ref(false)
      let changeactive1 = ref(false)
      let userRole = ref('')
      const data = reactive({
        role: '',
        fullname: '',
        email: '',
        password: '',
        password_repl: '',
        search: ''
      })
      const userput = reactive({
        role: ''
      })
      let userPage = ref([])

      async function getUsers() {
        try {
          const response = await api.get('/v1/admin/users?perPage=25')
          users.value = response.data.data.users.data
          userPage.value = response.data.data.users.links
          userPage.value[0].label = '&#11160;'
          userPage.value[userPage.value.length - 1].label = '&#11162;'
        } catch (error) {
          console.error(error)
        }
      }

      const createUser = async () => {
        try {
          const response = await api.post(
            '/v1/admin/user/create',
            {
              role: data.role,
              fullname: data.fullname,
              email: data.email,
              password: data.password,
              password_repl: data.password_repl
            },
            {
              headers: {}
            }
          )
          active.value = !active.value
        } catch (error) {
          console.error(error)
        }
      }
      async function removeUserById(id) {
        try {
          const response = await api.delete('/v1/admin/user/delete/' + id)
          users.value = users.value.filter((a) => a.id !== id)
          changeactive.value = !changeactive.value
        } catch (error) {
          console.error(error)
        }
      }
      async function changeUserById(id) {
        try {
          const response = await api.put('/v1/admin/user/edit/' + id, {
            role: userput.role
          })
          window.location.reload()
          changeactive1.value = !changeactive1.value
        } catch (error) {
          console.error(error)
        }
      }

      function endAndStartTimer() {
        window.clearTimeout(timer)
        timer = window.setTimeout(function () {
          searchUser()
        }, 300)
      }
      async function searchUser() {
        try {
          const response = await api.post('/v1/admin/user/search', {
            word: data.search
          })
          users.value = response.data.data.users.data
        } catch (error) {}
      }
      function selectUserRole(userRole) {
        if ('Админ' == userRole || userRole == 'Admin') {
          userput.role = 'Admin'
        } else if (userRole == 'guide') {
          userput.role = 'Guide'
        } else if (userRole == 'Content-Manager') {
          userput.role = 'Content-Manager'
        }
      }
      async function pageUser(url) {
        try {
          const response = await api.get(url)
          users.value = response.data.data.users.data
          userPage.value = response.data.data.users.links
          userPage.value[0].label = '&#11160;'
          userPage.value[userPage.value.length - 1].label = '&#11162;'
        } catch (error) {
          console.error(error)
        }
      }
      return {
        selectUserRole,
        userRole,
        users,
        search,
        active,
        data,
        createUser,
        removeUserById,
        changeactive,
        user1,
        userinfo,
        changeactive1,
        changeUserById,
        userput,
        endAndStartTimer,
        searchUser,
        userPage,
        pageUser
      }
    }
  })
