
  import { defineComponent, ref } from 'vue'
  import AdminMainTopLine from '@/components/AdminMainTopLine.vue'

  import AdminImport from '@/components/Adminimport.vue'
  import AdaptiveMainTopLine from '@/components/AdaptiveMainTopLine.vue'
  import AdminAdaptiveMainTopLine from '@/components/AdminAdaptiveMainTopLine.vue'
  import router from '@/router'
  import { api } from '@/api'
  import Downloaded from '@/components/download.vue'

  export default defineComponent({
    components: {
      AdminMainTopLine,
      AdminImport,
      AdaptiveMainTopLine,
      AdminAdaptiveMainTopLine,
      Downloaded
    },
    setup() {
      let bulletinsStatGov = ref([])
      let endpending = ref()

      async function getBulletinStatGov() {
        try {
          endpending.value = false
          const response = await api.get('/v1/admin/import/statgov')
          bulletinsStatGov.value = response.data.data.bulletins
          endpending.value = true
        } catch (error) {
          console.error(error)
          endpending.value = true
          alert('Ошибка сервера')
        }
      }
      getBulletinStatGov()
      async function setBulletinStatGov(id, name) {
        try {
          const response = await api.get(
            '/v1/admin/import/statgov-segment?title=' + name + '&p_period_id=5&id=' + id
          )
          globalThis.ImportStatGovFile = response.data
          endpending.value = true

          router.push({ name: 'ImportFileStatGov' })
        } catch (error) {
          console.error(error)
          alert('Ошибка сервера')
        }
      }
      return { bulletinsStatGov, setBulletinStatGov, endpending }
    }
  })
