
import { computed, onMounted } from 'vue'
import store from '@/store'

export default {
  setup() {
    const articles = computed(() => {
      return store.getters._getHomeArticles?.map((a) => {
        return {
          ...a,
          created_at: a.created_at.split('T')[0]
        }
      }) || []
    })

    const getFile = computed(() => (file) => {
      return `${process.env.VUE_APP_FILES_PATH}${file}`
    })

    onMounted(() => {
      store.dispatch('_setHomeArticles')
    })

    return {
      articles,
      getFile
    }
  }
}
