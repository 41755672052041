
  import { ref, reactive } from 'vue'

  import { api } from '@/api'
  import router from '@/router'

  export default {
    setup() {
      const authProfile = ref(false)
      const isAdmin = ref(false)
      const data = reactive({
        name: 'профиль',
        url: '/login'
      })

      getProfile()

      async function getProfile() {
        try {
          const token = localStorage.getItem('token')
          if (token) {
            authProfile.value = true
            data.url = '/profile'

            if ('Admin' == localStorage.getItem('role')) {
              isAdmin.value = true
            } else {
              isAdmin.value = false
            }
          } else {
            data.url = '/login'
            authProfile.value = false
          }
        } catch (error) {
          data.url = '/login'
          authProfile.value = false
        }
      }

      async function logout() {
        try {
          await api.get('/auth/logout')
          await router.push({ name: 'Main' })
          window.location.reload()
        } catch (error) {
          console.log(error)
        } finally {
          localStorage.removeItem('token')
          localStorage.removeItem('role')
        }
      }

      return {
        authProfile,
        isAdmin,
        data,
        logout
      }
    }
  }
