
  import { defineComponent, onMounted, onUpdated, ref, watch } from 'vue'

  import { api } from '@/api'
  import { useI18n } from 'vue-i18n'
  import { Button } from '@amcharts/amcharts4/core'
  import { eventTarget } from '@amcharts/amcharts4/.internal/core/utils/DOM'
  export default defineComponent({
    setup() {
      let checkbox
      const faq = ref([])
      async function getFaq() {
        try {
          const response = await api.get('/v1/FAQ')
          faq.value = response.data.data.staticPages
        } catch (error) {
          console.error(error)
        }
      }

      getFaq()
      let saveElement
      const i18n = useI18n()
      watch(i18n.locale, getFaq)
      onUpdated(() => {
        checkbox = document.querySelectorAll('.checkbox-faq')
      })

      document.onclick = function (e) {
        let h3 = document.querySelectorAll('h3')
        for (const h of h3) {
          if (e.target == h) {
            return
          }
        }

        if (saveElement == e.target) {
          saveElement.checked = false
        }
        saveElement = e.target
      }
      return { faq }
    }
  })
