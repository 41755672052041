<template>
  <div class="container mb-4">
    <div class="row">
      <div class="col-12">
        <h2 class="section__title">
          {{ $t('fequently.FrequentlyViewed') }}
        </h2>
      </div>
    </div>
  </div>
  <div class="container mb-300">
    <div class="row">
      <div
        class="col-6 mb-4"
        v-for="(report, index) in frequentlyDownloadedReports"
        :key="report.id"
      >
        <router-link
          style="text-decoration: none"
          :to="{
            name: 'statisticsResult',
            params: {
              newBulletinId: report.id,
              categoryId: report.report_category_id,
              newBulletinName: report.name
            }
          }"
        >
          <div class="bulletin">
            <img
              class="bulletin__photo"
              v-bind:src="require('@/assets/images/' + photoArray[index])"
              alt=""
              srcset=""
            />
            <div class="bulletin__text">
              <p class="bulletin__text-name">
                {{ report.name }}
              </p>
              <p class="bulletin__text-date">
                <!-- Январь - Сентябрь 2020 -->
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent, watch } from '@vue/runtime-core'
  import { ref } from 'vue'
  import { api } from '@/api/index.ts'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    setup() {
      let frequentlyDownloadedReports = ref([])
      let arrayPhoto = ref([
        'MaskGroup(3).png',
        'MaskGroup.png',
        'MaskGroup(1).png',
        'MaskGroup(2).png'
      ])
      let photoArray = ref([])
      let i = 0
      arrayPhoto.value.getIndex = function () {
        if (i < arrayPhoto.value.length - 1) {
          i++
        } else {
          i = 0
        }
        return arrayPhoto.value[i]
      }
      async function frequentReports() {
        try {
          const response = await api.get('/v1')

          frequentlyDownloadedReports.value =
            response.data.data.frequentlyDownloadedReports
          frequentlyDownloadedReports.value.length = 4
          frequentlyDownloadedReports.value.forEach(() => {
            photoArray.value.push(arrayPhoto.value.getIndex())
          })
        } catch (error) {
          console.error(error)
        }
      }
      frequentReports()
      const i18n = useI18n()
      watch(i18n.locale, frequentReports)
      return {
        frequentlyDownloadedReports,
        arrayPhoto,
        photoArray
      }
    }
  })
</script>
<style lang="scss" scoped>
  .section__title {
    width: 100%;
    margin-bottom: 14px;
    height: 46px;
    font-weight: 500;
    font-size: 26px;
    line-height: 35px;
    text-transform: uppercase;
    color: $main-blue;
    padding-bottom: 65px;
    border-bottom: 1px solid $main-yellow;
  }
  .bulletin {
    background: rgba(22, 53, 91, 0.7);

    transition: 0.5s ease;

    position: relative;
    height: 210px;
    border-radius: 6px;
    &__text {
      position: absolute;
      bottom: 34px;
      left: 42px;
      &-name {
        text-transform: inherit;
        font-family: 'OpenSans italic';
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 26px;
        /* identical to box height */
        margin-bottom: 9px;

        text-transform: inherit;

        color: #ffffff;
      }
      &-date {
        font-family: 'OpenSans italic';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height */

        color: #ffffff;
      }
    }
    &__photo {
      border-radius: 6px;
      mix-blend-mode: multiply;

      position: absolute;
      width: 100%;
      top: 0%;
      left: 0%;
      height: 100%;
      object-fit: cover;
    }
    &:hover {
      cursor: pointer;
      background: rgba(22, 53, 91, 0.8);

      &__photo {
      }
    }
  }
</style>
