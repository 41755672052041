
  import { ref, computed, onMounted } from 'vue'
  import store from '@/store'
  import { api } from '@/api'
  import router from '@/router'

  import UiSearch from '@/components/ui/UiSearch.vue'
  import DropDownLoadFile from '@/components/ui/DropDownLoadFile.vue'

  export default {
    components: { UiSearch, DropDownLoadFile },
    setup() {
      const step = ref(0)
      const changeactive1 = ref(false)

      let selectedBull = null
      let selectedBullPeriod = null

      const bullSearchText = ref('')
      const bullOptions = computed(() => {
        return store.getters._getBulletins
      })
      async function fetchBulls() {
        await store.dispatch('_setBulletins', bullSearchText.value)
      }
      function selectBull(bull) {
        selectedBull = bull
        fetchBulletins(bull.id)

        bulletinPeriod.value = ''
        bulletinPeriods.value = []
        selectedBullPeriod = null
        step.value = 1
      }

      const bulletinPeriod = ref('')
      const bulletinPeriods = ref([])
      async function fetchBulletins(bulletinId) {
        try {
          const response = await api.post('/v1/chart/search/period', {
            bulletin_id: bulletinId
          })

          bulletinPeriods.value = response.data
        } catch (e) {
          console.log(e)
        }
      }
      function selectBullPeriod(bull) {
        step.value += 1
        selectedBullPeriod = bull
      }

      async function selectFile(type) {
        try {
          if (!selectedBull || !selectedBullPeriod) return

          const response = await api.get(
            '/v1/' +
              (type || 'excel') +
              '-download?' +
              'id=' +
              selectedBull.id +
              '&fromDate=' +
              selectedBullPeriod.from_date +
              '&toDate=' +
              selectedBullPeriod.to_date
          )
          let path = 'https://back.tourstat.kz/' + response.data.file_path

          window.open(path)
        } catch (error) {
          changeactive1.value = true
          console.log(error)
        }
      }

      onMounted(() => {
        fetchBulls()
      })

      async function routerLink() {
        await router.push({ name: 'login' })
      }

      return {
        step,
        bullSearchText,
        bullOptions,
        fetchBulls,
        selectBull,
        bulletinPeriod,
        bulletinPeriods,
        selectBullPeriod,
        selectFile,
        changeactive1,
        routerLink
      }
    }
  }
