<template>
  <div class="statistics-result">
    <div class="container" style="margin-bottom: 30px;">
      <BreadCrumbs :links="[{ to: 'statistics-three', name: 'adaptiveTop.statistics' }]" />
      <SectionTitle :title="$t('pages.statistics.title1')" class="statistics-result__title" />
      <StatCategories :body="false" />
      <div class="row">
        <p v-if="currentBulletin" class="statistics-result__subtitle">{{ currentBulletin.bulletin_name }}</p>
      </div>
      <div class="row">
        <div class="col-12 mb-3 col-md-3 mb-md-0">
          <DropDown v-if="currentBulletin" v-model="currentBulletin" :title="$t('statisticsResult.indicator')"
            :options="bulletinsList" :field="'name'" @change="fetchDataByBull" />
        </div>
        <div class="col-12 mb-3 col-md-3 mb-md-0">
          <DropDown v-if="selectRegion" v-model="selectRegion" :title="$t('statisticsResult.region')"
            :options="regionsList" :field="'name'" @change="fetchDataByYears" />
        </div>
        <div class="col-12 col-md-3">
          <DropDown v-if="selectedDate" v-model="selectedDate" :title="$t('statisticsResult.year')" :options="date"
            @change="fetchDataByLocations" />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="overlay section">
            <div class="graph">
              <div>
                <p class="section__prediction" :class="{
                  'section__prediction-active': predictionIsActive
                }" v-if="predictionMessageIsActive" @click="changePrediction()">
                  {{ $t('statisticsResult.prediction') }}
                </p>
                <div v-if="labelType" class="buttons">
                  <template v-if="labelType.val === 'location'">
                    <button class="button-parameter" v-for="year in yearsList" :key="year">
                      {{ year }}
                      <SvgIcon name="delete-parameter" @click="deleteYearButtons(year)" />
                    </button>
                  </template>
                  <template v-else>
                    <button class="button-parameter" v-for="region in regions" :key="region.location_id">
                      {{ region.name }}
                      <SvgIcon name="delete-parameter" @click="deleteRegion(region.location_id)" />
                    </button>
                  </template>
                </div>
                <div class="choice-indicator">
                  <div class="choice">
                    <span class="graphics" :class="{ 'choice-active': !showtable }" @click="showtable = false">
                      {{ $t('statisticsResult.chart') }}</span>
                    <span class="table" :class="{ 'choice-active': showtable }" @click="showtable = true">
                      {{ $t('statisticsResult.table') }}</span>
                  </div>
                  <button class="download">
                    {{ $t('statisticsResult.download') }}
                    <SvgIcon name="arrow-long-right" />
                    <div class="expand">
                      <div class="expand__item" @click="downloadFileExel()">
                        <svg-icon name="excel-file" />
                        Excel File
                      </div>

                      <div class="expand__item" @click="downloadFilePDF()">
                        <svg-icon name="pdf-file" />

                        PDF File
                      </div>
                    </div>
                  </button>
                </div>
                <table v-if="showtable">
                  <thead>
                    <tr>
                      <th v-for="(th, index) in tableValues.thead" :key="index">
                        {{ th }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(tr, index) in tableValues.tBody" :key="index">
                      <td v-for="(td, i) in tr" :key="i">
                        {{ td.toString() }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <section class="charts">
                  <!-- <div class="select-chart">
                    <div class="select-item" :class="graphics.chart === 'line' ? 'active' : ''"
                      @click="graphics.chart = 'line'">
                      <svg-icon name="line_chart_active" v-if="graphics.chart === 'line'" />
                      <svg-icon name="line_chart" v-else />
                    </div>
                    <div class="select-item" :class="graphics.chart === 'multi' ? 'active' : ''"
                      @click="graphics.chart = 'multi'">
                      <svg-icon name="multi_chart_active" v-if="graphics.chart === 'multi'" />
                      <svg-icon name="multi_chart" v-else />
                    </div>
                    <div class="select-item" :class="graphics.chart === 'bar' ? 'active' : ''"
                      @click="graphics.chart = 'bar'">
                      <svg-icon name="bar_chart_active" v-if="graphics.chart === 'bar'" />
                      <svg-icon name="bar_chart" v-else />
                    </div>
                    <div class="select-item" :class="graphics.chart === 'pie' ? 'active' : ''"
                      @click="graphics.chart = 'pie'">
                      <svg-icon name="pie_chart_active" v-if="graphics.chart === 'pie'" />
                      <svg-icon name="pie_chart" v-else />
                    </div>
                    <div class="select-item" :class="graphics.chart === 'doughnut' ? 'active' : ''"
                      @click="graphics.chart = 'doughnut'">
                      <svg-icon name="doughnut_chart_active" v-if="graphics.chart === 'doughnut'" />
                      <svg-icon name="doughnut_chart" v-else />
                    </div>
                    <div class="select-item" :class="graphics.chart === 'polarArea' ? 'active' : ''"
                      @click="graphics.chart = 'polarArea'">
                      <svg-icon name="sunburst_chart_active" v-if="graphics.chart === 'polarArea'" />
                      <svg-icon name="sunburst_chart" v-else />
                    </div>
                  </div> -->
                  <div class="chart" key="edit">
                    <AllCharts :data="
                      graphics.chart === 'multi'
                        ? [...dataValues, ...republicDatas]
                        : dataValues
                      " :labels="graphics.labels" :chart="graphics.chart" />
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <transition name="slide-fade">
        <div v-if="changeactive1" id="myModal" class="modal">
          <div class="modal-content modal-content__auth">
            <span class="close" @click="changeactive1 = !changeactive1">&times;</span>
            <div class="modalcontent">
              <h2 class="modalcontent__h2">
                {{ $t('message.authorized') }}
                <div class="modalcontent__margin__auth">
                  <div class="buttons">
                    <button class="button-using" @click="$router.push('/login')">
                      {{ $t('profile.logIn') }}
                    </button>
                    <button @click="changeactive1 = !changeactive1"
                      class="button-create-new-report button-create-new-report__auth">
                      {{ $t('profile.cancel') }}
                    </button>
                  </div>
                </div>
              </h2>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  reactive,
  onUpdated,
  onBeforeMount,
  onCreated,
  watch
} from 'vue'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { api } from '@/api'

import store from '@/store'

import graphColors from '@/consts/graphColors'

import getItemByOrder from '@/helpers/getItemByOrder'

import BreadCrumbs from '@/components/ui/BreadCrumbs.vue'
import SectionTitle from '@/components/ui/SectionTitle.vue'
import DropDown from '@/components/ui/DropDown.vue'
import StatCategories from '@/components/pages/statistics/StatCategories.vue'

import StatisticsCategory from '@/components/StatisticsCategory.vue'
import FrequentlyReports from '@/components/FrequentlyReports.vue'
import LineChart from '@/components/LineChart.vue'
import AllCharts from '@/components/AllCharts.vue'


function formatDateToYears(date) {
  return date.slice(0, 4)
}

const statTranslates = {
  en: 'name_en',
  ru: 'name',
  kz: 'name_kz'
}

export default defineComponent({
  components: {
    BreadCrumbs,
    SectionTitle,
    DropDown,
    StatCategories,
    StatisticsCategory,
    FrequentlyReports,
    LineChart,
    AllCharts
  },
  setup() {
    const showtable = ref(false)
    const authorization = ref(false)
    const changeactive1 = ref(false)

    const getColor = getItemByOrder(graphColors)

    const $route = useRoute()
    const $router = useRouter()
    const { t: $t, locale } = useI18n()

    const labelType = ref()

    const bulletinsList = ref([])
    const currentBulletin = ref()

    async function getbulletins() {
      try {
        const response = await api.get(
          `/v1/chart/categories/${Number($route.params.categoryId)}/bulletins`
        )
        bulletinsList.value = response.data
        currentBulletin.value = response.data.find((b) => b.id == $route.params.newBulletinId)
      } catch (error) {
        console.error(error)
      }
    }

    const attributes = ref([])

    async function getAttribute() {
      try {
        const response = await api.post('/v1/chart/search/attr', {
          bulletins: [
            {
              id: +$route.params.newBulletinId
            }
          ]
        })

        attributes.value = response.data.data.listAttribute
      } catch (error) {
        console.error(error)
      }
    }

    const regionsList = ref([])
    const selectRegion = ref()
    const regions = ref([])
    const regionsId = computed(() => {
      return regions.value.map((r) => r.id)
    })

    async function getBulletinLocation() {
      try {
        const response = await api.post('/v1/chart/search/location', {
          bulletins: [
            {
              id: $route.params.newBulletinId
            }
          ]
        })
        regionsList.value = response.data.data.listLocations
        selectRegion.value = regionsList.value[0] || null
        regions.value = response.data.data.listLocations.slice(1)
      } catch (error) {
        console.error(error)
      }
    }

    async function deleteRegion(id) {
      regions.value = regions.value.filter(
        (item) => item.location_id != id
      )
    }

    const date = ref([])
    const selectedDate = ref()
    const yearsList = ref([])

    async function getBulletinPeriod() {
      try {
        const response = await api.post(
          '/v1/chart/search/period',
          {
            bulletin_id: $route.params.newBulletinId
          }
        )

        date.value = ['Все года', ...response.data.map((a) => {
          return +formatDateToYears(a.from_date)
        }).sort((a, b) => b - a)]
        selectedDate.value = date.value[0]
      } catch (error) {
        console.error(error)
      }
    }

    function deleteYearButtons(value) {
      yearButtons.value = yearButtons.value.filter(
        (y) => y != value
      )
    }

    const predictionIsActive = ref(false)
    const predictionMessageIsActive = ref(false)
    const tableValues = reactive({
      thead: [],
      tBody: []
    })
    const graphics = reactive({
      labels: [],
      date: '',
      values: [],
      color: [],
      name: '',
      chart: 'line'
    })
    const dataValues = ref([
      {
        backgroundColor: [],
        borderColor: [],
        data: [],
        fill: false
      }
    ])
    const republicDatas = ref([])
    let saveData
    let saveDataYears
    let allYears = 'Все года'
    let years = 'год'
    let city = 'Город'
    const bulletinValues = ref([])
    // const arrayColor = []

    const chartOption = ref()

    async function fetchDataByYears() {
      try {
        regions.value = [selectRegion.value]
        labelType.value = { val: 'location' }
        const response = await api.post('/v1/chart/values-years', {
          reportId: currentBulletin.value.id,
          attrId: attributes.value[0].report_attribute_id,
          locationsId: [selectRegion.value.id]
        })

        bulletinValues.value = response.data?.data

        getGraphicsOneLocationYears()
      } catch (error) {
        console.error(error)
      }
    }

    async function getGraphicsOneLocationYears() {
      predictionIsActive.value = false

      let arrayData = []
      let arrayPrediction = []
      const data = []
      const backgroundColor = []
      const yearButtons = []
      const tBody = []

      bulletinValues.value.forEach((b) => {
        if (b.prediction) arrayPrediction.push(b)
        else arrayData.push(b)
      })
      arrayPrediction = arrayPrediction.filter((b) => !arrayData.find((i) => i.from_year === b.from_year))
      arrayData = arrayData.concat(arrayPrediction).map((p) => {
        const year = typeof p.from_year === 'number' ? p.from_year : p.from_year.split('-')[0]

        data.push(p.value)
        yearButtons.push(year)
        tBody.push([year, p.value])
        if (p.prediction) backgroundColor.push('#00FF00')
        else backgroundColor.push(getColor())

        return {
          ...p,
          from_year: year
        }
      })
      predictionMessageIsActive.value = !!arrayPrediction.length
      yearsList.value = yearButtons
      graphics.labels = yearButtons

      dataValues.value = [
        {
          label: 'Выберите регион',
          data: data,
          backgroundColor: backgroundColor,
          fill: false
        }
      ]
      saveData = dataValues.value
      saveDataYears = yearButtons
      if (!republicDatas.value || !republicDatas.value.length || !republicDatas.value[0]) {
        republicDatas.value = [
          {
            label: 'Данные по всему Казахстану',
            data: data,
            backgroundColor: backgroundColor,
            borderColor: '#04a9c4',
            fill: false
          }
        ]
      }
      republicDatas.value[0].label = 'Данные по всему Казахстану'
    }

    async function fetchDataByLocations() {
      try {
        yearsList.value = [selectedDate.value]
        regions.value = regionsList.value
        labelType.value = { val: 'year' }

        let data = isNaN(Number(selectedDate.value)) ? {} : {
          from_date: `${selectedDate.value}-01-01`,
          to_date: `${selectedDate.value}-12-31`,
        }

        const response = await api.post('/v1/chart/values', {
          locations: regionsId.value,
          attrs: [attributes.value[0].report_attribute_id],
          ...data,
        })

        bulletinValues.value = response.data?.data

        getGraphicsOneYearsLocation()
      } catch (error) {
        console.error(error)
      }
    }

    async function getGraphicsOneYearsLocation() {
      try {
        let value = []
        for (const location of regions.value) {
          for (const val of bulletinValues.value) {
            if (location.location_id == val.location_id) {
              let l = location.name
              let v = val.value
              value.push({ location: l, value: v })
            }
          }
        }

        let arrayData = []
        let arrayBackground = []
        graphics.labels = []
        tableValues.thead = []
        tableValues.tBody = []
        dataValues.value = []
        for (const data of value) {
          arrayData.push(data.value)
          graphics.labels.push(data.location)
          tableValues.tBody.push([data.location, data.value])
          arrayBackground.push(getColor())
        }
        tableValues.thead = [city, `${selectedDate.value}`]
        dataValues.value = [
          {
            label: selectedDate,
            data: arrayData,
            backgroundColor: arrayBackground,
            borderColor: arrayBackground,
            fill: false
          }
        ]
        if (republicDatas.value[0]?.label) {
          republicDatas.value[0].label = date.value
          .slice(-1)[0]
          .from_date
        }
      } catch (error) {
        console.log(error)
      }
    }

    // function deleteYearButtons(from) {
    //   dataValues.value[0].data = dataValues.value[0].data.filter(
    //     (item, index) => index != from
    //   )
    //   graphics.labels = yearButtons.value
    //   tableValues.tBody = tableValues.tBody.filter(
    //     (item, index) => index != from
    //   )
    // }

    // watch(() => regions.value, () => {
    //   graphics.labels = []

    //   if (yearsIsActive.value) {
    //   } else {
    //     let arrayRegion = regions.value.map((item) => item.location_id)
    //     await getDoubleValues(arrayRegion)
    //   }
    // })

    onMounted(async () => {
      await Promise.all([
        await store.dispatch('_fetchDigestCategorys'),
        await getAttribute(),
        await getbulletins(),
        await getBulletinLocation(),
        await getBulletinPeriod()
      ])
      // fetchDataByYears()
      fetchDataByLocations()
    })

    let yearButtons = ref([])
    let yearsIsActive = ref(false)
    let graphics1 = []
    let currentBulletinId

    function fetchDataByBull(bull) {
      $router.push({
        name: 'statisticsResult',
        params: {
          newBulletinId: bull.id,
          categoryId: $route.params.categoryId,
          newBulletinName: getStatTreeName(bull) || bull.name
        }
      })
      // getBulletin(bull.id, bull.name)
    }

    function getStatTreeName(stat) {
      return stat[statTranslates[locale.value]]
    }

    async function getBulletin(id, name) {
      predictionMessageIsActive.value = false
      yearButtons.value = []

      yearsIsActive.value = false
      currentBulletinId = id
      await getBulletinPeriod()
      await getBulletinLocation()
      await getAttribute()
      graphics.labels = []
      let regionsArray = regions.value.map((item) => item.location_id)
      await getDoubleValues(regionsArray)
    }

    onUpdated(() => {
      document.addEventListener('DOMContentLoaded', function () {
        var tooltipTriggerList = [].slice.call(
          document.querySelectorAll('[data-bs-toggle="tooltip"]')
        )
        var tooltipList = tooltipTriggerList.map(function (element) {
          return new bootstrap.Tooltip(element)
        })
      })
    })

    function getDoubleValues(locationId) {
      let tr = []
      tableValues.tBody = []
      tableValues.thead = [city]
      let locations = []
      let getDoubleValues = new Promise(async (resolve) => {
        try {
          const response = await api.post('/v1/chart/values-years', {
            reportId: currentBulletinId,
            attrId: attributes.value[0].report_attribute_id,
            locationsId: locationId
          })
          bulletinValues.value = response.data?.data

          resolve('resolve')
          let arrayValue = {
            from_year: [],
            value: []
          }
          locations = []
          for (let region of regions.value) {
            for (let bulletin of bulletinValues.value) {
              if (region.location_id == bulletin.location_id) {
                arrayValue.from_year = [
                  ...arrayValue.from_year,
                  bulletin.from_year
                ]
                arrayValue.value = [...arrayValue.value, bulletin.value]
              }
            }
            locations.push({
              location: region.name,
              regionId: region.location_id,
              values: arrayValue.value,
              from_years: arrayValue.from_year
            })
            arrayValue = {
              from_year: [],
              value: []
            }
          }
        } catch (error) {
          console.error(error)
        }
      })
      getDoubleValues.then(() => {
        let bc = []
        let years
        for (const region of locations) {
          years = []
          // bc.push(arrayColor.getColor())
          if (region.regionId == regions.value[0].location_id) {
            dataValues.value = [
              {
                backgroundColor: bc,
                borderColor: bc,
                data: region.values,
                fill: false,
                label: locations[0].location
              }
            ]
            graphics.labels = region.from_years
            years = region.from_years.slice(
              region.from_years.length - 3,
              region.from_years.length
            )
          }
          let yearsValue = region.values.slice(
            region.values.length - 3,
            region.values.length
          )

          tableValues.tBody.push([region.location, ...yearsValue])
          tableValues.thead.push(...years)
        }
      })
    }

    async function downloadFileExel() {
      if (window.localStorage.getItem('token') !== null) {
        authorization.value = true

        try {
          const response = await api.get(
            '/v1/excel-download?' +
            'id=' +
            $route.params.newBulletinId +
            '&fromDate=' +
            `${selectedDate.value}-01-01` +
            '&toDate=' +
            `${selectedDate.value}-12-31`
          )
          let path = 'https://back.tourstat.kz/' + response.data.file_path

          window.open(path)
        } catch (error) {
          console.log(error)
        }
      } else {
        authorization.value = false
        changeactive1.value = true
      }
    }

    async function downloadFilePDF() {
      if (window.localStorage.getItem('token') !== null) {
        authorization.value = true

        try {
          const response = await api.get(
            '/v1/pdf-download?' +
            'id=' +
            $route.params.newBulletinId +
            '&fromDate=' +
            `${selectedDate.value.from}-01-01` +
            '&toDate=' +
            `${selectedDate.value.from}-12-31`
          )
          let path = 'https://back.tourstat.kz/' + response.data.file_path

          window.open(path)
        } catch (error) { }
      } else {
        authorization.value = false
        changeactive1.value = true
      }
    }

    function changePrediction() {
      let array = []
      let data = []
      let backgroundColor = []
      graphics.labels = []
      yearButtons.value = []
      if (!predictionIsActive.value) {
        yearButtons.value = []

        for (let item of bulletinValues.value) {
          if (!item.prediction) {
            data.push(item.value)
            // arrayColor.getColor()
            yearButtons.value.push(item.from_year)
            // backgroundColor.push(arrayColor.getColor())
          } else backgroundColor.push(backgroundColor.push('#00FF00'))
        }
        graphics.labels = yearButtons.value
        dataValues.value = [
          {
            label: currentBulletin.value?.name,
            data: data,
            backgroundColor: backgroundColor,
            borderColor: backgroundColor,
            fill: false
          }
        ]
      } else {
        dataValues.value = saveData
        graphics.labels = saveDataYears
        yearButtons.value = saveDataYears
      }
      predictionIsActive.value = !predictionIsActive.value
    }

    return {
      currentBulletin,
      labelType,
      bulletinsList,
      regionsList,
      selectRegion,
      regions,
      deleteRegion,
      date,
      selectedDate,
      yearsList,
      deleteYearButtons,
      predictionIsActive,
      predictionMessageIsActive,
      tableValues,
      graphics,
      dataValues,
      republicDatas,
      showtable,
      fetchDataByYears,
      fetchDataByLocations,
      fetchDataByBull,

      downloadFileExel,
      downloadFilePDF,
      changeactive1,
      getbulletins,
      yearButtons,
      formatDateToYears,
      changePrediction,
    }
  }
})
</script>

<style lang="scss" scoped>
.statistics-result {
  padding-bottom: 80px;

  &__title {
    margin-bottom: 50px;

    @media screen and (max-width: 576px) {
      margin-bottom: 30px;
    }
  }

  &__subtitle {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.2;
    color: $main-blue;
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
      font-size: 28px;
    }

    @media screen and (max-width: 576px) {
      font-size: 24px;
    }
  }

  .container {
    overflow: unset !important;
    overflow-y: unset !important;
  }
}

.graph {
  overflow-x: auto;

  & > div {
    min-width: 600px;
  }

  @media screen and (max-width: 576px) {
    padding-bottom: 15px;
  }

  &::-webkit-scrollbar {
    margin-bottom: 15px;
    width: 7px;
    border-radius: 7px;
  }

  &::-webkit-scrollbar-track {
    background: $lightgray;
  }

  &::-webkit-scrollbar-thumb {
    background: $main-green;
    border-radius: 7px;
    border: none;
  }
}

.container.mb-5 {
  margin-bottom: 34px !important;
}

.regions {
  display: flex;
  margin-top: 4px;
  position: absolute;
  background: #ca9e67;
  border-radius: 5px;
  width: 536px;
  overflow: hidden;
  overflow-x: scroll;
  left: 0;
  flex-direction: column;
  flex-wrap: wrap;
  height: 280px;
  padding: 10px 10px 10px 0;
  justify-content: flex-start;
  box-sizing: border-box;

  li {
    width: 50%;
    padding-left: 10px;
    display: flex;
    color: white;
    line-height: 24px;
    font-family: 'Museo Sans Cyrl';
    font-size: 14px;
    line-height: 28px;
    font-weight: 300;

    // margin-right: 26px;
    &:hover {
      color: $main-yellow;
      background-color: #fff;
      cursor: pointer;
    }
  }
}

.ul-bulletins {
  margin-top: 4px;
  position: absolute;
  background: #ca9e67;
  border-radius: 5px;
  width: 304px;
  left: 0;
  max-height: 280px;
  padding: 10px 10px 10px 0;
  justify-content: flex-start;
  box-sizing: border-box;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;

  li {
    padding-left: 10px;
    overflow: hidden;
    display: flex;
    color: white;
    line-height: 24px;
    font-family: 'Museo Sans Cyrl';
    font-size: 14px;
    line-height: 28px;
    font-weight: 300;

    &:hover {
      color: $main-yellow;
      background-color: #fff;
      cursor: pointer;
    }
  }
}

.title {
  border-bottom: 1px solid #ca9e67;
  font-family: 'Museo Sans Cyrl';
  font-weight: 3 00;
  font-size: 26px;
  line-height: 31px;
  text-transform: uppercase;
  padding-bottom: 20px;
}

.bulletin {
  margin-right: 30px;
  margin-bottom: 44px;
  position: relative;

  &__text {
    font-family: 'Museo Sans Cyrl';
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 10px;
  }

  &__input {
    font-family: 'Museo Sans Cyrl';
    border: none;
    font-weight: 300;
    font-size: 16px;
    margin-right: 8px;
    width: 100%;
    color: #16355b;
    cursor: pointer;

    &-label {
      width: 304px;
      padding: 8px 15px;
      border: 0.5px solid #676d73;
      display: inline-flex;

      align-items: center;
      justify-content: center;
      border-radius: 6px;

      svg {
        fill: $main-yellow;
        width: 10px;
      }

      .svg-icon--calendar {
        width: 24px;
      }

      select {
        appearance: none;
        width: 116px;
        position: relative;
        background: url ('../assets/icons/calendar.svg');
        background-position: right 5px top 50%;

        ::placeholder {
          font-family: 'Museo Sans Cyrl';
          font-weight: 400;
          font-size: 16px;
        }

        option {
          font-family: 'Museo Sans Cyrl';
          font-weight: 400;
          font-size: 16px;
        }
      }
    }
  }

  &-name {
    font-family: 'Museo Sans Cyrl';
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 40px;
    text-transform: uppercase;
  }

  .region {
    width: 240px;
  }

  .date {
    width: 127px;
  }

  .ul-date {
    font-family: 'Museo Sans Cyrl';
    font-style: normal;
    font-weight: 300;
    position: absolute;
    font-size: 14px;
    width: 106px;
    background: #ca9e67;
    // border: 0.5px solid #ca9e67;
    box-sizing: border-box;
    border-radius: 6px;
    color: white;
    padding: 0px 5px 18px 0;
    max-height: 168px;
    overflow-y: scroll;
    margin-top: 4px;
    box-shadow: 10px 0px 0px #ca9e67;

    &::-webkit-scrollbar-track {
      margin-top: 15px;

      margin-bottom: 15px;
      background: rgba(221, 218, 218, 0.219);
      border-radius: 0px;
      border: none;
      margin-right: 10px !important;
      padding-right: 10px;

      &:hover {
        border: none;
        margin-right: 10px;
        // background: rgb(236, 236, 236);
      }
    }

    &::-webkit-scrollbar-thumb {
      margin-right: 10px;
      background: rgb(255, 255, 255);
      border-radius: 0px;
      border: none;

      // border: 2px solid #c5ab8b;
      &:hover {
        border: none;
        // border: 2px solid #e2c4a0;
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    li {
      padding: 10px 0px 7px 15px;

      &:hover {
        color: $main-yellow;
        background: white;
      }
    }
  }
}

.section {
  height: auto;

  padding: 30px;
  margin-bottom: 25px;

  &__date {
    font-family: 'Museo Sans Cyrl';
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  }

  &__prediction {
    margin-top: 30px;
    font-family: 'Museo Sans Cyrl';
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    background: rgba(140, 84, 255, 0.15);
    width: min-content;
    border-radius: 6px;
    white-space: nowrap;
    padding: 6px 10px;

    &-active {
      text-decoration: line-through;
    }

    &:hover {
      cursor: pointer;
    }
  }

  table {
    font-family: 'Museo Sans Cyrl';
    font-size: 12px;
    display: table;
    text-transform: capitalize;
    text-align: right;

    width: 100%;

    thead {
      tr {
        border: none;

        th {
          border-collapse: collapse;
          padding-bottom: 15px;
          padding-left: 16px;
          padding-right: 16px;

          &:nth-child(1) {
            text-align: left;
          }
        }
      }

      color: rgba(103, 109, 115, 0.5);
      border-color: transparent;
      box-shadow: none;
      font-weight: 600;
    }

    tbody {
      font-weight: 400;
      color: #676d73;
      border-color: transparent;
      box-shadow: none !important;

      tr {
        &:nth-child(odd) {
          background: #edf2f7;
        }

        box-shadow: none;
        border-spacing: 16px;
        border: none;
        border-color: transparent;

        td {
          &:nth-child(1) {
            text-align: left;
          }

          padding-left: 16px;
          border-collapse: collapse;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-right: 16px;
          border-color: transparent;
        }
      }
    }
  }
}

.buttons {
  margin-top: 30px;
  margin-bottom: 28px;
  display: flex;
  flex-wrap: wrap;
}

.button {
  &-parameter {
    background: rgba(140, 84, 255, 0.15);
    transition: 0.3s ease;
    border-radius: 20px;
    padding: 6px 10px;
    border: none;
    color: rgba(140, 84, 255, 0.7);
    font-family: 'Museo Sans Cyrl';
    font-weight: 300;
    font-size: 13px;
    display: flex;
    align-items: center;
    margin-right: 8px;
    margin-bottom: 8px;

    svg {
      margin-left: 10px;
      stroke: rgba(140, 84, 255, 0.7);
      fill: transparent;
      transition: 0.5s ease;
    }

    &:hover {
      // background: rgba(140, 84, 255, 0.45);
      color: rgba(140, 84, 255, 1);

      svg {
        stroke: rgba(119, 51, 255, 0.7);
        fill: rgba(176, 138, 252, 0.7);
      }
    }
  }
}

.choice-indicator {
  font-family: 'Museo Sans Cyrl';
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;

  .choice {
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    color: $main-blue;

    :hover {
      cursor: pointer;
    }

    .graphics {
      margin-right: 55px;
    }

    .table {
      text-transform: capitalize;
    }
  }

  .download {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px;
    background: $main-blue;
    color: white;
    font-weight: 500;
    font-size: 16px;
    border: none;
    border-radius: 10px;
    transition: 0.3 ease;

    svg {
      margin-left: 10px;
      stroke: white;
      transition: 0.5s ease;
      fill: transparent;
    }

    &:hover {
      .expand {
        display: block !important;

        &__item {
          &:hover {
            text-decoration: underline;
          }

          &:first-of-type {
            margin-bottom: 15px;
          }

          .svg-icon {
            width: 45px;
            height: 27px;

            &--pdf-file {
              fill: red !important;
              stroke: none;
            }

            &--excel-file {
              fill: $main-green !important;
              stroke: none;
            }
          }
        }
      }

      background: $main-grown;
    }
  }
}

.choice-active {
  color: $main-yellow;
}

.cooki {
  font-family: 'Museo Sans Cyrl';
  font-weight: 300;
  font-size: 14px;
  text-decoration: none;
  color: #676d73;
  border-right: 1px solid #676d73;
  padding-right: 8px;
  margin-right: 8px;
  margin-bottom: 15px;
}

.td {
  margin-top: 20px;
  text-decoration: none !important;

  :hover {
    color: black;
  }

  a {
    text-decoration: none !important;
  }
}

.expand {
  display: none;
  bottom: -100px;
  left: 0;
  position: absolute;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 7px 40px rgb(0 0 0 / 7%);
  border-radius: 6px;
  color: $main-blue;
  padding: 15px;

  &__item {
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;
    align-items: center;
    text-transform: initial;

    &:hover {
      text-decoration: underline;
    }

    &:first-of-type {
      margin-bottom: 15px;
    }

    .svg-icon {
      width: 45px;
      height: 27px;

      &--pdf-file {
        fill: red !important;
        stroke: none;
      }

      &--excel-file {
        fill: $main-green !important;
        stroke: none;
      }
    }
  }
}

.quartet-box {
  margin-bottom: 28px;
}

section.charts {
  box-shadow: none;

  .select-chart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    .select-item {
      box-shadow: 0px 7px 10px rgb(0 0 0 / 7%);
      padding: 10px 55px;
      border-radius: 6px;
      cursor: pointer;

      .svg-icon {
        width: 40px;
        height: 40px;

        svg {
          transition: all 0.3s ease;
        }
      }
    }

    &.active {
      .select-item svg {
        fill: #ca9e67;
      }
    }
  }
}

.button-using {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 40px;
  background: $main-yellow;
  border-radius: 6px;
  border: none;
  text-transform: uppercase;
  font-size: 18px;
  color: #fff;
  margin-right: 24px;

  &:hover {
    background: darken($main-yellow, 5);
  }
}

.button-create-new-report__auth {
  padding: 0 40px;
  font-size: 18px;
  line-height: 1px !important;
  height: 50px;
}

.modalcontent {
  align-content: center;
  width: 100%;
  margin: auto;

  &__h2 {
    text-align: center;
    font-size: 26px;
    margin-bottom: 45px;
  }

  &__text-info {
    color: #535353;
    margin-bottom: 5px;
    font-size: 16px;
  }

  &__input {
    width: 268px;
    margin-bottom: 20px;
    background: #f6f6f6;
    border: 0;
    border-radius: 6px;
    padding: 5px 6px;
    color: #535353;
    height: 46px;
  }

  &__margin {
    width: min-content;
    margin: auto;
  }
}

.modal {
  position: fixed;
  /* Оставаться на месте */
  z-index: 1;
  /* Сидеть на вершине */
  left: 0;
  top: 0;
  width: 100%;
  /* Полная ширина */
  height: 100%;
  /* Полная высота */
  overflow: auto;
  /* Включите прокрутку, если это необходимо */

  // background-color: rgba(0, 0, 0, 0.374); /* Черный с непрозрачностью */
}

/* Модальное содержание/коробка */
.modal-content {
  border-radius: 20px;
  background-color: #fefefe;
  margin: 10% auto;
  /* 15% сверху и по центру */
  padding: 20px;
  border: 1px solid #888;
  width: 480px;
  padding-bottom: 45px;
}

/* Кнопка закрытия */
.close {
  position: relative;
  left: -10px;
  top: 0px;
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;

  &__successful {
    top: 3px;
  }
}

.text--primary {
  padding-top: 10px;
}

.text-bold {
  margin-right: 10px;
}

.button-create-new-report__auth {
  padding: 0 40px;
  font-size: 18px;
  line-height: 1px !important;
  height: 50px;
}

.modalcontent__margin__auth {
  margin-top: 60px !important;
  width: min-content;
  margin: auto;
}

.modalcontent__margin__auth .buttons {
  flex-wrap: nowrap;
}

.modalcontent__h2 {
  margin-top: 40px;

  &-delete {
    padding: 0px 20px !important;
  }
}

.modal-content__auth {
  width: 762px;
  height: 317px;
  border-radius: 20px;
}

.statistic-list {
  max-height: 200px;
  overflow-y: scroll;
  padding: 0 5px 5px 10px;

  & li {
    color: rgb(22, 53, 91);
    padding: 5px 0;

    &:hover {
      color: #ca9e67;
      cursor: pointer;
    }
  }
}

.label-ul {
  margin-bottom: 0;
}
</style>
