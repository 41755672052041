
  import { defineComponent, reactive, ref, onMounted } from 'vue'
  import MainTopLine from '@/components/MainTopLine.vue'
  import MainFooter from '@/components/MainFooter.vue'
  import LineChart from '@/components/LineChart.vue'
  import { api } from '@/api'
  import AdaptiveMainTopLine from '@/components/AdaptiveMainTopLine.vue'

  export default defineComponent({
    components: {
      AdaptiveMainTopLine,
      MainTopLine,
      MainFooter,
      LineChart
    },
    setup() {
      let dataValues = ref([])
      let graphics = reactive({
        labels: [],
        date: '',
        values: [],
        color: [],
        name: '',
        chart: 'bar'
      })
      let data = reactive({
        search: '',
        buletin: ''
      })
      let setBulletin = reactive({
        name: '',
        attributes: [],
        locations: []
      })
      let getBulletin = reactive({
        value: [],
        unit: '',
        labels: []
      })
      let unit = ref('')
      let searchBuletins = ref([])
      let bulletinAttributes = ref([])
      let bulletinLocations = ref([])
      let inputPlaceBulletinName = ref('')
      let changeactive = ref(true)
      let bulletinValues = ref([])
      let bulletinLocationsMap = ref([])
      let bulletinAttributesMap = ref([])
      let setbulletinLocationsMap = ref([])
      let setbulletinAttributesMap = ref([])
      let activeAttribute = ref(false)
      let activeLocation = ref(false)
      let timer
      let bulletinNameButton = ref([])
      let period = ref({})
      function endAndStartTimer() {
        window.clearTimeout(timer)
        timer = window.setTimeout(function () {
          searchBuletin()
        }, 300)
      }
      async function searchBuletin() {
        try {
          const response = await api.post('/v1/chart/search/bulletin', {
            search: data.search
          })
          changeactive.value = true
          searchBuletins.value = response.data.data.listBulletins
        } catch (error) {
          searchBuletins.value = null
        }
      }
      async function clickbullById(bulid, name) {
        deleteBulletinNoValues()
        dataValues.value = []
        graphics.labels = []
        inputPlaceBulletinName.value = name

        try {
          const response = await api.post('/v1/chart/search/attr', {
            bulletins: [
              {
                id: bulid
              }
            ]
          })

          bulletinAttributes.value = response.data.data.listAttribute
          bulletinAttributesMap.value = bulletinAttributes.value.map(
            function (obj) {
              return {
                name: obj.name,
                id: obj.report_attribute_id,
                values: obj.attribute_values
              }
            }
          )

          changeactive.value = false
          bulletinNameButton.value.push({ name: name, id: bulid })
        } catch (error) {
          console.error(error)
        }
        try {
          const response = await api.post('/v1/chart/search/location', {
            bulletins: [
              {
                id: bulid
              }
            ]
          })
          bulletinLocations.value = response.data.data.listLocations

          bulletinLocationsMap.value = bulletinLocations.value.map(
            function (obj) {
              return {
                name: obj.name + [],
                id: obj.location_id,
                values: obj.location_values
              }
            }
          )
          bulletinNameButton.value[
            bulletinNameButton.value.length - 1
          ].fromDate = bulletinLocations.value[0]?.location_values[0]?.from_date
        } catch (error) {
          console.error(error)
        }
        getBulletinValues()
      }

      async function getBulletinValues() {
        dataValues.value = []
        let bulletinLocationsId = setbulletinLocationsMap.value.map(
          (item) => item.id
        )
        let bulletinAttributesId = setbulletinAttributesMap.value.map(
          (item) => item.id
        )
        try {
          const response = await api.post('/v1/chart/values', {
            attrs: bulletinAttributesId,
            locations: bulletinLocationsId
          })
          if (response.data.data[0].unit == null) {
            unit.value = 'не выбрано'
          } else {
            unit.value = response.data.data[0].unit
          }

          await setUnit()

          for (const item of setbulletinAttributesMap.value) {
            let row
            let findRow = []
            let backgroundColor = []
            for (const bl of setbulletinLocationsMap.value) {
              row = []

              for (const ba of bl.values) {
                if (ba.report_attribute_id == item.id) {
                  row.push(ba.value)
                }
              }

              // if (row.length == 1) {
              findRow.push(row[0])
              // } else {
              // findRow.push('-')
              // }
              backgroundColor.push(dynamicColors())
            }
            dataValues.value.push({
              data: findRow,
              label: item.name,
              backgroundColor: backgroundColor,
              borderColor: backgroundColor
            })
            findRow = []

            graphics.labels = setbulletinLocationsMap.value.map(
              (item) => item.name
            )
          }
        } catch (error) {
          console.error(error)
        }
      }

      function setChart(typeChart) {
        graphics.chart = typeChart
      }

      function dynamicColors() {
        var r = Math.floor(Math.random() * 255)
        var g = Math.floor(Math.random() * 255)
        var b = Math.floor(Math.random() * 255)
        return 'rgba(' + r + ', ' + g + ', ' + b + ', 0.5)'
      }
      function setbulletinAttributes(id, name) {
        setbulletinAttributesMap.value.push(
          bulletinAttributesMap.value.find((item) => item.id == id)
        )
        bulletinAttributesMap.value = bulletinAttributesMap.value.filter(
          (a) => a.id !== id
        )
        getBulletinValues()
        activeAttribute.value = false
        activeLocation.value = false
      }
      async function setbulletinLocation(id, name) {
        setbulletinLocationsMap.value.push(
          bulletinLocationsMap.value.find((item) => item.id == id)
        )
        bulletinLocationsMap.value = bulletinLocationsMap.value.filter(
          (a) => a.id !== id
        )

        await getBulletinValues()
        activeLocation.value = false
        activeAttribute.value = false
      }
      let i = 0
      async function setUnit() {
        try {
          if (i < 1) {
            bulletinAttributesMap.value = bulletinAttributesMap.value.map(
              function (obj) {
                return {
                  name: obj.name + ` [${unit.value}]`,
                  id: obj.id,
                  values: obj.values
                }
              }
            )
          } else if (i <= 1) {
            setbulletinAttributesMap.value[0].name =
              setbulletinAttributesMap.value[0].name + ` [${unit.value}]`
          }
          i++
        } catch (error) {
          console.error(error)
        }
      }
      function deletebulletinAttributesMap(id) {
        bulletinAttributesMap.value.push(
          setbulletinAttributesMap.value.find((item) => item.id == id)
        )
        setbulletinAttributesMap.value = setbulletinAttributesMap.value.filter(
          (a) => a.id !== id
        )
        getBulletinValues()
      }
      function deletebulletinLocationsMap(id) {
        bulletinLocationsMap.value.push(
          setbulletinLocationsMap.value.find((item) => item.id == id)
        )
        setbulletinLocationsMap.value = setbulletinLocationsMap.value.filter(
          (a) => a.id !== id
        )
        getBulletinValues()
      }
      onMounted(() => {
        let chartButtons = document.querySelectorAll('.tabs__item')
        chartButtons.forEach((button) => {
          button.addEventListener('click', function (e) {
            chartButtons.forEach(function (item) {
              item.classList.remove('tabs__item--active')
            })

            button.classList.add('tabs__item--active')
          })
        })
        let input = document.querySelectorAll('.statistic-list')[1]

        input.addEventListener('mouseleave', (event) => {
          window.onclick = (e) => {
            if (
              document.querySelectorAll('.statistic-list')[1] != e.target &&
              !(document.querySelector('.input') == e.target)
            ) {
              changeactive.value = false
            }
          }
        })
      })
      function deleteBulletin(id) {
        dataValues.value = []
        let graphics = {
          labels: [],
          date: '',
          values: [],
          color: [],
          name: '',
          chart: 'bar'
        }
        let data = {
          search: '',
          buletin: ''
        }
        // let setBulletin = {
        //     name: '',
        //     attributes: [],
        //     locations: []
        // }
        // let getBulletin = {
        //     value: [],
        //     unit: '',
        //     labels: []
        // }
        searchBuletins.value = []
        // bulletinAttributes.value = []
        // bulletinLocations.value = []
        inputPlaceBulletinName.value = ''
        changeactive.value = true
        bulletinValues.value = []
        bulletinLocationsMap.value = []
        bulletinAttributesMap.value = []
        // setbulletinLocationsMap.value = []
        // setbulletinAttributesMap.value = []
        activeAttribute.value = false
        activeLocation.value = false
        timer
        // bulletinNameButton.value = []
        // let pos = bulletinNameButton.value.indexOf(id)
        // bulletinNameButton.value.splice(pos, 1)
        bulletinNameButton.value = bulletinNameButton.value.filter(
          (a) => a.id !== id
        )
        let setBulletin = bulletinNameButton.value
        for (let item of setBulletin) {
          clickbullById(item.id, item.name)
        }
        bulletinNameButton.value = []
      }
      function deleteBulletinNoValues() {
        let moreSelect
        if (bulletinNameButton.value.length >= 1) {
          moreSelect = true
        } else {
          moreSelect = false
        }
        if (
          setbulletinAttributesMap.value.length < 1 ||
          setbulletinLocationsMap.value.length < 1
        ) {
          if (moreSelect) {
            bulletinNameButton.value.length = 0
            setbulletinAttributesMap.value.length = 0
            setbulletinLocationsMap.value.length = 0
          }
        }
      }
      return {
        setbulletinLocation,
        setbulletinAttributes,
        searchBuletin,
        searchBuletins,
        data,
        endAndStartTimer,
        clickbullById,
        bulletinAttributes,
        bulletinLocations,
        graphics,
        inputPlaceBulletinName,
        changeactive,
        getBulletinValues,
        setBulletin,
        bulletinValues,
        getBulletin,
        setChart,
        dataValues,
        setbulletinAttributesMap,
        setbulletinLocationsMap,
        bulletinAttributesMap,
        bulletinLocationsMap,
        deletebulletinAttributesMap,
        deletebulletinLocationsMap,
        deleteBulletin,
        activeAttribute,
        activeLocation,
        bulletinNameButton
      }
    }
  })
