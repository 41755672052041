
  import { defineComponent, reactive, ref, unref, watch, onMounted } from 'vue'
  import MainTopLine from '@/components/MainTopLine.vue'
  import MainFooter from '@/components/MainFooter.vue'
  import LineChart from '@/components/LineChart.vue'
  import { api } from '@/api'
  import AdaptiveMainTopLine from '@/components/AdaptiveMainTopLine.vue'
  import { createNamespacedHelpers } from 'node_modules/vuex/types'
  import { useRoute, useRouter } from 'vue-router'

  export default defineComponent({
    components: {
      AdaptiveMainTopLine,
      MainTopLine,
      MainFooter,
      LineChart
    },
    setup() {
      let graphics = reactive({
        labels: [],
        date: '',
        values: [],
        color: [],
        name: '',
        chart: 'bar'
      })
      let data = reactive({
        search: '',
        bulletin: ''
      })
      let currentBulletinId = ref()
      let bulletins = ref([])
      let bulletinPeriod = ref([])
      let bulletinLocations = ref([])
      let bulletinLocationsMap = ref([])
      let bulletinAttributes = ref([])
      let bulletinAttributesMap = ref([])
      let bulletinPeriodSelect = ref()
      let bulletinDate = ref('')
      let bulletinValues = ref([])
      let bulletinArr = ref([])
      let bullettinTable = ref([])
      let changeactive = ref(true)
      let inputPlaceBulletinName = ref('')
      let dataValues = ref([])
      let bulletinNameButton = ref([])
      let frequentlyReports = ref('')
      const route = useRoute()
      let active = ref(false)
      let checkedBulletin = ref(false)
      let timer
      function searchBulletinTimer() {
        window.clearTimeout(timer)
        timer = window.setTimeout(function () {
          searchBulletin()
          deleteBulletin()
        }, 300)
      }
      async function searchBulletin() {
        try {
          const response = await api.post('/v1/chart/search/bulletin', {
            search: data.search
          })
          bulletins.value = response.data.data.listBulletins
          changeactive.value = true
        } catch (error) {
          bulletins.value = null
        }
      }
      async function clickBulletinById(bulletinId, bulletinName) {
        checkedBulletin.value = true
        try {
          currentBulletinId.value = bulletinId
          const response = await api.post(
            '/v1/chart/search/period',

            {
              bulletin_id: bulletinId
            }
          )
          bulletinPeriod.value = response.data
          bulletinDate.value = '0'
          changeactive.value = !changeactive.value
          inputPlaceBulletinName.value = bulletins.value.find(
            (item) => item.id == currentBulletinId.value
          ).name

          bulletinNameButton.value.push({ name: bulletinName })

          showValuesByBulletin()
        } catch (error) {
          console.error(error)
        }
      }

      async function showValuesByBulletin() {
        try {
          const response = await api.post('/v1/chart/search/location', {
            bulletins: [
              {
                id: currentBulletinId.value
              }
            ]
          })

          bulletinLocations.value = response.data.data.listLocations

          bulletinLocationsMap.value = bulletinLocations.value.map(
            function (obj) {
              return {
                name: obj.name,
                id: obj.location_id,
                values: obj.location_values
              }
            }
          )
        } catch (error) {
          console.error(error)
        }

        try {
          const response = await api.post('/v1/chart/search/attr', {
            bulletins: [
              {
                id: currentBulletinId.value
              }
            ]
          })
          bulletinAttributes.value = response.data.data.listAttribute

          bulletinAttributesMap.value = bulletinAttributes.value.map(
            function (obj) {
              return {
                name: obj.name,
                id: obj.report_attribute_id,
                values: obj.attribute_values
              }
            }
          )
          getValues()
        } catch (error) {
          console.error(error)
        }
      }

      async function getValues() {
        dataValues.value = []
        let bulletinLocationsId = bulletinLocationsMap.value.map(
          (item) => item.id
        )
        let bulletinAttributesId = bulletinAttributesMap.value.map(
          (item) => item.id
        )

        try {
          // let [startDate, endDate] = bulletinDate.value.split('=')

          const response = await api.post('/v1/chart/values', {
            attrs: bulletinAttributesId,
            locations: bulletinLocationsId,
            from_date: bulletinPeriod.value[bulletinDate.value].from_date,
            to_date: bulletinPeriod.value[bulletinDate.value].to_date
          })

          bulletinValues.value = response.data.data.slice()

          const vals = []
          let bv = []

          for (const bl of bulletinLocationsMap.value) {
            for (const ba of bulletinAttributesMap.value) {
              let pushed = false
              for (const val of bulletinValues.value) {
                if (
                  val.report_attribute_id == ba.id &&
                  val.location_id == bl.id
                ) {
                  vals.push(val)
                  bv.push(val.value)

                  pushed = true
                }
              }

              if (!pushed) {
                vals.push({ value: '-' })
              }
            }
          }

          for (const item of bulletinLocationsMap.value) {
            let row
            let findRow = []
            let backgroundColor = []
            for (const bl of bulletinAttributesMap.value) {
              row = []

              for (const ba of bl.values) {
                if (ba.location_id == item.id) {
                  row.push(ba.value)
                }
              }

              if (row.length == 1) {
                findRow.push(row[0])
              } else {
                findRow.push('-')
              }
              backgroundColor.push(dynamicColors())
            }

            dataValues.value.push({
              data: findRow,
              label: item.name,
              backgroundColor: backgroundColor,
              borderColor: backgroundColor
            })

            findRow = []

            graphics.labels = bulletinAttributesMap.value.map(
              (item) => item.name
            )
          }

          bullettinTable.value = []
          for (let i = 0; i < bulletinLocationsMap.value.length; i++) {
            bullettinTable.value[i] = {
              name: bulletinLocationsMap.value[i].name,
              values: vals.slice(0, bulletinAttributesMap.value.length)
            }
            vals.splice(0, bulletinAttributesMap.value.length)
          }

          editGraphics()
        } catch (error) {
          console.error(error)
        }
      }
      function editGraphics() {
        graphics.date =
          bulletinLocations.value[0].location_values[0].from_date +
          '-' +
          bulletinLocations.value[0].location_values[0].to_date

        graphics.name = bulletins.value.find(
          (item) => item.id == currentBulletinId.value
        ).name
      }
      let r = 200
      let i = 0
      function dynamicColors() {
        if (graphics.chart == 'pie') {
          r = r - 10
          var g = r - 10
          var b = 255
        } else {
          r = Math.floor(Math.random() * 255)
          var g = Math.floor(Math.random() * 255)
          var b = Math.floor(Math.random() * 255)
        }
        return 'rgba(' + r + ', ' + g + ', ' + b + ', 0.5)'
      }

      function setChart(typeChart) {
        graphics.chart = typeChart
        active.value = true
        getValues()
      }

      function setCurrentAttribute(id) {
        bulletinAttributesMap.value = bulletinAttributesMap.value.filter(
          (a) => a !== id
        )
      }
      function deleteAttributes(id) {
        bulletinAttributesMap.value = bulletinAttributesMap.value.filter(
          (a) => a.id !== id
        )

        getValues()
      }
      function deletelocations(id) {
        bulletinLocationsMap.value = bulletinLocationsMap.value.filter(
          (a) => a.id !== id
        )

        getValues()
      }
      function deleteBulletin() {
        bulletinLocationsMap.value = []
        bulletinAttributesMap.value = []
        bulletinNameButton.value = []
        bulletinPeriod.value = []
        bulletinDate.value = ''
        inputPlaceBulletinName.value = ''
        bullettinTable.value = []
        getValues()
      }
      if (route.params.frequentlyReportTrue) {
        frequentlyReports.value = route.params.bulletinId.toString()
        getFrequentlyReports()
      }
      async function getFrequentlyReports() {
        try {
          const response = await api.post(
            '/v1/chart/search/period',

            {
              bulletin_id: frequentlyReports
            }
          )

          bulletinDate.value = '0'
          changeactive.value = !changeactive.value
          inputPlaceBulletinName.value = route.params.bulletinName.toString()
          bulletinNameButton.value.push({
            name: route.params.bulletinName.toString()
          })
          data.search = route.params.bulletinName.toString()
          bulletinPeriod.value = response.data
          currentBulletinId.value = frequentlyReports.value
          showValuesByBulletin()
        } catch (error) {
          console.error(error)
        }
      }
      onMounted(() => {
        let chartButtons = document.querySelectorAll('.tabs__item')

        chartButtons.forEach((button) => {
          button.addEventListener('click', function (e) {
            chartButtons.forEach(function (item) {
              item.classList.remove('tabs__item--active')
            })

            button.classList.add('tabs__item--active')
          })
        })
      })
      return {
        deletelocations,
        deleteAttributes,
        setCurrentAttribute,
        changeactive,
        searchBulletin,
        bulletins,
        data,
        searchBulletinTimer,
        clickBulletinById,
        bulletinPeriod,
        bulletinLocations,
        bulletinAttributes,
        showValuesByBulletin,
        bulletinPeriodSelect,
        bulletinDate,
        bulletinValues,
        bulletinArr,
        dataValues,
        bullettinTable,
        graphics,
        setChart,
        bulletinLocationsMap,
        bulletinAttributesMap,
        inputPlaceBulletinName,
        bulletinNameButton,
        deleteBulletin,
        active
      }
    }
  })
