
import { defineComponent, ref, watch, computed, onMounted } from 'vue'
import { api } from '@/api'
import { useI18n } from 'vue-i18n'
import store from '@/store'

import BreadCrumbs from '@/components/ui/BreadCrumbs.vue'
import SectionTitle from '@/components/ui/SectionTitle.vue'
import SearcBlock from '@/components/ui/SearcBlock.vue'

export default defineComponent({
  components: {
    BreadCrumbs,
    SectionTitle,
    SearcBlock,
  },
  setup() {
    const { locale } = useI18n()
    const curPage = ref(1)
    const searchText = ref('')
    const totalCount = ref(0)
    const list = ref([])

    watch(() => [searchText.value], () => {
      curPage.value = 1
      getLicensies()
    })

    watch(() => [curPage.value], () => {
      getLicensies()
    })

    const getList = computed(() => {
      return list.value
    })

    function getCurPageCount() {
      if (getList.value.length === 0) return '0'
      return (curPage.value - 1) * 10 + 1 + '-' + Math.min(curPage.value * 10, totalCount.value)
    }

    const lastPage = computed(() => {
      return Math.ceil(totalCount.value / 10)
    })

    const tableHeads = [
      {
        id: 0,
        name: 'order',
        style: {
          maxWidth: '40px',
        }
      },
      // {
      //   id: 1,
      //   name: 'licensor',
      //   style: {
      //     maxWidth: '173px',
      //   }
      // },
      // {
      //   id: 2,
      //   name: 'number',
      //   style: {
      //     maxWidth: '131px',
      //   }
      // },
      {
        id: 3,
        name: 'name',
        style: {
          maxWidth: '240px',
        }
      },
      {
        id: 4,
        name: 'iin',
        style: {
          maxWidth: '149px',
        }
      },
      {
        id: 5,
        name: 'issue_at',
        style: {
          maxWidth: '252px',
        }
      },
      {
        id: 6,
        name: 'address',
        style: {
          maxWidth: '350px',
        }
      },
      {
        id: 7,
        name: 'subspecies',
        style: {
          maxWidth: '167px',
        }
      },
    ]

    const getFieldData = computed(() => (item: any, field: string) => {
      if (field === 'licensor') return item['licensor']['name_' + locale.value] || item['licensor']['name_ru']
      return item[field]
    })

    onMounted(() => {
      getLicensies()
    })

    function clickPage(num: number) {
      curPage.value = curPage.value + num
    }

    async function getLicensies() {
      try {
        store.commit('setLoading', true)
        const res = await api.get('/v1/licenses', {
          params: {
            'filter[search]': searchText.value,
            page: curPage.value,
          }
        })

        list.value = res.data.data
        totalCount.value = res.data.meta.total
      } catch (e) {
        console.log(e)
      } finally {
        store.commit('setLoading', false)
      }
    }

    return {
      getCurPageCount,
      getList,
      tableHeads,
      searchText,
      curPage,
      list,
      totalCount,
      getFieldData,
      lastPage,
      clickPage
    }
  }
})
