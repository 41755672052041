
  import MainLogo from '@/components/main/MainLogo.vue'
  import SwitchLanguage from '@/components/main/SwitchLanguage.vue'
  import MenuDesktopList from '@/components/main/MenuDesktopList.vue'
  import ProfileMenu from '@/components/main/ProfileMenu.vue'
  import RegionSelect from '@/components/main/RegionSelect.vue'
  import SearchBlock from '@/components/main/SearchBlock.vue'

  export default {
    components: {
      MainLogo,
      SwitchLanguage,
      MenuDesktopList,
      ProfileMenu,
      RegionSelect,
      SearchBlock
    }
  }
