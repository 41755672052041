
import { ref } from 'vue'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'

import 'swiper/swiper.min.css'

SwiperCore.use([Navigation])

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    list: Array,
    default() {
      return []
    }
  },
  setup() {
    const prev = ref(null)
    const next = ref(null)
    
    return {
      prev,
      next,
    }
  }
}
