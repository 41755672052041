export const en = {
  trour: 'October 29, 2023 – Day of national mourning in the Republic of Kazakhstan',
  pages: {
    tourBase: {
      title: 'The base of tour operators',
      table: {
        order: '№',
        licensor: 'Licensor',
        number: 'License number',
        name: 'Name',
        iin: 'IIN/BIN',
        issue_at: 'Date of issue',
        address: 'Address',
        subspecies: 'Subspecies',
      }
    },
    home: {
      banner: {
        title: 'Tourstat.kz',
        subtitle:
          'Information system for collecting and processing data in the field of tourism'
      },
      top10MonthCountry: 'TOP 10 countries this month',
      title1: 'Portrait of a tourist this month',
      title2: 'Tourism statistics',
      title3: 'Digests',
      title4: 'CREATING A REPORT',
      title5: 'About the system',
      title6: 'News',
      title7: 'FAQ',
      createReportTitle:
        'In this block, you can generate a report with statistical data in a PDF or Excel format that is convenient for you.',
      aboutSystem:
        'The information system Turstat carries out statistical accounting of tourist activity in the republic and provides analytics of tourist flow in different sections. The system was created as part of the implementation of the State Program for the Development of the tourism industry for 2019-2025 by the Ministry of Culture and Sports of the Republic of Kazakhstan.',
      systemInfo: {
        0: 'Maintaining statistical records of \n tourist activities',
        1: 'Number of arrivals',
        2: 'Analytics of \n tourist flow',
        3: 'Availability of \n statistical data',
        4: 'Duration of stay \n (difficulties)',
        5: 'The amount of expenses of \n tourists in the calendar \n year'
      }
    },
    statistics: {
      tabsMain: {
        title1: 'Tourist services',
        title2: 'Travel expenses',
        title3: 'Visiting visitors',
        title4: 'Routes and attractions',
      },
      title1: 'Tourism statistics',
      title2: 'FREQUENTLY VIEWED METRICS',
    },
    about: {
      title1: 'About the system',
      title2: 'STATISTICS ON TOURISM IN KAZAKHSTAN: WHAT IS IT FOR?',
      text1: 'The information system Turstat carries out statistical accounting of tourist activity in the republic and provides analytics of tourist flow in different sections.',
      text2: 'The system was created as part of the implementation of the State Program for the development of the tourism industry for 2019-2025 by the Ministry of Culture and Sports of the Republic of Kazakhstan.',
      list: {
        0: 'Maintaining statistical records of tourist activities',
        1: 'Availability of statistical data',
        2: 'Analytics of tourist flow',
        3: 'Number of arrivals',
        4: 'Duration of stay (similar)',
        5: 'The amount of tourists\' expenses in a calendar year'
      },
      text3: 'Tourism statistics in Kazakhstan is the basis for making the right strategic decisions both within a particular business and the industry as a whole. Based on this information, short-term and long-term development plans are built, new niches are selected and appear.',
      text4: 'Tourism statistics reflect what is really happening in the market. Comprehensive objective information allows industry organizations to analyze market trends, make forecasts and make decisions about investments, expanding the range of tourist products and services.',
      text5: 'The information on the portal will be useful to everyone connected with the tourism industry. Statistical data affect international and domestic tourism in Kazakhstan, the statistics of the Tourstat allows you to study the main indicators at the level of the country as a whole and individual regions.',
      text6: 'Tourism statistics by region gives an idea of the state of the tourist market for effective business. It will be useful to scientific and educational institutions as information about the main results and trends in the development of the tourism sector.All data presented on this website of tourism statistics can be used for commercial, scientific and private purposes with reference to the source.',
    },
    news: {
      title: 'Latest tourism news: Stay up to date with all the events',
      title1: 'News',
      description:
        'Keep up to date with all the latest developments in the tourism industry with our news section. We provide fresh and up-to-date news about the tourism industry, travel, excursions and places of interest.'
    },
    digest: {
      title: 'Analysis of international arrivals and departures',
      title1: 'Digest',
      description:
        'Find out the number of trips abroad, study the data on international tourists and their main purposes of travel.'
    },

    default: {
      title: 'Information system database',
      description:
        'Information system for the collection and processing of statistical data in the field of tourism.'
    },
    main: {
      title:
        'Welcome to Tourstat.kz - Your source of travel data and statistics',
      description:
        'Tourstat.kz is your reliable partner in the field of tourism! Find out the latest statistics and information about the tourism industry in Kazakhstan. Join us and expand your horizons with Tourstat.kz!'
    },
    touchBord: {
      title:
        'Discover the portrait of a foreign tourist using the eQonaq information systems',
      description:
        'Get up-to-date data from various places of accommodation (hotels, hostels, recreation centers) provides up-to-date statistics from more than 60% of all places of accommodation in Kazakhstan.'
    },
    statisticsThree: {
      title:
        'Research of tourist statistics: Accommodation locations, visitors and duration of stay',
      description:
        'Immerse yourself in the world of tourism statistics! Find out the number of placements, analyze the flow of visitors and study the duration of their stay.'
    },
    createReport: {
      title: 'Tourism statistics: Create a handy report in PDF or Excel format',
      description:
        'Explore the world of tourism statistics with our service. Here you can easily create a report with detailed statistics in PDF or Excel format.'
    },
  },
  commands: {
    enter: 'Login',
    download: 'Download'
  },
  labels: {
    more: 'More detailed',
    steps: 'STEP {0}',
    enterSearchData:
      'Enter in the field “Search for” data you would like to find',
    selectPeriod: 'Select a period',
    downloadReport: 'Download the report',
    fromTourstat: 'from Tourstart {0}',
    next: 'further',
    yetStat: 'More statistics',
    year: '{0} year',
    feedback: 'Feedback',
    feedbackText: 'If you find any error or problem, please let us know by writing to the email address:',
    noDataInDB: 'There is no data in the database',
    ageMore55: ' 55 and older',
    ageRest: ' The others',
    ageYears: ' years',
    searchTourControlers: 'Search for a tour operator',
    find: 'To find...',
    pageCounter: '{1} out of {1}',
    noData: 'No data',
  },
  placeholders: {
    enterName: 'Enter a name'
  },
  footer: {
    author: 'Copyright 2023 ⓒ Tourstat. All rights reserved.',
    sublinks: {
      1: 'Tourist services',
      2: 'Travel expenses',
      3: 'Placements',
      4: 'Routes and attractions'
    },
  },
  menu: {
    mainSystem: 'Main',
  },
  
  message: {
    greet: 'Hello.',
    title: 'Title',
    banner: 'The system is undergoing technical work',
    authorized:
      '"You are not logged in. You must log in to download the report."'
  },
  mainHeader: {
    buttons: {
      forBusiness: 'for Business',
      forTourist: 'for Tourist'
    },
    business: {
      link1: {
        title: 'Place your business on the National Tourism Portal',
        subtitle: 'Module «Commercial platform»',
        link: 'https://kazakhstan.travel/marketplace'
      },
      link2: {
        title: 'Tourism ecosystem',
        subtitle: 'Module «Tourism online»',
        link: 'https://www.tourismonline.kz/'
      },
      link3: {
        title: 'Become a guide or a tour guide',
        subtitle: 'Module «Guides»',
        link: 'https://guides.kazakhstan.travel/ru'
      },
      link4: {
        title: 'To see statistics in the field of tourism',
        subtitle: 'Module «Tourstat»',
        link: 'https://tourstat.kz/'
      },
      link5: {
        title: 'Become an investor in tourism projects',
        subtitle: 'Module «Investments»',
        link: 'https://invest.kazakhstan.travel/'
      },
      link6: {
        title: 'Send a notification of the arrival of a foreign guest',
        subtitle: 'Information system «eQonaq»',
        link: 'https://eqonaq.kz'
      },
      link7: {
        title: 'Apply for subsidies',
        subtitle: 'Module «Subsidizing»',
        link: 'https://subsidy.kazakhstan.travel/ru'
      },
      link8: {
        title: 'Become a member of mice events',
        subtitle: 'The «MICE» module',
        link: 'https://kazakhstan.travel/mice'
      }
    },
    tourist: {
      link1: {
        title: 'About Kazakhstan',
        subtitle: 'National Tourist Portal',
        link: 'https://kazakhstan.travel/'
      },
      link2: {
        title: 'Book the best excursions, hotels, cars, campsites',
        subtitle: 'The «Commercial Platform» module',
        link: 'https://kazakhstan.travel/marketplace'
      },
      link3: {
        title: 'Choose a guide or a tour guide',
        subtitle: 'Module «Guides»',
        link: 'https://guides.kazakhstan.travel/ru'
      },
      link4: {
        title: '750th anniversary of the Golden Horde',
        subtitle: 'Module «Golden Horde»',
        link: 'https://altynorda.kazakhstan.travel/'
      },
      link5: {
        title: 'landscapes of Kazakhstan in the best quality',
        subtitle: '«Photobank» module',
        link: 'https://kazakhstan.travel/photobank'
      },
      link6: {
        title: 'Send a notification of the arrival of a foreign guest',
        subtitle: 'Information system «eQonaq»',
        link: 'https://eqonaq.kz'
      }
    }
  },
  adaptiveTop: {
    mainSystem: 'Main',
    about: 'About system',
    createReport: 'Create report',
    digest: 'Digest',
    statistics: 'Tourism statistics',
    touchBord: 'Portrait of a tourist',
    region: 'Region',
    articles: 'News',
    faq: 'FAQ',
    exit: 'Exit',
    account: 'Personal account',
    adminPanel: 'Admin panel',
    import: 'Import',
    bulletin: 'Bulletin',
    tourBase: 'The base of tour operators',
  },
  mobileTop: {
    mainSystem: 'Main',
    about: 'About system',
    createReport: 'Create report',
    statistics: 'Statistics',
    region: 'Region',
    articles: 'Articles',
    faq: 'Faq',
    exit: 'Exit'
  },
  main: {
    mainAboutSystem:
      'The system was created as part of the implementation of the State Program for the Development of the Tourism Industry for 2019-2025 by the Ministry of Culture and Sports of the Republic of Kazakhstan.',
    welcome:
      '<b>Welcome</b><br> to the information system<br> Tourstat.kz databases!',
    information:
      'Information system for collection and processing of statistical data in the field of tourism.',
    enter: 'enter',
    frequently: 'FREQUENTLY VIEWED METRICS',
    aboutSystem:
      'The information system Turstat performs statistical accounting of tourist activity in the republic and provides analysis of the tourist flow in different sections. The system was created as part of the implementation of the State Program for the Development of the Tourism Industry for 2019-2025 by the Ministry of Culture and Sports of the Republic of Kazakhstan. The information system Turstat performs statistical accounting of tourist activity in the republic and provides analysis of the tourist flow in different sections. Tourism statistics cover all aspects of this activity, which allows you to get an objective picture of the market. The tourism industry in Kazakhstan is one of the priority sectors of the national economy. It can become a driver of growth for the development of the regions: to stimulate the activities of small and medium-sized businesses, create new jobs and increase budget revenues.',
    next: 'next',
    search: 'Site search',
    statistics: 'statistics',
    mainTask: 'Key objectives and indicators',
    bulletinstat: 'Number of placements',
    bulletinCount: 'Total for the Republic of Kazakhstan - 3488 units',
    tour: 'Maintaining statistical records of tourism activities',
    stat: 'Availability of statistics',
    analitics: 'Tourist flow analytics',
    count: 'Number of arrivals',
    date: 'Length of stay (tour days)',
    sum: 'The amount of tourist expenses in a calendar year',
    otherArticles: 'Other news',
    linkArticles: 'go to news section',
    address: 'Address',
    city: 'Astana c',
    place: 'Turan s. 1 , 14 f',
    phone: 'Phone',
    bulletins: 'Generating a report',
    dynamic: 'Statistical data in the form of charts',
    summary: 'Summary statistics as charts',
    selectOrInput: 'Enter or select the required indicator from the list',
    inputName: 'Enter',
    selectTypeDiagramm: 'Select the type of charts',
    selectAttribute: 'Select the parameter according to the indicator',
    selectCity: 'Specify city',
    noData: 'Select the required indicator for data output',
    fromDate: 'Select date from',
    toDate: 'Select date before',
    title: 'Select a report',
    statisticText:
      'Statistics is a branch of knowledge, a science that sets out general issues of collecting, measuring,monitoring, analyzing mass statistical (quantitative or qualitative) data and comparing them. The study of the quantitative side of mass social phenomena in numerical form. Tourism statistics are very important both for the state and for business. Tourism is a large sector of the economy that needs marketing and serious planning. Tourism can give a country a very high percentage of GDP. The state plans to increase the impact of the tourism industry on GDP to 15% in the coming years.',
    numberOfPlaces: 'Количество мест размещения',
    totalOfRepublicKazakhstan: 'Всего по Республике Казахстан',
    units: 'единиц',
    percent: 'процент',
    occupancyHotels: 'Заполняемость гостиниц',

    namebulletin: {
      name: '',
      text: 'Total for the Republic of Kazakhstan - <strong>{0}</strong> units'
    },
    taskName: 'STATISTICS ON TOURISM IN KAZAKHSTAN: WHAT IS IT FOR?',
    aboutSystemSystem: {
      p1: {
        b: 'Tourism statistics',
        p: ' in Kazakhstan is the basis for making the right strategic decisions both within a particular business and the industry as a whole. Based on this information, short-term and long-term development plans are built, new niches are selected and appear.'
      },
      p2: {
        b: 'Tourism statistics',
        p: ' reflect what is really happening in the market. Comprehensive objective information allows industry organizations to analyze market trends, make forecasts and make decisions about investments, expanding the range of tourist products and services.'
      },
      p3: {
        p1: 'The information on the portal will be useful to everyone connected with the tourism industry. Statistical data affect international and ',
        b: 'domestic tourism in Kazakhstan',
        p2: ', the statistics of the Tourstat allows you to study the main indicators at the level of the country as a whole and individual regions.'
      },
      p4: {
        b1: 'Tourism statistics by region',
        p1: ' gives an idea of the state of the tourist market for effective business. It will be useful to scientific and educational institutions as information about the main results and trends in the development of the tourism sector. All data presented ',
        b2: 'on this website',
        p2: ' of tourism statistics can be used for commercial, scientific and private purposes with reference to the source.'
      }
    }
  },
  statistics: {
    types: 'Diagram Types',
    bar: 'BarGraph',
    line: 'LineChart',
    histogram: 'BarHistograms',
    pie: 'PieCharts',
    sun: 'Sunburts',
    multi: 'Multi-AxisCharts',
    donut: 'DoughnutCharts',
    linkStatistics: 'go to statistics section',
    onerParameter: 'Select one parameter',
    parameter: 'Select an parameter',
    table: 'Table',
    selectLocation: 'Select location',
    selectOption:
      'To display other types of charts, select the options "One Attribute" or "One Location"',
    byRegion: 'By region',
    ByIndicators: 'By indicators',
    OnYears: 'On years'
  },
  app: {
    region: 'Region',
    aboutSystem: 'ABOUT SYSTEM',
    mainSystem: 'MAIN',
    adaptiveClose: 'Close',
    createReport: 'CREATE REPORT',
    statistic: 'STATISTICS',
    articles: 'ARTICLES',
    faq: 'FAQ',
    nur_sultan: 'Astana',
    almaty: 'Almaty',
    shymkent: 'Shymkent',
    aqmola: 'Aqmola region',
    aqtobe: 'Aqtobe region',
    almatyObl: 'Almaty region',
    atyrau: 'Atyrau region',
    vko: 'The East Kazakhstan region',
    jambyl: 'Jambyl region',
    zko: 'The West Kazakhstan region',
    karagandy: 'Qaragandy region',
    qostanai: 'Qostanai region',
    qyzylorda: 'Qyzylorda region',
    mangystau: 'Mangystau region',
    pavlodar: 'Pavlodar region',
    sko: 'The North Kazakhstan region',
    turkistan: 'Turkistan region',
    lichKab: 'Personal area',
    adminPanel: 'Go to admin panel',
    exit: 'EXIT',
    creatingReport: 'CREATING A REPORT',
    currentPage:
      'On this page you can generate a report with statistical data in a convenient PDF or Excel format.',
    stepOne: 'STEP 1',
    insertDataToSearch:
      'Enter in the “Search” field the data you would like to find',
    insertText: 'Search',
    stepTwo: 'STEP 2',
    selectPeriod: 'Select period',
    stepThree: 'STEP 3',
    downloadReport: 'Download this report',
    download: 'DOWNLOAD'
  },
  profile: {
    errorLoginMassage:
      "The old password was entered incorrectly and / or the passwords don't match. Try again.",
    errorMessage: 'Request error. Try again.',
    phone: 'Phone number:',
    gender: 'Gender:',
    mail: 'mail:',
    edit: 'edit',
    editData: 'Edit data',
    FIO: 'Full Name ',
    man: 'Male',
    woman: 'Female',
    email: 'e-mail ',
    save: 'save',
    cancel: 'Cancel',
    changePwd: 'change password',
    changePwdModal: 'Change password',
    oldPwd: 'Old password  ',
    newPwd: 'New password ',
    confirmPwd: 'Confirm new password',
    reports: 'My Reports',
    reportsSubTitle: 'Generate a report in a format convenient for you',
    newReports: 'create a new report',
    logIn: 'log in'
  },
  admin: {
    createUser: 'Create User',
    role: 'Role',
    admin: 'Admin',
    guide: 'Guide',
    content_manger: 'Content-Manager',
    fullName: 'Fullname',
    pwd: 'Password',
    confirmPwd: 'Confirm the password',
    save: 'save',
    cancel: 'cancel',
    name: 'Name',
    mail: 'Mail',
    operation: 'Operations',
    change: 'Change',
    delete: 'Delete',
    changeData: 'Change data',
    yes: 'yes',
    no: 'no',
    createArticle: 'Create Article',
    title: 'Title',
    subtitle: 'Subtitle',
    alias: 'Alias',
    content: 'Content',
    image: 'Image',
    deleteArticle: 'Delete Article',
    visualization: 'Visualization',
    import: 'Import',
    importManual: 'Manual import',
    importStat: 'Import from StatGov',
    continue: 'Continue',
    dynamic: 'Dynamic Tables',
    edit: 'Edit'
  },
  login: {
    back: 'back',
    lk: 'Login to your account',
    pwd: 'Password',
    enter: 'Enter',
    create: 'Create personal account',
    reg: 'registration',
    regis: 'Registration',
    rpwd: 'Come up with a password',
    confirmPwd: 'confirm new password',
    confirmPwdRegistration: 'Confirm password',
    forgotPassword: 'Forgot your password?',
    errorLogin: ' Invalid username or password',
    loginSuccessful: 'Successful! check your email'
  },
  adminApp: {
    main: 'Main',
    toSite: 'Go to website',
    users: 'Users',
    statistics: 'Statistics',
    articles: 'Articles',
    online: 'Online',
    viewed: 'Viewed in a month',
    accounts: 'Account',
    download: 'Downloaded',
    staticPages: 'Static pages'
  },
  panelAdminArticles: {
    createArticle: 'Create a news item',
    saveNews: 'Save News',
    Preview: 'Preview',
    LoadingWait: 'Loading! Wait',
    errorOccurred: 'an error occurred'
  },
  panelAdminFaq: {
    AddaFaq: 'Add a FAQ',
    changeFaq: 'Edit the FAQ',
    title: 'Title',
    operations: 'Operations',
    reallyWantMessage: 'Do you really want to delete the data?',
    editData: 'Edit data',
    question: 'Question',
    Answer: 'Answer',
    CreateTab: 'Create a tab'
  },
  multiLogo: {
    logoMCS: 'MCS_Logos_ENG.png'
  },
  touchbord: {
    title: 'Portait of a foreing tourist',
    darkStyle: 'Dark Mode',
    lightStyle: 'Light mode',
    discription: `Real-time data is provided by the «eQonaq» information system, due to the automated collection of data from places of accommodation (hotels, hostels, recreation centers). At the moment, the system keeps records of foreign tourists in more than 60% of places of accommodation in Kazakhstan.`,
    region: 'by region',
    city: 'by city',
    low: 'low',
    normal: 'normal',
    high: 'high',
    more: 'more',
    touristsByCountry: 'Number of tourists by country',
    hintContent:
      'On this infographic, you can see from which countries tourists arrived at the accommodation sites that are registered in the «eQonaq» system.',
    popularCountries: 'TOP 10 popular countries',
    popularRegions: 'TOP 10 popular regions',
    hintContentTop:
      'This chart shows the rating of the TOP 10 countries from where tourists arrived to accommodation places that are registered in the «eQonaq» system.',
    hintContentTopRegion:
      'This chart shows the rating of the TOP 10 regions where tourists arrived at accommodation places that are registered in the "eQonaq" system',
    Portrait: 'Portrait of a tourist',
    hintContentPortrait:
      'This chart shows the gender data for tourists, as well as their age group in accommodation locations that are registered in the «eQonaq» system.',
    gender: 'Gender',
    man: 'Man',
    woman: 'Woman',
    TouristAge: `Tourist's age`,
    purpose: `The purpose of tourists' visits to Kazakhstan`,
    hintContentPurpose:
      'Data on the purposes of the visits of tourists who arrived at the accommodation registered in the «eQonaq» system.',
    placements: 'Top 10 placements ',
    hintContentPlacements:
      'This chart shows the rating of the TOP 10 accommodation places that have been visited by the largest number of tourists. The rating is based on placements that are registered in the «eQonaq» system.',
    hintTitle: 'The number of foreign tourists arriving by region',
    hintDescription:
      'On this infographic you can see the ratio of arrivals of foreign tourists by region or city. Real-time data is transmitted by the "eQonaq" information system'
  },
  breadCrumbs: {
    main: 'Main',
    TourismStatistics: 'Tourism Statistics',
    TouristServices: 'Tourst Services',
    TravelExpenses: 'Travel expenses',
    OutboundVisitors: 'Placements',
    StatisticsRoutesAndAttractions: 'Routes and attractions'
  },
  fequently: {
    FrequentlyViewed: 'Frequently viewed metricsFrequently viewed metrics'
  },
  statisticsResult: {
    indicator: 'Indicator',
    region: 'Region',
    year: 'Year',
    download: 'Download the report',
    table: 'Table',
    chart: 'Chart',
    prediction: 'There are additionally predicted data on the graph'
  },
  day: 'Today',
  week: 'Week',
  month: 'Month',
  year: 'Year',
  quater: '{0} quarter',
  quaterTitle: 'Quarter',
  period: 'Period',
  regions: {
    'Западно-Казахстанская область': 'West Kazakhstan region',
    'Павлодарская область': 'Pavlodar region',
    'Алматинская область': 'Almaty region',
    'Жамбылская область': 'Zhambyl region',
    'Акмолинская область': 'Akmola region',
    'Карагандинская область': 'Karaganda region',
    'Северо-Казахстанская область': 'North Kazakhstan region',
    'Восточно-Казахстанская область': 'East Kazakhstan region',
    'Актюбинская область': 'Aktobe region',
    'Атырауская область': 'Atyrau region',
    'Кызылординская область': 'Kyzylorda region',
    'Костанайская область': 'Kostanay region',
    'Мангистауская область': 'Mangystau region',
    'Туркестанская область': 'Turkestan region',
    'Южно-Казахстанская область': 'South Kazakhstan region'
  }
}
