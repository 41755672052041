
  import { defineComponent, reactive, ref } from 'vue'
  import AdminMainTopLine from '@/components/AdminMainTopLine.vue'
  import AdminImport from '@/components/Adminimport.vue'

  import AdaptiveMainTopLine from '@/components/AdaptiveMainTopLine.vue'
  import AdminAdaptiveMainTopLine from '@/components/AdminAdaptiveMainTopLine.vue'
  import { api } from '@/api'
  import { useRoute } from 'vue-router'
  import { i18n } from '@/locales'

  export default defineComponent({
    components: {
      AdminMainTopLine,
      AdminImport,
      AdminAdaptiveMainTopLine
    },
    setup() {
      let fromDate = ref('')
      let toDate = ref('')
      const attrs = reactive({})
      globalThis.attrsX = attrs
      const data = reactive({
        attributeIndexes: {},
        dataTable: [],
        locationIndexes: {},
        nameBulletenIndex: {},
        batchList: []
      })
      let postData = reactive({
        attrs: [],
        locations: [],
        name: '',
        fromDate: '',
        toDate: '',
        typeUnits: [],
        values: []
      })
      async function getImportFile() {
        data.dataTable = []
        for (let rowIndex = 0; rowIndex < 4; rowIndex++) {
          data.dataTable.push([])
          for (let colIndex = 0; colIndex < 3; colIndex++) {
            data.dataTable[rowIndex].push({
              type: null,
              value: ''
            })
          }
        }
        data.attributeIndexes = {
          ['index-end-column']: 3,
          ['index-row']: 1,
          ['index-start-column']: 1
        }
        data.locationIndexes = {
          ['index-column']: 0,
          ['index-end-row']: 3,
          ['index-start-row']: 2
        }
        data.nameBulletenIndex = {
          ['index-column']: 0,
          ['index-row']: 0
        }

        data.dataTable[0][0].type = 'bilut'
        data.dataTable[1][1].type = 'attr'
        data.dataTable[1][2].type = 'attr'
        data.dataTable[2][0].type = 'loc'
        data.dataTable[3][0].type = 'loc'
      }
      function selectedBulletin() {
        let m = 0
        for (let r = 0; r < data.dataTable.length; r++) {
          for (let c = 0; c < data.dataTable[r].length; c++) {
            if (
              data.nameBulletenIndex['index-column'] == c &&
              data.nameBulletenIndex['index-row'] == r
            ) {
              attrs[m] = 'bilut'
            }
            if (
              data.locationIndexes['index-column'] == c &&
              data.locationIndexes['index-end-row'] >= r &&
              data.locationIndexes['index-start-row'] <= r
            ) {
              attrs[m] = 'loc'
            }
            if (
              data.attributeIndexes['index-row'] == r &&
              data.attributeIndexes['index-end-column'] >= c &&
              data.attributeIndexes['index-start-column'] <= c
            ) {
              attrs[m] = 'attr'
            }
            m++
          }
        }
      }
      getImportFile()
      selectedBulletin()

      function plusRow() {
        data.dataTable.push([])

        for (
          let colIndex = 0;
          colIndex < data.dataTable[0].length;
          colIndex++
        ) {
          data.dataTable[data.dataTable.length - 1].push({
            type: null,
            value: ''
          })
        }
      }
      function minusRow() {
        data.dataTable.pop()
      }
      function plusCol() {
        for (let row of data.dataTable) {
          row.push({ type: null, value: '' })
        }
      }
      function minusCol() {
        for (let row of data.dataTable) {
          row.pop()
        }
      }

      function postBulletin() {
        let arrtd
        let unitname
        postData = {
          attrs: [],
          locations: [],
          name: '',
          fromDate: '',
          toDate: '',
          typeUnits: [],
          values: []
        }
        arrtd = document.querySelectorAll('.import-box')

        let m = 0
        for (let r = 0; r < data.dataTable.length; r++) {
          for (let c = 0; c < data.dataTable[r].length; c++) {
            if (arrtd[m].children[1].value == 'attr') {
              let value = arrtd[m].children[0].value

              postData.attrs.push({
                name: value,
                ['index-row']: r,
                ['index-column']: c
              })

              if (arrtd[m].children[1].value == 'attr') {
                unitname = arrtd[m].children[2].children[0].value
                postData.typeUnits.push({
                  name: unitname,
                  ['index-row']: r,
                  ['index-column']: c
                })
              }
            }
            if (arrtd[m].children[1].value == 'loc') {
              let value = arrtd[m].children[0].value
              postData.locations.push({
                name: value,
                ['index-row']: r,
                ['index-column']: c
              })
            }
            if (arrtd[m].children[1].value == 'bilut') {
              let value = arrtd[m].children[0].value
              postData.name = value
            }

            m++
          }
        }
      }
      function postValue() {
        let m = 0
        let arrtd
        let indexLocation = 0
        arrtd = document.querySelectorAll('.import-box')
        for (let r = 0; r < data.dataTable.length; r++) {
          m = r * data.dataTable[r].length

          if (arrtd[m].children[1].value == 'loc') {
            for (let c = 0; c < data.dataTable[r].length - 1; c++) {
              m++
              let value = arrtd[m].children[0].value
              postData.values.push({
                value: value,
                ['index-location']: indexLocation,
                ['index-attr']: c
              })
            }
            indexLocation++
          }
        }
        postData.fromDate = fromDate.value
        postData.toDate = toDate.value
      }
      async function takeBulletin() {
        await postBulletin()
        await postValue()
        await importBulletin()
      }
      async function importBulletin() {
        const response = await api.post('/v1/admin/import/now', postData)
        if (response.status == 200) {
          alert('успешно загруженно')
        }
        await cleanVariables()
      }
      function cleanVariables() {
        postData = {
          attrs: [],
          locations: [],
          name: '',
          fromDate: '',
          toDate: '',
          typeUnits: [],
          values: []
        }
      }

      return {
        postData,
        data,
        attrs,
        plusRow,
        plusCol,
        minusRow,
        minusCol,
        takeBulletin,
        fromDate,
        toDate
      }
    }
  })
