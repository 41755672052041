<template>
  <div class="tab-head">
    <div v-for="(item, itemInd) in items" :key="itemInd" :class="{ 'tab-head__item--active': itemInd == active, ['tab-head__item--' + type]: 'active' }" class="tab-head__item" @click="select(itemInd)">
      <div class="tab-head__icon-wrap" :title="item.name">
        <img v-if="item.icon" :src="item.icon" alt="" class="tab-head__icon" />
        <img v-if="item.activeIcon" :src="item.activeIcon" alt="" class="tab-head__icon tab-head__icon--active" />
      </div>
      <span>{{ item.name }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      active: {
        type: Number,
        required: true
      },
      items: {
        type: Array,
        default() {
          return []
        }
      },
      type: {
        type: String,
        default: 'default'
      }
    },
    emits: ['select'],
    setup(_, context) {
      function select(ind) {
        context.emit('select', ind)
      }

      return {
        select
      }
    }
  }
</script>

<style lang="scss" scoped>
  .tab-head {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 5px;
    overflow-x: auto;
    box-sizing: border-box;

    @media screen and (max-width: 576px) {
      flex-direction: column;
      align-items: stretch;
      gap: 10px;
      max-height: 420px;
      overflow-y: auto;
    }

    &__item {
      max-width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      gap: 10px;
      box-sizing: border-box;
      padding: 17px 56px;
      border-radius: 10px;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.2;
      background: $lightgray;
      color: $main-blue;
      transition: 0.3s linear;
      cursor: pointer;

      @media screen and (max-width: 768px) {
        justify-content: flex-start;  
        padding: 17px 25px;      
      }

      &--active {
        background: $main-yellow;
        color: $white;
      }

      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @media screen and (max-width: 768px) {
          white-space: unset;
          justify-content: flex-start;  
          padding: 17px 25px;      
        }
      }
    }

    &__icon-wrap {
      position: relative;

      img {
        transition: 0.3s linear;
        display: block;
      }
    }

    &__icon {
      opacity: 1;
      height: 44px;
    }

    &__item--active &__icon {
      opacity: 0;
    }

    &__icon:last-child {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }

    &__item--active &__icon:last-child {
      opacity: 1;
    }
  }
</style>
