
import { ref } from 'vue'

import MenuDesktopList from '@/components/main/MenuDesktopList.vue'
import SearchBlock from '@/components/main/SearchBlock.vue'

export default {
  components: {
    MenuDesktopList,
    SearchBlock
  },
  setup() {
    const isActive = ref(false)

    return {
      isActive
    }
  }
}
