import { createApp } from 'vue'
import vueClickOutsideElement from 'vue-click-outside-element'

import SvgIcon from '@/components/ui/SvgIcon.vue'

import { i18n } from './locales'
import router from './router'
import store from './store'

import { AuthService } from './services/auth.service'

import { api } from './api'

import App from './App.vue'

const app = createApp(App)

app.component('SvgIcon', SvgIcon)

app.provide(AuthService.TOKEN, new AuthService(api))

app.use(store)
app.use(router)
app.use(i18n)
app.use(vueClickOutsideElement)

app.mount('#app')
