
import { ref, onUpdated } from 'vue'

import { api } from '@/api'

export default {
  setup() {
    let timer

    const searchInput = ref('')
    const noResulteIsActiveNews = ref(false)
    const changeactive = ref(false)
    const searchResulteNews = ref([])
    const searchResulteBulletin = ref([])
    const noResulteIsActiveBulletin = ref(false)

    onUpdated(() => {
      const form = document.getElementById('input')
      form.addEventListener(
        'focus',
        () => {
          changeactive.value = true
        },
        true
      )
      form.addEventListener('blur', () => {
        setTimeout(() => {
          changeactive.value = false
        }, 300)
      })
    })

    async function search() {
      try {
        const response = await api.get(
          'v1/search?search_query=' + searchInput.value
        )

        if (response.data.articles.length == 0) {
          searchResulteNews.value = [{ seo_description: '' }]
          noResulteIsActiveNews.value = true
        } else {
          searchResulteNews.value = response.data.articles
          noResulteIsActiveNews.value = false
        }
        if (response.data.reports.length == 0) {
          searchResulteBulletin.value = [{ name: '' }]
          noResulteIsActiveBulletin.value = true
        } else {
          searchResulteBulletin.value = response.data.reports
          noResulteIsActiveBulletin.value = false
        }

        changeactive.value = true
      } catch (error) {
        console.error(error)
      }
    }

    function searchTimer() {
      clearTimeout(timer)
      timer = setTimeout(function () {
        search()
      }, 300)
    }

    return {
      noResulteIsActiveNews,
      searchResulteNews,
      changeactive,
      searchTimer,
      searchInput,
      search,
      noResulteIsActiveBulletin,
      searchResulteBulletin
    }
  }
}
