
  import { defineComponent, reactive, ref, watch, onMounted } from 'vue'
  import MainTopLine from '@/components/MainTopLine.vue'
  import MainFooter from '@/components/MainFooter.vue'
  import LineChart from '@/components/LineChart.vue'
  import { api } from '@/api'
  import AdaptiveMainTopLine from '@/components/AdaptiveMainTopLine.vue'
  import { createNamespacedHelpers } from 'node_modules/vuex/types'
  import { useRoute, useRouter } from 'vue-router'
  import router from '@/router'
  import AmCharts from '@/components/AmCharts.vue'

  export default defineComponent({
    components: {
      AdaptiveMainTopLine,
      MainTopLine,
      MainFooter,
      LineChart,
      AmCharts
    },
    setup() {
      let saveData
      let saveBulletinAttributesMap
      let graphics = reactive({
        labels: [],
        date: '',
        values: [],
        color: [],
        name: '',
        chart: 'line'
      })
      let data = reactive({
        search: '',
        bulletin: ''
      })
      let oneValuesLocationAndAttributeIsActive = ref(false)
      let oneLocationInAtributes = ref(false)
      let oneAttributeInLocation = ref(false)
      let locationOne = false
      let attributeOne = false
      let amChartsData = ref([])
      const amchartIsActive = ref(false)
      let currentBulletinId = ref()
      let inputSelect = ref()
      let bulletins = ref([])
      let bulletinPeriod = ref([])
      let bulletinLocations = ref([])
      let bulletinLocationsMap = ref([])
      let bulletinAttributes = ref([])
      let bulletinAttributesMap = ref([])
      let bulletinPeriodSelect = ref()
      let bulletinDate = ref('')
      let bulletinValues = ref([])
      let bulletinArr = ref([])
      let bullettinTable = ref([])
      let changeactive = ref(true)
      let inputPlaceBulletinName = ref('')
      const dataValues = ref([])
      let bulletinNameButton = ref([])
      let frequentlyReports = ref('')
      const route = useRoute()
      let active = ref(false)
      let checkedBulletin = ref(false)
      let timer
      let checkedValues = ref()
      let changeCheckedActive = ref(false)
      let selectOption
      let changeactive1 = ref(false)
      let conficurationData
      let oneLocationAndAttributeIsActive = ref(false)
      let arrayData = {
        attributes: [],
        locations: []
      }
      let bulletinCategoryName = ref('')
      let checkedBulletinName = ref('')
      let chartButtons
      let isActive = ref(false)
      let doubleBulletinData = ref([])
      let attributechangeCheckedActive = ref(false)
      let LocationchangeCheckedActive = ref(false)
      let oneAttribute = ref('')
      let oneLocation = ref('')
      let oneLocalAndAttributeActive = ref(false)
      let authorization = ref(false)
      let arrayColor = [
        '#4DB6AC',
        '#81C784',
        '#4DD0E1',
        '#AED581',
        '#DCE775',
        '#F06292',
        '#BA68C8',
        '#E57373',
        '#FF8A65',
        '#FFB74D',
        '#FFB74D',
        '#4FC3F7',
        '#78DFEC',
        '#6AAECC'
      ]
      let startIsActive = true
      let oneChacked = true
      const isPredictionActive = ref(false)
      function searchBulletinTimer() {
        window.clearTimeout(timer)
        if (oneChacked) {
          changeactive.value = false
          oneChacked = false
        } else {
          timer = window.setTimeout(function () {
            searchBulletin()
          }, 100)
          oneChacked = true
        }
      }
      async function searchBulletin() {
        deleteBulletin()
        isActive.value = false

        try {
          const response = await api.post('/v1/chart/search/bulletin', {
            search: data.search
          })
          bulletins.value = response.data.data.listBulletins
          changeactive.value = true
        } catch (error) {
          bulletins.value = null
        }
      }
      async function clickBulletinById(bulletinId, bulletinName) {
        bulletinNameButton.value = []
        checkedBulletin.value = true
        try {
          currentBulletinId.value = bulletinId
          const response = await api.post(
            '/v1/chart/search/period',

            {
              bulletin_id: bulletinId
            }
          )
          bulletinPeriod.value = response.data
          bulletinDate.value = '0'
          changeactive.value = false

          // inputPlaceBulletinName.value = bulletins.value.find(
          //     item => item.id == currentBulletinId.value
          // ).name
          oneLocationSAndAttribute()

          bulletinNameButton.value.push({ name: bulletinName })
          checkedBulletinName.value = bulletinName
          await bulletinCategory()
        } catch (error) {
          console.error(error)
        }
      }
      async function bulletinCategory() {
        try {
          const response = await api.get(
            `v1/chart/bulletins/${currentBulletinId.value}/category`
          )

          bulletinCategoryName.value = response.data.name
        } catch (error) {
          console.error(error)
        }
      }
      async function getBulletinLocation() {
        try {
          const response = await api.post('/v1/chart/search/location', {
            bulletins: [
              {
                id: currentBulletinId.value
              }
            ]
          })

          bulletinLocations.value = response.data.data.listLocations
          arrayData.locations = bulletinLocations.value.map(function (obj) {
            return {
              name: obj.name,
              id: obj.location_id,
              values: obj.location_values
            }
          })
        } catch (error) {
          console.error(error)
        }
      }
      async function getAttributes() {
        try {
          const response = await api.post('/v1/chart/search/attr', {
            bulletins: [
              {
                id: currentBulletinId.value
              }
            ]
          })
          bulletinAttributes.value = response.data.data.listAttribute
          arrayData.attributes = bulletinAttributes.value.map(function (obj) {
            return {
              name: obj.name,
              id: obj.report_attribute_id,
              values: obj.attribute_values
            }
          })
        } catch (error) {
          console.error(error)
        }
      }
      async function oneAttributes() {
        bulletinLocationsMap.value = []
        bulletinAttributesMap.value = []
        oneLocalAndAttributeActive.value = false
        await getAttributes()
        checkedValues.value = arrayData.attributes
        changeCheckedActive.value = true
        selectOption = 'attribute'
        await getBulletinLocation()
        setChart('line')
      }
      async function oneLocations() {
        bulletinLocationsMap.value = []
        bulletinAttributesMap.value = []
        oneLocationAndAttributeIsActive.value = false
        oneLocalAndAttributeActive.value = false
        await getBulletinLocation()
        checkedValues.value = arrayData.locations
        changeCheckedActive.value = true
        selectOption = 'location'
        await getAttributes()
        setChart('line')
      }
      async function oneLocationSAndAttribute() {
        isActive.value = true
        bulletinLocationsMap.value = []
        bulletinAttributesMap.value = []

        selectOption = 'oneLocationAndAttribute'
        await getBulletinLocation()
        await getAttributes()
        oneLocalAndAttributeActive.value = true
        attributechangeCheckedActive.value = true
        LocationchangeCheckedActive.value = true
        if (startIsActive) {
          await selectAttributeBulletin(arrayData.attributes[0].id)
          await selectLocationBulletin(arrayData.locations[0].id)
        }

        if (arrayData.attributes.length <= 1) {
          oneLocationInAtributes.value = true
        } else {
          oneLocationInAtributes.value = false
        }
        if (arrayData.locations.length <= 1) {
          oneAttributeInLocation.value = true
        } else {
          oneAttributeInLocation.value = false
        }
        setChart('line')
      }

      function getDoubleValues() {
        saveData = {}
        doubleBulletinData.value = []

        let getDoubleValues = new Promise(async (resolve) => {
          dataValues.value = []
          doubleBulletinData.value.length = 0
          try {
            const response = await api.get('/v1/chart/values-years', {
              params: {
                reportId: currentBulletinId.value,
                attrId: oneAttribute.value,
                locationId: oneLocation.value
              }
            })
            doubleBulletinData.value = response.data.data
            let color
            for (let data of doubleBulletinData.value) {
              color = dynamicColors()
              // if (data.prediction) {
              //     isPredictionActive.value = true
              //     color = '#75a3ff52'
              // }
              dataValues.value.push({
                data: [data.value],
                label: [data.to_year],
                backgroundColor: color,
                borderColor: color
              })
            }
            graphics.labels = bulletinAttributesMap.value.map(
              (item) => item.name
            )
            saveData = {
              value: dataValues.value,
              label: graphics.labels
            }
            resolve('jaksy')
          } catch (error) {
            console.error(error)
          }
        })

        getDoubleValues.then(
          function () {
            let data = []

            data = dataValues.value.map(function (item) {
              return {
                country: item.label[0],
                visits: item.data[0]
              }
            })

            amChartsData.value = data

            bullettinTable.value = amChartsData.value.map((item) => {
              return {
                name: item.country,
                values: [{ value: item.visits }]
              }
            })

            setChart(graphics.chart)
            processData(graphics.chart)
            checkedValuesAtribute()
          },
          function (reason) {
            console.log(reason) // Ошибка!
          }
        )
      }
      async function selectLocationBulletin(id) {
        oneLocation.value = id
        bulletinLocationsMap.value[0] = arrayData.locations.find(
          (item) => item.id == id
        )
        LocationchangeCheckedActive.value = false

        await getDoubleValues()
      }
      async function checkedValuesAtribute() {
        if (doubleBulletinData.value.length < 1) {
          oneValuesLocationAndAttributeIsActive.value = true

          isActive.value = false
          await oneAttributes()
          await clickCheckedValue(
            checkedValues.value[0].id,
            checkedValues.value[0].name
          )
        }
      }
      async function selectAttributeBulletin(id) {
        oneAttribute.value = id
        // bulletinAttributesMap.value[0] = arrayData.attributes.find(
        //     item => item.id == id
        // )
        attributechangeCheckedActive.value = false
        getDoubleValues()
      }

      // async function getDoubleValues() {
      //     dataValues.value = []
      //     try {
      //         const response = await api.get('/v1/chart/values-years', {
      //             params: {
      //                 reportId: currentBulletinId.value,
      //                 attrId: oneAttribute.value,
      //                 locationId: oneLocation.value
      //             }
      //         })
      //         doubleBulletinData.value = response.data.data

      //         let color = dynamicColors()
      //         for (let data of doubleBulletinData.value) {
      //             dataValues.value.push({
      //                 data: [data.value],
      //                 label: [data.to_year],
      //                 backgroundColor: color,
      //                 borderColor: color
      //             })
      //         }
      //         graphics.labels = bulletinAttributesMap.value.map(
      //             item => item.name
      //         )
      //         saveData = {
      //             value: dataValues.value,
      //             label: graphics.labels
      //         }
      //     } catch (error) {
      //         console.error(error)
      //     }
      // }

      async function selectInput() {
        if (selectOption == 'location') {
          checkedValues.value = bulletinLocationsMap.value
        } else {
          checkedValues.value = bulletinAttributesMap.value
        }
      }
      let currentId
      async function clickCheckedValue(id, name) {
        currentId = id
        changeCheckedActive.value = false
        bulletinAttributesMap.value = arrayData.attributes
        bulletinLocationsMap.value = arrayData.locations
        if (selectOption == 'location') {
          bulletinLocationsMap.value = bulletinLocationsMap.value.filter(
            (item) => item.id == id
          )
          let bulletinAttributesId = bulletinAttributesMap.value.map(
            (item) => item.id
          )
          conficurationData = {
            attrs: bulletinAttributesId,
            locations: [id],
            from_date: bulletinPeriod.value[bulletinDate.value].from_date,
            to_date: bulletinPeriod.value[bulletinDate.value].to_date
          }
        } else if (selectOption == 'attribute') {
          let bulletinLocationsId = bulletinLocationsMap.value.map(
            (item) => item.id
          )
          bulletinAttributesMap.value = bulletinAttributesMap.value.filter(
            (item) => item.id == id
          )
          conficurationData = {
            attrs: [id],
            locations: bulletinLocationsId,
            from_date: bulletinPeriod.value[bulletinDate.value].from_date,
            to_date: bulletinPeriod.value[bulletinDate.value].to_date
          }
        }
        await getValues()
        await setChart(graphics.chart)
      }
      async function getValues() {
        saveData = {}
        checkedValuesAtribute()
        bulletinValues.value.length = 0
        bulletinValues.value = []

        dataValues.value.length = 0
        saveBulletinAttributesMap = bulletinAttributesMap.value

        try {
          const response = await api.post('/v1/chart/values', conficurationData)
          bulletinValues.value = response.data.data.slice()
          const vals = []
          let bv = []
          for (const bl of bulletinLocationsMap.value) {
            for (const ba of bulletinAttributesMap.value) {
              let pushed = false
              for (const val of bulletinValues.value) {
                if (
                  val.report_attribute_id == ba.id &&
                  val.location_id == bl.id
                ) {
                  vals.push(val)
                  bv.push(val.value)

                  pushed = true
                }
              }

              if (!pushed) {
                vals.push({ value: '0' })
              }
            }
          }

          let i = 0
          for (const item of bulletinLocationsMap.value) {
            let row
            let findRow = []
            let backgroundColor = []

            let push
            for (const bl of bulletinAttributesMap.value) {
              row = []

              for (const ba of bl.values) {
                if (ba.location_id == item.id) {
                  row.push(ba.value)
                }
              }
              // findRow.push(row[0])
              push = bv[i]
              i++

              backgroundColor.push(dynamicColors())
            }

            dataValues.value = [
              ...dataValues.value,
              {
                data: [push],
                label: item.name,
                backgroundColor: backgroundColor,
                borderColor: backgroundColor
              }
            ]
            push = 0
            findRow = []

            graphics.labels = bulletinAttributesMap.value.map(
              (item) => item.name
            )

            saveData = {
              value: dataValues.value,
              label: graphics.labels
            }
          }
          // for (const bl of bulletinLocationsMap.value) {
          //     for (const ba of bulletinAttributesMap.value) {
          //         let pushed = false
          //         for (const val of bulletinValues.value) {
          //             if (
          //                 val.report_attribute_id == ba.id &&
          //                 val.location_id == bl.id
          //             ) {
          //                 vals.push(val)
          //                 bv.push(val.value)

          //                 pushed = true
          //             }
          //         }

          //         if (!pushed) {
          //             vals.push({ value: '-' })
          //         }
          //     }
          // }

          bullettinTable.value = []
          for (let i = 0; i < bulletinLocationsMap.value.length; i++) {
            bullettinTable.value[i] = {
              name: bulletinLocationsMap.value[i].name,
              values: vals.slice(0, bulletinAttributesMap.value.length)
            }
            vals.splice(0, bulletinAttributesMap.value.length)
          }

          editGraphics()
          let check = dataValues.value
        } catch (error) {
          console.error(error)
        }
      }
      function editGraphics() {
        graphics.date =
          bulletinLocations.value[0].location_values[0].from_date +
          '-' +
          bulletinLocations.value[0].location_values[0].to_date

        graphics.name = bulletins.value.find(
          (item) => item.id == currentBulletinId.value
        ).name
      }
      let index = 0

      let loop
      function dynamicColors() {
        if (index == arrayColor.length) {
          loop = true
        } else if (index == 0) {
          loop = false
        }
        if (loop) {
          index = index - 1
        } else {
          index = index + 1
        }
        let color = arrayColor[index]
        return color
      }

      function setChart(typeChart) {
        graphics.chart = typeChart
        active.value = true
        amchartIsActive.value = false
        processData(graphics.chart)

        if (typeChart == 'line') {
          chartButtons.forEach((element) => {
            element.classList.remove('tabs__item--active')
          })
          chartButtons[0].classList.add('tabs__item--active')
        }
      }

      function setCurrentAttribute(id) {
        bulletinAttributesMap.value = bulletinAttributesMap.value.filter(
          (a) => a !== id
        )
      }
      function deleteAttributes(id) {
        bulletinAttributesMap.value = bulletinAttributesMap.value.filter(
          (a) => a.id !== id
        )
        if (!oneLocalAndAttributeActive.value) getValues()
      }
      function deletelocations(id) {
        bulletinLocationsMap.value = bulletinLocationsMap.value.filter(
          (a) => a.id !== id
        )

        if (!oneLocalAndAttributeActive.value) getValues()
      }
      function deleteBulletin() {
        checkedBulletinName.value = ''
        bulletinCategoryName.value = ''
        oneLocationAndAttributeIsActive.value = false
        dataValues.value = []
        oneLocation.value = null
        oneAttribute.value = null
        oneValuesLocationAndAttributeIsActive.value = false
        oneLocationInAtributes.value = false
        oneAttributeInLocation.value = false
        isPredictionActive.value = false
        bulletinLocationsMap.value = []
        bulletinAttributesMap.value = []
        bulletinNameButton.value = []
        bulletinPeriod.value = []
        bulletinDate.value = ''
        inputPlaceBulletinName.value = ''
        bullettinTable.value = []
        // getValues()
        let buttonBulletin = document.querySelectorAll(
          '.button-bulletin-change'
        )
        buttonBulletin.forEach((button) => {
          buttonBulletin.forEach(function (item) {
            item.classList.remove('button-bulletin-change-active')
          })
        })
        changeCheckedActive.value = false
      }
      if (route.params.frequentlyReportTrue) {
        frequentlyReports.value = route.params.bulletinId.toString()
        getFrequentlyReports()
      }
      async function getFrequentlyReports() {
        try {
          changeactive.value = !changeactive.value
          inputPlaceBulletinName.value = route.params.bulletinName.toString()
          bulletinNameButton.value.push({
            name: route.params.bulletinName.toString()
          })
          data.search = route.params.bulletinName.toString()
          currentBulletinId.value = frequentlyReports.value

          await clickBulletinById(currentBulletinId.value, data.search)
          await oneLocationSAndAttribute()
        } catch (error) {
          console.error(error)
        }
      }

      onMounted(() => {
        chartButtons = document.querySelectorAll('.tabs__item')
        chartButtons.forEach((button) => {
          button.addEventListener('click', function (e) {
            chartButtons.forEach(function (item) {
              item.classList.remove('tabs__item--active')
            })

            button.classList.add('tabs__item--active')
          })
        })

        let buttonBulletin = document.querySelectorAll(
          '.button-bulletin-change'
        )
        buttonBulletin.forEach((button) => {
          button.addEventListener('click', function (e) {
            buttonBulletin.forEach(function (item) {
              item.classList.remove('button-bulletin-change-active')
            })
            button.classList.add('button-bulletin-change-active')
          })
        })
        let input = document.querySelectorAll('.statistic-list')[1]

        input.addEventListener('mouseleave', (event) => {
          window.onclick = (e) => {
            if (
              document.querySelectorAll('.statistic-list')[1] != e.target &&
              !(document.querySelector('.input') == e.target)
            ) {
              changeactive.value = false
            }
          }
        })
      })
      async function viewAllTable() {
        if (inputSelect.value) {
          let bulletinAttributesId = arrayData.attributes.map((item) => item.id)
          let builletinLocationId = arrayData.locations.map((item) => item.id)
          let conficurationDataAll = {
            attrs: bulletinAttributesId,
            locations: builletinLocationId,
            from_date: bulletinPeriod.value[bulletinDate.value].from_date,
            to_date: bulletinPeriod.value[bulletinDate.value].to_date
          }
          try {
            const response = await api.post(
              '/v1/chart/values',
              conficurationDataAll
            )

            bulletinValues.value = response.data.data.slice()

            const vals = []
            let bv = []
            for (const bl of arrayData.locations) {
              for (const ba of arrayData.attributes) {
                let pushed = false
                for (const val of bulletinValues.value) {
                  if (
                    val.report_attribute_id == ba.id &&
                    val.location_id == bl.id
                  ) {
                    vals.push(val)
                    bv.push(val.value)

                    pushed = true
                  }
                }

                if (!pushed) {
                  vals.push({ value: '-' })
                }
              }
            }
            for (const item of arrayData.locations) {
              let row
              let findRow = []

              for (const bl of arrayData.attributes) {
                row = []

                for (const ba of bl.values) {
                  if (ba.location_id == item.id) {
                    row.push(ba.value)
                  }
                }

                if (row.length == 1) {
                  findRow.push(row[0])
                } else {
                  findRow.push('-')
                }
              }

              findRow = []
            }

            bullettinTable.value = []
            for (let i = 0; i < arrayData.locations.length; i++) {
              bullettinTable.value[i] = {
                name: arrayData.locations[i].name,
                values: vals.slice(0, arrayData.attributes.length)
              }
              vals.splice(0, arrayData.attributes.length)
            }

            bulletinAttributesMap.value = arrayData.attributes
          } catch (error) {
            console.error(error)
          }
        } else {
          try {
            bulletinAttributesMap.value = saveBulletinAttributesMap
            await getValues()
          } catch (error) {
            console.error(error)
          }
        }
      }
      function downloadCanvas() {
        let canvas = document.getElementById('myChart')

        let img = (<HTMLCanvasElement>canvas).toDataURL('image/jpeg')
        // img.toString()
        let link = document.createElement('a')
        link.download = 'bullitenGraphics.jpeg'
        link.href = img
        link.click()
      }
      async function downloadFileExel() {
        if (currentBulletinId.value) {
        } else {
          alert('Сперва Введите либо выберите нужный показатель из списка')
        }
        if (window.localStorage.getItem('token') !== null) {
          authorization.value = true

          try {
            const response = await api.get(
              '/v1/excel-download?' +
                'id=' +
                currentBulletinId.value +
                '&fromDate=' +
                bulletinPeriod.value[bulletinDate.value].from_date +
                '&toDate=' +
                bulletinPeriod.value[bulletinDate.value].to_date
            )
            let path = 'https://back.tourstat.kz' + response.data.file_path

            window.open(path)
          } catch (error) {}
        } else {
          changeactive1.value = true
          authorization.value = false
        }
        if (authorization.value) {
        }
      }
      async function downloadFilePDF() {
        if (window.localStorage.getItem('token') !== null) {
          authorization.value = true

          try {
            const response = await api.get(
              '/v1/pdf-download?' +
                'id=' +
                currentBulletinId.value +
                '&fromDate=' +
                bulletinPeriod.value[bulletinDate.value].from_date +
                '&toDate=' +
                bulletinPeriod.value[bulletinDate.value].to_date
            )
            let path = 'https://back.tourstat.kz' + response.data.file_path

            window.open(path)
          } catch (error) {}
        } else {
          changeactive1.value = true
          authorization.value = false
        }
        if (authorization.value) {
        }
      }
      async function routerLink() {
        await router.push({ name: 'login' })
      }
      function setAmCharts(type) {
        dataValues.value = saveData.value

        graphics.labels = saveData.label
        amchartIsActive.value = true
        watchAmchgartsData
      }
      let watchAmchgartsData = () => {
        let data = []
        if (selectOption == 'location') {
          for (const dataValue of dataValues.value) {
            for (let i = 0; i < dataValue.data.length; i++) {
              data.push({
                country: bulletinAttributesMap.value[i].name,
                visits: dataValue.data[i]
              })
            }
          }
        }
        if (selectOption == 'attribute') {
          data = dataValues.value.map(function (item) {
            return {
              country: item.label,
              visits: item.data[0]
            }
          })
        }

        if (oneLocalAndAttributeActive.value) {
          watch(dataValues, () => {
            globalThis.dataValuesX = dataValues
            data = dataValues.value.map(function (item) {
              return {
                country: item.label[0],
                visits: item.data[0]
              }
            })
          })
        }

        amChartsData.value = data
      }

      async function processData(type) {
        let id = currentId
        let adata = []
        let abackgroundColor = []
        let aborderColor = []
        let checked = type

        if (saveData.value == null) {
        } else {
          dataValues.value = saveData.value
        }
        graphics.labels = saveData.label
        if (
          checked == 'line' ||
          checked == 'polarArea' ||
          checked == 'pie' ||
          checked == 'doughnut'
        ) {
          if (selectOption == 'attribute') {
            oneLocationAndAttributeIsActive.value = false
            for (let i = 0; i <= bulletinAttributesMap.value.length; i++) {
              dataValues.value.map(function (item) {
                if (item.backgroundColor.lenght == 0) {
                } else {
                  abackgroundColor.push(item.backgroundColor[0])
                }

                aborderColor.push(item.backgroundColor[0])
                adata.push(item.data[0])
              })
              let array = []
              array.push({
                backgroundColor: abackgroundColor,
                borderColor: aborderColor,
                data: adata,
                label: bulletinAttributesMap.value[0].name,
                fill: false
              })
              dataValues.value = array
              graphics.labels = bulletinLocationsMap.value.map(
                (item) => item.name
              )
            }
          }
          if (selectOption == 'location') {
            oneLocationAndAttributeIsActive.value = false
            for (let i = 0; i <= bulletinLocationsMap.value.length; i++) {
              dataValues.value.map(function (item) {
                if (item.backgroundColor.lenght == 0) {
                } else {
                  abackgroundColor.push(item.backgroundColor[0])
                }

                aborderColor.push(item.backgroundColor[0])
                adata.push(item.data[0])
              })
              let array = []
              array.push({
                backgroundColor: abackgroundColor,
                borderColor: aborderColor,
                data: adata,
                label: bulletinAttributesMap.value[0].name,
                fill: false
              })
              dataValues.value = array
              graphics.labels = bulletinLocationsMap.value.map(
                (item) => item.name
              )
            }
          }
          if (selectOption == 'oneLocationAndAttribute') {
            graphics.labels.length = 0
            for (let i = 0; i <= bulletinAttributesMap.value.length; i++) {
              dataValues.value.map(function (item) {
                // if (item.backgroundColor == null) {
                // } else {
                abackgroundColor.push(item.backgroundColor)
                // }
                aborderColor.push(item.backgroundColor)
                adata.push(item.data[0])
                graphics.labels.push(item.label[0])
              })
              let array = []
              array.push({
                backgroundColor: abackgroundColor,
                borderColor: aborderColor,
                data: adata,
                label: checkedBulletinName.value,
                fill: false
              })
              dataValues.value = array
              // graphics.labels = bulletinLocationsMap.value.map(
              //     item => item.name
              // )
            }
            // dataValues.value.map(function(item) {
            //     abackgroundColor.push(item.backgroundColor[0])
            //     aborderColor.push(item.backgroundColor[0])
            //     adata.push(item.data[0])
            // })

            oneLocationAndAttributeIsActive.value = true
          }
        } else {
          dataValues.value = saveData.value
          graphics.labels = saveData.label
        }
      }
      // watch(dataValues, watchAmchgartsData)
      // watch(
      //     () => Object.assign({}, graphics),
      //     graphics => {

      //         processData(graphics.chart)
      //         return
      //     },
      //     { deep: true }
      // )
      function showValuesByBulletin() {}

      return {
        bulletinCategoryName,
        isPredictionActive,
        amChartsData,
        amchartIsActive,
        setAmCharts,
        changeactive1,
        downloadFilePDF,
        downloadFileExel,
        downloadCanvas,
        deletelocations,
        deleteAttributes,
        setCurrentAttribute,
        changeactive,
        searchBulletin,
        bulletins,
        data,
        searchBulletinTimer,
        clickBulletinById,
        bulletinPeriod,
        bulletinLocations,
        bulletinAttributes,
        viewAllTable,
        bulletinPeriodSelect,
        bulletinDate,
        bulletinValues,
        bulletinArr,
        dataValues,
        bullettinTable,
        graphics,
        setChart,
        bulletinLocationsMap,
        bulletinAttributesMap,
        inputPlaceBulletinName,
        bulletinNameButton,
        deleteBulletin,
        checkedValues,
        active,
        oneAttributes,
        oneLocations,
        changeCheckedActive,
        clickCheckedValue,
        oneLocalAndAttributeActive,
        oneLocationSAndAttribute,
        arrayData,
        oneAttribute,
        oneLocation,
        selectLocationBulletin,
        selectAttributeBulletin,
        attributechangeCheckedActive,
        LocationchangeCheckedActive,
        getDoubleValues,
        routerLink,
        inputSelect,
        isActive,
        oneLocationInAtributes,
        oneAttributeInLocation,
        oneLocationAndAttributeIsActive,
        oneValuesLocationAndAttributeIsActive,
        showValuesByBulletin,
        checkedBulletinName
      }
    }
  })
