
  import { defineComponent } from 'vue'

  import BreadCrumbs from '@/components/ui/BreadCrumbs.vue'
  import SectionTitle from '@/components/ui/SectionTitle.vue'
  import StatCategories from '@/components/pages/statistics/StatCategories.vue'
  import FrequentlyReports from '@/components/pages/statistics/FrequentlyReports.vue'
  
  export default defineComponent({
    components: {
      BreadCrumbs,
      SectionTitle,
      StatCategories,
      FrequentlyReports
    },
  })
