
import { defineComponent, onMounted, onUpdated, ref } from 'vue'
import { useRoute } from 'vue-router'
import { getDigestidPdf } from '@/api/digest'
import store from '@/store'
import pdf from 'vue3-pdf'

export default defineComponent({
  name: 'DigestId',
  components: {
    pdf
  },
  setup() {
    // const data = ref(
    // {description_ru:'',
    // name_ru: '',
    // embed:''
    // })
    // const frame = ref()
    // let contentFrame
    // const route = useRoute()
    // console.log('  route.params',  route.params)
    const pdfFile = ref('')
    const myPdfComponent = ref()
    store.commit('setLoading', true)
    onMounted(async () => {
      const res = await getDigestidPdf()

      console.log('res', res)
      pdfFile.value = 'https://back.tourstat.kz/Kazakhstan_2022.pdf'
      //    try {
      //     store.commit('setLoading', true)
      //     data.value = await getDigestid(route.params.categoryId, route.params.id)
      //    } catch (error) {
      //     console.error(error)
      //    }finally {
      //     store.commit('setLoading', false)
      //    }
    })
    onUpdated(() => {
      //     store.commit('setLoading', true)
      //     frame.value.children[0]
      //     contentFrame = frame.value.children[0]
      //     contentFrame.style.width = '100%'
      //     contentFrame.addEventListener( "load", function(e) {
      //     store.commit('setLoading', false)
      // } )
      store.commit('setLoading', false)
    })
    return {
      // route,
      // data,
      // frame
      pdfFile
    }
  }
})
