export default function getWeekStartAndEndDates(currentDate: Date) {
  const firstDayOfWeek = new Date(currentDate)
  firstDayOfWeek.setDate(currentDate.getDate() - currentDate.getDay())

  const lastDayOfWeek = new Date(currentDate)
  lastDayOfWeek.setDate(currentDate.getDate() + (6 - currentDate.getDay()))

  const formattedStartDate = firstDayOfWeek.toISOString().split('T')[0]
  const formattedEndDate = lastDayOfWeek.toISOString().split('T')[0]

  return {
    start: formattedStartDate,
    end: formattedEndDate
  }
}