<template>
  <div class="window">
    <div class="modal">
      <!-- <div class="message">Новость загружается...</div> -->
      <!-- <button class="button">Выйти</button> -->
      <div class="box"></div>
    </div>
  </div>
</template>
<script>
  import { onBeforeUnmount, onMounted } from '@vue/runtime-core'
  export default {
    setup() {
      document.body.style.overflowY = 'hidden'

      onMounted(() => {
        document.body.style.overflowY = 'hidden'
      })
      onBeforeUnmount(() => {
        document.body.style.overflowY = 'auto'
      })
    }
  }
</script>
<style lang="scss" scoped>
  .modal {
    border-radius: 1rem;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 150px;
    display: flex;
    align-items: center;
    align-content: center;
    background: transparent;
    // box-shadow: 1px 0 15px black;
    left: 50%;
    top: 50%;
    justify-content: center;
    flex-direction: column;
    .message {
      font-size: 24px;

      margin-bottom: 40px;
    }
    .button {
      border: 1px solid black;
      background: white;
      margin-bottom: 50px;
    }
    .box {
      width: 80px;
      height: 80px;
      border: 5px solid #ca9e67;
      animation: download 2s infinite;
      animation-duration: 1s;
      // animation-direction: alternate;
    }
  }
  .window {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    height: 100vmax;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.779), rgba(0, 0, 0, 0.779));
  }
  @keyframes download {
    0% {
      border-radius: 0em;
    }
    25% {
      border-radius: 2em;
    }
    50% {
      transform: rotate(90deg);
    }
    100% {
      border-radius: 0em;
      transform: rotate(90deg);
    }
  }
</style>
