
import MenuDesktopList from '@/components/main/MenuDesktopList.vue'
import SearchBlock from '@/components/main/SearchBlock.vue'

export default {
  components: {
    MenuDesktopList,
    SearchBlock
  }
}
