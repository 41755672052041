
  import { defineComponent, onMounted } from 'vue'
  import { useRoute } from 'vue-router'

  import router from '@/router'
  import { api } from '@/api'
  import { useBulletin } from '@/composables/useBulletin'
  
  import BaseLayout from '@/layouts/BaseLayout.vue'
  import AdminLayout from '@/layouts/AdminLayout.vue'

  import { useTitle } from './composables/useTitle'

  export default defineComponent({
    name: 'App',
    components: {
      BaseLayout,
      AdminLayout,
    },
    setup() {
      const $route = useRoute()

      if (localStorage.getItem('token')) {
        try {
          const response = api.get('/v1/profile')
          response.catch(function (error) {
            if (error.response) {
              localStorage.removeItem('token')
              localStorage.removeItem('role')
              router.push({ name: 'Main' })
            }
          })
        } catch (error) {
          localStorage.removeItem('token')
          localStorage.removeItem('role')
        }
      } else {
        localStorage.removeItem('token')
        localStorage.removeItem('role')
      }

      onMounted(() => {
        useTitle()
      })

      return {
        ...useBulletin(),
        $route
      }
    }
  })
