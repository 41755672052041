
  import { computed, onMounted } from 'vue'
  import store from '@/store'
  import { useI18n } from 'vue-i18n'

  export default {
    setup() {
      const i18n = useI18n()

      const ids = [1, 11, 13, 18, 22, 23, 255]
      const bgColors = {
        1: '#944CEE',
        11: '#2FFC8D',
        13: '#55DCE7',
        18: '#FB8832',
        22: '#C99F69',
        23: '#0194EB',
        255: '#F9D451'
      }

      const filtered = computed(() => {
        return store.getters._getFrequentlyReports?.filter((e) =>
          ids.includes(e.id)
        )
      })

      const max = computed(() => {
        const t = filtered.value?.reduce((t, c) => {
          return Math.max(t, c.count)
        }, 0)

        return Math.round(t / 100) * 100
      })

      const percents = computed(() => {
        return filtered.value?.map((e) => {
          return Math.round((e.count / max.value) * 100)
        })
      })

      const getColor = computed(() => (id) => {
        return bgColors[id]
      })

      const getRange = computed(() => {
        return max.value
          ? new Array(Math.ceil(max.value / 100 + 1))
              .fill(null)
              .map((e, i) => i * 100)
          : []
      })

      onMounted(async () => {
        await store.dispatch('_setFrequentlyReports')
      })

      function getName(obj) {
        if (!obj) return ''
        return obj['name_' + i18n.locale.value] || ''
      }

      return {
        filtered,
        getColor,
        percents,
        getRange,
        getName
      }
    }
  }
