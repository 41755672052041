
  import { ref } from 'vue'
  import { useI18n } from 'vue-i18n'

  export default {
    setup() {
      const i18n = useI18n()
      const langs = {
        en: 'Eng',
        ru: 'Рус',
        kz: 'Қаз'
      }
      const changeLanguage = ref('')
      const localActive = ref(false)

      changeLanguage.value = langs[i18n.locale.value]

      function switchLocal(local) {
        i18n.locale.value = local.toLowerCase()
        localActive.value = !localActive.value
        changeLanguage.value = langs[i18n.locale.value]
        localStorage.setItem('lang', local.toLowerCase())
      }

      return {
        langs,
        changeLanguage,
        localActive,
        switchLocal
      }
    }
  }
