
  import { defineComponent, reactive, ref } from 'vue'
  import MainTopLine from '@/components/MainTopLine.vue'
  import Modal3 from '@/components/Modal.vue'
  import AdaptiveMainTopLine from '@/components/AdaptiveMainTopLine.vue'
  import { api } from '@/api'
  import router from '@/router'

  export default defineComponent({
    components: {
      MainTopLine,
      Modal3,
      AdaptiveMainTopLine
    },
    setup() {
      const data = reactive({
        fullname: '',
        phone: '',
        gender: '',
        old_password: '',
        new_password: '',
        new_password_repl: '',
        email: ''
      })
      const active = ref(false)
      const changeactive = ref(false)
      const profile = ref({})
      const reports = ref({})
      const passwordactive = ref(false)
      const profileupdateactive = ref(false)
      let linkPage = ref([])
      const inputPassword1 = ref<HTMLInputElement>()
      const inputPassword2 = ref<HTMLInputElement>()
      const inputPassword3 = ref<HTMLInputElement>()
      const togglePasswordAttribute1 = () => {
        if (inputPassword1.value) {
          if (inputPassword1.value.type == 'text') {
            inputPassword1.value.type = 'password'
          } else {
            inputPassword1.value.type = 'text'
          }
        }
      }
      const togglePasswordAttribute2 = () => {
        if (inputPassword2.value) {
          if (inputPassword2.value.type == 'text') {
            inputPassword2.value.type = 'password'
          } else {
            inputPassword2.value.type = 'text'
          }
        }
      }
      const togglePasswordAttribute3 = () => {
        if (inputPassword3.value) {
          if (inputPassword3.value.type == 'text') {
            inputPassword3.value.type = 'password'
          } else {
            inputPassword3.value.type = 'text'
          }
        }
      }

      const toggleActive = () => {
        active.value = !active.value
      }

      async function getProfile() {
        try {
          const response = await api.get('/v1/profile')
          profile.value = response.data.data.user
          reports.value = response.data.data.requestedReports
          linkPage.value = response.data.data.requestedReports.links
          linkPage.value[0].label = '&#11160;'
          linkPage.value[linkPage.value.length - 1].label = '&#11162;'
        } catch (error) {
          console.error(error)
        }
      }

      if (localStorage.getItem('token')) {
        getProfile()
      } else {
        router.push({ name: 'Main' })
      }

      async function edit() {
        try {
          const response = await api.put(
            '/v1/profile/update',
            {
              fullname: data.fullname,
              phone: data.phone,
              gender: data.gender
            },
            {
              headers: {}
            }
          )
          active.value = !active.value
          window.location.reload()
        } catch (error) {
          console.error(error)
          profileupdateactive.value = true
        }
      }
      async function changePassword() {
        try {
          const response = await api.put('/v1/profile/password', {
            old_password: data.old_password,
            new_password: data.new_password,
            new_password_repl: data.new_password_repl
          })
          const response1 = await api.get('/auth/logout')
          localStorage.removeItem('token')
          localStorage.removeItem('role')
          await router.push({ name: 'Main' })
        } catch (error) {
          console.error(error)
          passwordactive.value = true
        }
      }
      async function createReport() {
        await router.push({ name: 'create-report' })
      }
      async function pageLink(url) {
        try {
          const response = await api.get(url)
          reports.value = response.data.data.requestedReports
          linkPage.value = response.data.data.requestedReports.links
          linkPage.value[0].label = '&#11160;'
          linkPage.value[linkPage.value.length - 1].label = '&#11162;'
        } catch (error) {
          console.error(error)
        }
      }
      return {
        active,
        toggleActive,
        profile,
        data,
        edit,
        changeactive,
        changePassword,
        passwordactive,
        reports,
        togglePasswordAttribute1,
        togglePasswordAttribute2,
        togglePasswordAttribute3,
        inputPassword1,
        inputPassword2,
        inputPassword3,
        profileupdateactive,
        createReport,
        pageLink,
        linkPage
      }
    }
  })
