
  import { ref } from 'vue'

  export default {
    props: {
      disabled: {
        type: Boolean,
        default: false
      }
    },
    emits: ['select'],
    setup(props, context) {
      const active = ref(false)

      const options = ref([
        { name: 'Excel File', icon: 'excel-file', type: 'excel' },
        { name: 'PDF File', icon: 'pdf-file', type: 'pdf' }
      ])

      function selectType(type) {
        context.emit('select', type)
        active.value = false
      }

      return {
        active,
        options,
        selectType
      }
    }
  }
