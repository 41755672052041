
  import { computed, onMounted, watch } from 'vue'
  import store from '@/store'
  import * as am4core from '@amcharts/amcharts4/core'
  import am4themes_animated from '@amcharts/amcharts4/themes/animated'
  import * as am4charts from '@amcharts/amcharts4/charts'

  am4core.useTheme(am4themes_animated)

  export default {
    setup() {
      let pieGtraphics

      const dataToLegend = computed(() => {
        if (!store.getters._getMonthAge) return []

        const d = store.getters._getMonthAge.map((e) => {
          return {
            ...e,
            ageGroup: e.ageGroup
          }
        })

        d[d.length - 2].ageGroup = ''
        d[d.length - 1].ageGroup = ''

        return d
      })

      function getAgeRangeTranslate(length, ind) {
        if (ind + 1 === length) return 'labels.ageRest'
        if (ind + 2 === length) return 'labels.ageMore55'
        return 'labels.ageYears'
      }

      watch(
        () => dataToLegend.value,
        (newVal) => {
          if (newVal && newVal.length) {
            pieGtraphics.data = dataToLegend.value.map((item) => {
              return {
                country: item.ageGroup,
                value: item.percentage
              }
            })
          }
        }
      )

      onMounted(() => {
        store.dispatch('_setAge')
        getGraphics()
      })

      function getGraphics() {
        pieGtraphics = am4core.create(
          'state-age__divchartpie',
          am4charts.PieChart
        )
        if (pieGtraphics.logo) pieGtraphics.logo.disabled = true
        pieGtraphics.align = 'center'
        pieGtraphics.hiddenState.properties.opacity = 0

        pieGtraphics.maxwidth = '100%'
        pieGtraphics.maxheight = '100%'
        pieGtraphics.align = 'left'

        pieGtraphics.data = [
          {
            country: 'до 18 лет',
            value: 260,
            value2: 100
          },
          {
            country: '18-25 лет',
            value: 230,
            value2: 200
          },
          {
            country: '25-35 лет',
            value: 200,
            value2: 500
          },
          {
            country: '35-45 лет',
            value: 165,
            value2: 300
          },
          {
            country: 'Старше 45 лет',
            value: 139,
            value2: 400
          }
        ]

        var series = pieGtraphics.series.push(new am4charts.PieSeries())
        series.dataFields.value = 'value'
        series.dataFields.radiusValue = 'value'
        series.dataFields.category = 'country'
        series.colors.step = 3
        series.ticks.template.disabled = true
        series.labels.template.disabled = true

        series.colors = new am4core.ColorSet()
        series.colors.list = [
          am4core.color('#0194EB'),
          am4core.color('#FB8832'),
          am4core.color('#944CEE'),
          am4core.color('#55DCE7'),
          am4core.color('#2FFC8D'),
          am4core.color('#000')
        ]

        series.labels.template.paddingTop = 0
        series.labels.template.paddingBottom = 0

        series.slices.template.tooltipText = '{value}'
        series.tooltip.getFillFromObject = false
        series.tooltip.label.fill = am4core.color('#000')
        series.tooltip.label.fontSize = 14

        series.hiddenState.properties.endAngle = -90

        pieGtraphics.radius = am4core.percent(90)
      }

      return {
        pieGtraphics,
        dataToLegend,
        getAgeRangeTranslate
      }
    }
  }
