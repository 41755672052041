
  import { defineComponent, reactive, ref } from 'vue'
  import { api } from '@/api'
  import router from '@/router'
  import { useRoute } from 'vue-router'

  export default defineComponent({
    setup() {
      const route = useRoute()
      route.params.newsId
      const data = reactive({
        id: '',
        title: '',
        content: ''
      })

      data.id = route.params.FAQId.toString()
      data.title = route.params.FAQTitle.toString()
      data.content = route.params.FAQContent.toString()
      const faqPost = async () => {
        try {
          const response = await api.put('/v1/admin/FAQ/edit/' + data.id, {
            title: data.title,
            content: data.content
          })
          await router.push({ name: 'adminfaq' })
        } catch (error) {
          console.error(error)
        }
      }
      return { faqPost, data }
    }
  })
