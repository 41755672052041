
  import { defineComponent, ref } from 'vue'
  import MainTopLine from '@/components/MainTopLine.vue'
  import MainFooter from '@/components/MainFooter.vue'
  import FAQList from '@/components/FAQList.vue'
  import AdaptiveMainTopLine from '@/components/AdaptiveMainTopLine.vue'

  export default defineComponent({
    components: {
      AdaptiveMainTopLine,
      MainTopLine,
      MainFooter,
      'faq-list': FAQList
    },
    data() {
      return {}
    },

    setup() {}
  })
