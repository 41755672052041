
  import { defineComponent, onMounted, ref, watch, reactive } from 'vue'
  import AboutSystem from '@/components/AboutSystem.vue'
  import LineChart from '@/components/LineChart.vue'
  import { api } from '@/api'

  import * as am4core from '@amcharts/amcharts4/core'
  import * as am4maps from '@amcharts/amcharts4/maps'
  import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow'
  export default defineComponent({
    components: {
      LineChart,
      AboutSystem,
    },
    setup() {
      onMounted(getWorldGraphic)

      const admin = ref([])
      let userCity = []
      let cityValues = []
      let map
      let mainCity = reactive({
        almaty: '',
        astana: '',
        shymkent: ''
      })
      let countryCity = ref()
      getAdmin()

      async function getAdmin() {
        try {
          const response = await api.get('/v1/admin/')
          admin.value = response.data.data
          let dataCity = response.data.data.cities
          for (const [key, value] of Object.entries(dataCity)) {
            userCity.push({ city: key, znach: value })
          }
          let array = []
          countryCity.value = response.data.data.kazakhstanCities
          for (let city of countryCity.value) {
            if (city.city.name_ru == '') {
            } else {
              array.push(city)
            }
          }

          countryCity.value = array
          await setCityValues()
          await getWorldGraphic()
        } catch (error) {
          console.error(error)
        }
      }
      function getWorldGraphic() {
        if (map) {
          map.dispose()
        }

        map = am4core.create('chartdiv', am4maps.MapChart)
        map.geodata = am4geodata_worldLow
        map.projection = new am4maps.projections.Orthographic()
        map.height = 500
        map.width = 500
        map.align = 'center'

        let polygonSeries = new am4maps.MapPolygonSeries()
        let series = new am4maps.MapSeries()

        polygonSeries.useGeodata = true
        map.series.push(polygonSeries)
        let imageSeries = map.series.push(new am4maps.MapImageSeries())

        let imageSeriesTemplate = imageSeries.mapImages.template
        let circle = imageSeriesTemplate.createChild(am4core.Circle)
        circle.radius = 6
        circle.fill = am4core.color('white')
        circle.stroke = am4core.color('#ca9e67')
        circle.strokeWidth = 1
        circle.nonScaling = true
        circle.tooltipText = '{title} : {value}'
        let hs = polygonSeries.mapPolygons.template.states.create('hover')
        hs.properties.fill = am4core.color('#ca9e67')
        imageSeriesTemplate.propertyFields.latitude = 'latitude'
        imageSeriesTemplate.propertyFields.longitude = 'longitude'
        map.panBehavior = 'rotateLongLat'
        imageSeries.data = cityValues
      }
      function setCityValues() {
        for (const city of userCity) {
          cityValues.push({
            latitude: city.znach.city.lat,
            longitude: city.znach.city.lon,
            title: city.city,
            value: city.znach.count
          })
        }

        let almaty = cityValues.find((item) => item.title == 'Almaty')
        let astana = cityValues.find((item) => item.title == 'Nur-Sultan')
        let shymkent = cityValues.find((item) => item.title == 'Shymkent')
        mainCity.almaty = almaty.value
        mainCity.astana = astana.value
        mainCity.shymkent = shymkent.value
      }

      return { admin, userCity, mainCity, countryCity }
    }
  })
