
  import {
    defineComponent,
    onBeforeMount,
    onMounted,
    onUpdated,
    reactive,
    ref
  } from 'vue'
  import AdminMainTopLine from '@/components/AdminMainTopLine.vue'
  import LineChart from '@/components/LineChart.vue'
  import { api } from '@/api'
  import AdminAdaptiveMainTopLine from '@/components/AdminAdaptiveMainTopLine.vue'
  import router from '@/router'
  import AdminImport from '@/components/Adminimport.vue'

  export default defineComponent({
    components: {
      AdminMainTopLine,
      LineChart,
      AdminAdaptiveMainTopLine,
      AdminImport
    },
    setup() {
      let bulletinName = ref('')
      let bulletinIsActive = ref(false)
      let checkbox = ref([])
      let moreselect = ref(false)
      let activeJoinBulletin = ref(false)
      let mainId = ref()
      let numberValues = ref()
      const bulletinTable = ref([])
      let linkPage = ref([])
      let configurationUrl
      let bulletinChecked = ref([])
      let editBulletinActive = ref(false)
      let setValue = ref([])
      let configuration = reactive({
        search: '',
        filterBy: 'location',
        perPpage: '',
        sortBy: 'name',
        asc: '0'
      })
      let pushedvalue = ref([])
      let timer
      function endAndStartTimer() {
        window.clearTimeout(timer)
        timer = window.setTimeout(function () {
          getBulletinTable()
        }, 300)
      }
      let isActiveUp = ref(false)
      let isActiveDown = ref(true)
      let editUniteValue = ref('')
      let watchEditUniteValue = ref(false)
      let deleteBulletinActive = ref(false)
      const rollBackIsActive = ref(false)
      const bulletinchangeActive = ref(false)
      let current = reactive({
        id: '',
        name: ''
      })

      async function getBulletinTable() {
        bulletinTable.value = []
        bulletinIsActive.value = false
        configurationUrl = `filter_by=${configuration.filterBy}&search_query=${configuration.search}&sort_by=${configuration.sortBy}&asc=${configuration.asc}`
        try {
          const response = await api.get(
            '/v1/admin/aliases?' + configurationUrl
          )
          bulletinTable.value = response.data.aliases.data
          linkPage.value = response.data.aliases.links
          numberValues.value = {
            atrributes: response.data.attibutes,
            bulletins: response.data.bulletins,
            locations: response.data.locations
          }

          linkPage.value[0].label = '<'
          linkPage.value[linkPage.value.length - 1].label = '>'
        } catch (error) {
          console.error(error)
        }
      }
      getBulletinTable()
      async function pageLink(url) {
        let [startUrl, endUrl] = url.split('?')
        let pageUrl = startUrl + '?' + configurationUrl + '&' + endUrl
        try {
          const response = await api.get(pageUrl)
          bulletinTable.value = response.data.aliases.data
          linkPage.value = response.data.aliases.links
          linkPage.value[0].label = '<'
          linkPage.value[linkPage.value.length - 1].label = '>'
        } catch (error) {
          console.error(error)
        }
      }
      async function pageLinkBulletin(url) {
        let [startUrl, endUrl] = url.split('?')
        let pageUrl = startUrl + '?' + '&' + endUrl
        try {
          const response = await api.get(pageUrl)
          bulletinTable.value = response.data.data
          linkPage.value = response.data.links
          linkPage.value[0].label = '<'
          linkPage.value[linkPage.value.length - 1].label = '>'
        } catch (error) {
          console.error(error)
        }
      }
      function sortTable(sort) {
        configuration.sortBy = sort
        getBulletinTable()
      }
      function sortTableArrow(arrow) {
        if (arrow == '1') {
          isActiveUp.value = true
          isActiveDown.value = false
        } else {
          isActiveDown.value = true
          isActiveUp.value = false
        }
        configuration.asc = arrow
        getBulletinTable()
      }
      function selectAll() {
        let masterCheckbox = document.querySelector('.main-checkbox')
        let slaveCheckboxes = document.querySelectorAll('.checkbox')

        if ((<HTMLInputElement>masterCheckbox).checked) {
          Array.prototype.forEach.call(slaveCheckboxes, function (checkbox) {
            checkbox.checked = true
          })
        } else {
          Array.prototype.forEach.call(slaveCheckboxes, function (checkbox) {
            checkbox.checked = false
          })
        }
        watchMoreSelect()
      }
      function jainselect() {
        pushedvalue.value = []
        let checkeds = document.querySelectorAll('.check')
        for (let checked of checkeds) {
          let check = (checked.children[0].children[0] as any).checked
          if (check) {
            let value = {
              id: checked.children[1].textContent,
              name: checked.children[2].textContent,
              unit: checked.children[4].textContent
            }
            pushedvalue.value.push(value)
          }
        }
      }
      function joinBulletin() {
        jainselect()
        activeJoinBulletin.value = true
      }
      function deleteValues(index) {
        pushedvalue.value.splice(index, 1)
      }
      async function joinPut() {
        let some = pushedvalue.value.filter((item) => item.id != mainId.value)
        let someId = some.map((item) => +item.id)
        try {
          const response = await api.put('/v1/admin/aliases/merge', {
            main_id: +mainId.value,
            secondary_ids: someId
          })
          activeJoinBulletin.value = false
        } catch (error) {
          console.error(error)
        }
      }
      function editBulletin() {
        jainselect()
        editBulletinActive.value = true
        if (configuration.filterBy == 'attribute') {
          editUniteValue.value = pushedvalue.value[0].unit

          watchEditUniteValue.value = true
        } else {
          watchEditUniteValue.value = false
        }
      }
      async function putEditBulletin() {
        if (configuration.filterBy == 'location') {
          try {
            const response = await api.put(
              '/v1/admin/aliases/' + pushedvalue.value[0].id,
              { name: pushedvalue.value[0].name }
            )
            editBulletinActive.value = false
            window.location.reload()
          } catch (error) {
            console.error(error)
          }
        } else {
          try {
            const response = await api.put(
              '/v1/admin/aliases/' + pushedvalue.value[0].id,
              {
                name: pushedvalue.value[0].name,
                unit: editUniteValue.value
              }
            )
            editBulletinActive.value = false
            window.location.reload()
          } catch (error) {
            console.error(error)
          }
        }
      }
      function watchMoreSelect() {
        let slaveCheckboxes = document.querySelectorAll('.checkbox')
        jainselect()

        if (pushedvalue.value.length <= 1) {
          moreselect.value = false
        } else {
          moreselect.value = true
        }
        Array.prototype.forEach.call(slaveCheckboxes, function (checkbox) {
          if (!checkbox.checked) {
          }
        })
      }

      function deleteBulletin() {
        jainselect()
        deleteBulletinActive.value = true
      }

      async function deletechackedBulletin() {
        let ids = pushedvalue.value.map(function (obj) {
          return +obj.id
        })

        try {
          const response = await api.delete('/v1/admin/aliases', {
            params: {
              alias_ids: ids
            }
          })
          deleteBulletinActive.value = false
        } catch (error) {}
      }
      function rollBackActiv(id, name) {
        current = {
          id: id,
          name: name
        }
        bulletinName.value = current.name
        rollBackIsActive.value = true
      }
      async function bulletinRollBack() {
        try {
          const response = await api.put('/v1/admin/aliases/rollback', {
            alias_ids: [current.id]
          })
          rollBackIsActive.value = false
        } catch (error) {
          console.error(error)
        }
      }
      async function getBulletin() {
        bulletinTable.value = []
        bulletinIsActive.value = true
        try {
          const response = await api.get(
            `/v1/bulettins?filter_By=name&asc=${configuration.asc}`
          )

          bulletinTable.value = response.data.data
          linkPage.value = response.data.links
          linkPage.value[0].label = '<'
          linkPage.value[linkPage.value.length - 1].label = '>'
        } catch (error) {
          console.error(error)
        }
      }
      function changeBulletin() {
        pushedvalue.value = []
        let checkeds = document.querySelectorAll('.check')

        for (let checked of checkeds) {
          let check = (checked.children[0].children[0] as any).checked
          if (check) {
            let value = {
              id: checked.children[1].textContent,
              name: checked.children[2].textContent
            }
            pushedvalue.value.push(value)
          }
        }

        bulletinchangeActive.value = true
      }
      async function putBulletin() {
        try {
          const response = await api.put(
            'v1/admin/bulletins/' + pushedvalue.value[0].id,
            { name: pushedvalue.value[0].name }
          )

          await getBulletin()
          bulletinchangeActive.value = false
        } catch (error) {
          console.error(error)
        }
      }
      function bulletinSortTableArrow(arrow) {
        if (arrow == '1') {
          isActiveUp.value = true
          isActiveDown.value = false
        } else {
          isActiveDown.value = true
          isActiveUp.value = false
        }
        configuration.asc = arrow
        getBulletin()
      }
      return {
        bulletinSortTableArrow,
        bulletinName,
        current,
        rollBackActiv,
        putEditBulletin,
        editBulletinActive,
        joinPut,
        deleteValues,
        getBulletinTable,
        linkPage,
        pageLink,
        pageLinkBulletin,
        bulletinTable,
        bulletinChecked,
        configuration,
        sortTable,
        endAndStartTimer,
        sortTableArrow,
        isActiveDown,
        isActiveUp,
        selectAll,
        setValue,
        joinBulletin,
        activeJoinBulletin,
        pushedvalue,
        numberValues,
        mainId,
        checkbox,
        editBulletin,
        moreselect,
        watchMoreSelect,
        editUniteValue,
        watchEditUniteValue,
        deleteBulletinActive,
        deleteBulletin,
        deletechackedBulletin,
        bulletinRollBack,
        rollBackIsActive,
        getBulletin,
        bulletinIsActive,
        changeBulletin,
        bulletinchangeActive,
        putBulletin
      }
    }
  })
