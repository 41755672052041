
import { ref, computed, onMounted } from 'vue'
import store from '@/store'

export default {
  setup() {
    const faqs = computed(() => {
      return store.getters._getFAQS
    })

    const state = ref({})

    onMounted(() => {
      store.dispatch('_fetchFAQS')
    })

    return {
      faqs,
      state
    }
  }
}
