
  import { defineComponent, ref } from 'vue'
  import AdminMainTopLine from '@/components/AdminMainTopLine.vue'
  import AdminImport from '@/components/Adminimport.vue'
  import AdminAdaptiveMainTopLine from '@/components/AdminAdaptiveMainTopLine.vue'
  import { api } from '@/api'
  import { useRoute } from 'vue-router'
  import router from '@/router'

  export default defineComponent({
    components: {
      AdminMainTopLine,
      AdminImport,
      AdminAdaptiveMainTopLine
    },
    setup() {
      const route = useRoute()
      let active = ref(false)
      let fileName = ref('')
      let importFileId = ref('')
      let changeFile = ref(false)
      var formData = new FormData()
      function fileChange(event) {
        formData.append('file', event.target.files[0])
        fileName.value = event.target.files[0].name
        active.value = true
        changeFile.value = true
      }
      async function postImportFile() {
        const response = await api.post('v1/admin/import', formData)
        importFileId.value = response.data.id

        router.push({
          name: 'adminimportnext',
          params: {
            importFileId: importFileId.value,
            fileName: fileName.value.toString()
          }
        })
      }
      function DeleteFile() {
        formData.delete('file')
        fileName.value = ''
        active.value = false
        changeFile.value = false
        let inputValue = document.getElementsByClassName(
          'file-select__file'
        )[0] as any

        inputValue.value = ''
      }

      return {
        postImportFile,
        fileChange,
        active,
        importFileId,
        changeFile,
        DeleteFile
      }
    }
  })
