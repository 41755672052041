export const kz = {
  trour: '2023 жылғы 29 қазан - Қазақстан Республикасында Жалпыұлттық аза тұту күні',
  pages: {
    tourBase: {
      title: 'Туроператорлар базасы',
      table: {
        order: '№',
        licensor: 'Лицензиар',
        number: 'Лицензия нөмірі',
        name: 'Атауы',
        iin: 'ЖСН/БСН',
        issue_at: 'Берілген күні',
        address: 'Мекен-жайы',
        subspecies: 'Кіші түрлер',
      }
    },
    home: {
      banner: {
        title: 'Tourstat.kz',
        subtitle:
          'Туризм саласындағы деректерді жинау және өңдеу жөніндегі ақпараттық жүйе'
      },
      top10MonthCountry: 'Осы айдағы ТОП 10 ел',
      title1: 'Осы айдағы туристің портреті',
      title2: 'Туризм статистикасы',
      title3: 'Дайджесттер',
      title4: 'ЕСЕП ҚҰРУ',
      title5: 'Жүйе туралы',
      title6: 'Жаңалықтар',
      title7: 'FAQ',
      createReportTitle:
        'Бұл блокта сіз өзіңізге ыңғайлы PDF немесе Excel форматында статистикалық мәліметтермен есеп жасай аласыз.',
      aboutSystem:
        'Турстат ақпараттық жүйесі республикадағы туристік қызметтің статистикалық есебін жүзеге асырады және әртүрлі бөлімдерде туристік ағынның талдауын ұсынады. \n жүйе Қазақстан Республикасы Мәдениет және спорт министрлігінің туристік саланы дамытудың 2019-2025 жылдарға арналған мемлекеттік бағдарламасын іске асыру шеңберінде құрылды.',
      systemInfo: {
        0: 'Туристік қызметтің \n статистикалық есебін жүргізу',
        1: 'Келу саны',
        2: 'Саяхат ағынының \n аналитикасы',
        3: 'Қол жетімділік \n статистикалық деректер',
        4: 'Болу мерзімі \n (қиындықтар)',
        5: 'Күнтізбелік \n жылдағы туристердің \n шығыстарының сомасы'
      }
    },
    statistics: {
      tabsMain: {
        title1: 'Туристік қызметтер',
        title2: 'Саяхат шығындары',
        title3: 'Көшпелі келушілер',
        title4: 'Маршруттар мен көрікті жерлер',
      },
      title1: 'Туризм статистикасы',
      title2: 'ЖИІ ҚАРАЛАТЫН КӨРСЕТКІШТЕР',
    },
    about: {
      title1: 'Жүйе туралы',
      title2: 'ҚАЗАҚСТАНДАҒЫ ТУРИЗМ БОЙЫНША СТАТИСТИКА: ОЛ НЕ ҮШІН ҚАЖЕТ?',
      text1: 'Турстат ақпараттық жүйесі республикадағы туристік қызметтің статистикалық есебін жүзеге асырады және әртүрлі бөлімдерде туристік ағынның талдауын ұсынады.',
      text2: 'Жүйе Қазақстан Республикасы Мәдениет және спорт министрлігінің туристік саланы дамытудың 2019-2025 жылдарға арналған мемлекеттік бағдарламасын іске асыру шеңберінде құрылған.',
      list: {
        0: 'Туристік қызметтің статистикалық есебін жүргізу',
        1: 'Статистикалық деректердің қолжетімділігі',
        2: 'Туристік ағынның аналитикасы',
        3: 'Келу саны',
        4: 'Болу мерзімі (туродни)',
        5: 'Күнтізбелік жылдағы туристер шығыстарының сомасы'
      },
      text3: 'Қазақстандағы туризм статистикасы-бұл нақты бизнес шеңберінде де, жалпы Индустрия шеңберінде де дұрыс стратегиялық шешімдер қабылдау үшін негіз. Осы ақпарат негізінде қысқа және ұзақ мерзімді даму жоспарлары құрылады, жаңа тауашалар таңдалады және пайда болады.',
      text4: 'Туризм статистикасы нарықта нақты не болып жатқанын көрсетеді. Жан-жақты объективті ақпарат сала ұйымдарына нарықтық трендтерді талдауға, болжамдар жасауға және инвестициялар, туристік өнімдер мен қызметтер спектрін кеңейту туралы шешімдер қабылдауға мүмкіндік береді.',
      text5: 'Порталдағы ақпарат туристік индустриямен байланысты барлық адамдарға пайдалы болады. Статистикалық деректер Қазақстандағы халықаралық және ішкі туризмді қозғайды, Турстат статистикасы жалпы ел деңгейіндегі және жекелеген өңірлердегі негізгі көрсеткіштерді зерделеуге мүмкіндік береді.',
      text6: 'Өңірлер бойынша Туризм статистикасы бизнесті тиімді жүргізу үшін туристік нарықтың жай-күйі туралы түсінік береді. Бұл туризм саласының негізгі нәтижелері мен даму тенденциялары туралы ақпарат ретінде ғылыми және оқу орындарына пайдалы болады.Туризм статистикасының осы сайтында берілген барлық деректерді коммерциялық, ғылыми және жеке мақсаттарда дереккөзге сілтеме жасай отырып пайдалануға болады.',
    },
    news: {
      title: 'Туризмнің соңғы жаңалықтары: барлық оқиғалардан хабардар болыңыз',
      title1: 'Жаңалықтар',
      description:
        'Біздің жаңалықтар бөлімімен туризм саласындағы барлық соңғы оқиғалардан хабардар болыңыз. Біз туристік индустрия, саяхат, экскурсиялар және қызықты орындар туралы соңғы және өзекті жаңалықтарды ұсынамыз.'
    },
    digest: {
      title: 'Халықаралық келушілер мен кетушілерді талдау',
      title1: 'Дайджест',
      description:
        'Шетелге сапарлар санын біліңіз, халықаралық туристер туралы мәліметтерді және олардың саяхаттағы негізгі мақсаттарын зерттеңіз'
    },

    default: {
      title: 'Ақпараттық жүйе мәліметтер базасы',
      description:
        'Туризм саласындағы статистикалық деректерді жинау және өңдеу жөніндегі ақпараттық жүйе.'
    },
    main: {
      title:
        'Қош келдіңіз Tourstat.kz сайтына - ол сіздің туристік деректері мен статистика көзі',
      description:
        'Tourstat.kz -сіздің туризм саласындағы сенімді серіктесіңіз! Қазақстанның туристік индустриясы туралы өзекті статистикалық деректер мен ақпаратты бізбен бірге біліңіз.'
    },
    touchBord: {
      title:
        'еQonaq ақпараттық жүйесін қолдана отырып, шетелдік туристің портретін ашыңыз',
      description:
        'Әртүрлі орналастыру орындарынан (қонақ үйлер, жатақханалар, демалыс базалары) өзекті деректерді біздің порталдан алыңыз, Tourstat.kz сайтында Қазақстандағы барлық орналастыру орындарының 60%-дан астамынан өзекті статистиканы көре аласыздар.'
    },
    statisticsThree: {
      title:
        'Туристік статистиканы зерттеу: тұру орындары, келушілер және болу ұзақтығы',
      description:
        'Туризм статистикасы әлеміне еніңіз! Орналастыру орындарының санын біліңіз, келушілер ағынын талдаңыз және олардың болу ұзақтығын зерттеңіз.'
    },
    createReport: {
      title:
        'Туризм статистикасы: PDF немесе Excel форматында ыңғайлы есеп жасаңыз',
      description:
        'Біздің қызметімізбен туристік статистика әлемін зерттеңіз. Мұнда сіз PDF немесе Excel форматында егжей-тегжейлі статистикалық мәліметтермен есепті оңай жасай аласыз.'
    },
  },
  commands: {
    enter: 'Кіру',
    download: 'Жүктеу'
  },
  labels: {
    more: 'Толығырақ',
    steps: 'Қадам {0}',
    enterSearchData:
      '“Іздеу” өрісіне іздегіңіз келетін деректерді енгізіңіз',
    selectPeriod: 'Кезеңді таңдаңыз',
    downloadReport: 'Есепті жүктеу',
    fromTourstat: 'TourStat {0}-ден',
    next: 'келесі',
    yetStat: 'Қосымша статистика',
    year: '{0} жыл',
    feedback: 'Кері байланыс',
    feedbackText: 'Егер сіз қандай да бір қатені немесе мәселені тапсаңыз, электрондық пошта арқылы бізге хабарлаңыз:',
    noDataInDB: 'Дерекқорда деректер жоқ',
    ageMore55: ' 55 және одан жоғары',
    ageRest: ' 17-тан төмен',
    ageYears: ' жыл',
    searchTourControlers: 'Туроператорды іздеу',
    find: 'Табу...',
    pageCounter: '{1} беттен {1}',
    noData: 'Мәлімет жоқ',
  },
  placeholders: {
    enterName: 'Тақырыпты енгізіңіз'
  },
  footer: {
    author: 'Авторлық құқық 2023 ⓒ Tourstat. Барлық құқықтар қорғалған.',
    sublinks: {
      1: 'Туристік қызметтер',
      2: 'Саяхат шығындары',
      3: 'Орналастыру орындары',
      4: 'Маршруттар мен көрікті жерлер'
    },
  },
  menu: {
    mainSystem: 'Басты бет',
  },

  message: {
    greet: 'Салем.',
    title: 'Такырып',
    banner: 'Жүйеде техникалық жұмыстар жүргізілуде',
    authorized:
      'Сіз жүйеге кірген жоқсыз. Есепті жүктеп алу үшін жүйеге кіруіңіз керек.'
  },
  mainHeader: {
    buttons: {
      forBusiness: 'бизнес үшін',
      forTourist: 'Турист үшін'
    },
    business: {
      link1: {
        title: 'Өз бизнесіңізді Ұлттық туристік порталға орналастырыңыз',
        subtitle: '«Коммерциялық платформа« модулі',
        link: 'https://kazakhstan.travel/marketplace'
      },
      link2: {
        title: 'Туристік экожүйе',
        subtitle: '«Туризм онлайн» модулі',
        link: 'https://www.tourismonline.kz/'
      },
      link3: {
        title: 'Гид немесе экскурсовод болу',
        subtitle: '«Гидтер» Модулі',
        link: 'https://guides.kazakhstan.travel/ru'
      },
      link4: {
        title: 'Туризм саласындағы статистиканы қараңыз',
        subtitle: '«Tourstat» Модулі',
        link: 'https://tourstat.kz/'
      },
      link5: {
        title: 'Туристік жобалардың инвесторы болу',
        subtitle: '«Инвестициялар» Модулі',
        link: 'https://invest.kazakhstan.travel/'
      },
      link6: {
        title: 'Шетелдік қонақтың келгені туралы хабарлама жіберу',
        subtitle: '«EQonaq» ақпараттық жүйесі',
        link: 'https://eqonaq.kz'
      },
      link7: {
        title: 'Субсидиялауға өтінім беру',
        subtitle: '«Субсидиялау» Модулі',
        link: 'https://subsidy.kazakhstan.travel/ru'
      },
      link8: {
        title: 'MICE іс-шараларының қатысушысы болу',
        subtitle: '«MICE» модулі',
        link: 'https://kazakhstan.travel/mice'
      }
    },
    tourist: {
      link1: {
        title: 'Қазақстан Туралы',
        subtitle: 'Ұлттық туристік портал',
        link: 'https://kazakhstan.travel/'
      },
      link2: {
        title:
          'Ең жақсы экскурсияларды, Қонақ үйлерді, автомобильдерді, лагерьлерді брондаңыз',
        subtitle: '«Коммерциялық платформа» модулі',
        link: 'https://kazakhstan.travel/marketplace'
      },
      link3: {
        title: 'Гидті немесе гидті таңдау',
        subtitle: '«Гидтер» Модулі',
        link: 'https://guides.kazakhstan.travel/ru'
      },
      link4: {
        title: 'Алтын Орданың 750 жылдығы',
        subtitle: '«Алтын орда» модулі',
        link: 'https://altynorda.kazakhstan.travel/'
      },
      link5: {
        title: 'Қазақстанның пейзаждары ең жақсы сапада',
        subtitle: '«Photobank« Модулі',
        link: 'https://kazakhstan.travel/photobank'
      },
      link6: {
        title: 'Шетелдік қонақтың келгені туралы хабарлама жіберу',
        subtitle: '«EQonaq» ақпараттық жүйесі',
        link: 'https://eqonaq.kz'
      }
    }
  },
  adaptiveTop: {
    mainSystem: 'Басты бет',
    about: 'Жүйе туралы',
    createReport: 'Есеп құру',
    digest: 'Дайжест',
    statistics: 'Туризм статистикасы',
    touchBord: 'Турист портреті',
    region: 'Аймақ',
    articles: 'Жаңалықтар',
    faq: 'FAQ',
    exit: 'Шығу',
    account: 'Жеке кабинет',
    adminPanel: 'Админ панельге өту',
    import: 'Импорт',
    bulletin: 'Булетин',
    tourBase: 'Туроператорлар базасы',
  },
  mobileTop: {
    mainSystem: 'Басты бет',
    about: 'Жүйе туралы',
    createReport: 'Есеп құру',
    statistics: 'Статистика',
    region: 'Аймақ',
    articles: 'Жаңалықтар',
    faq: 'Faq',
    exit: 'Шығу'
  },
  main: {
    mainAboutSystem:
      'Жүйе туристтік саланы 2019-2025 жылдар аралығында дамыту Мемлекеттік бағдарламасы аясында Қазақстан Республикасы спорт және мәдениет министірлігімен әзірленген',
    welcome:
      '<b>Tourstat.kz</b> мәліметтер базасы ақпараттық жүйесіне <b>қош келдіңіз!</b>',
    information:
      'Ақпараттық жүйе туризм бағытында статистикалық мәліметтерді жинауға және өңдеуге арналған.',
    enter: 'Кіру',
    frequently: 'ЖИІ КӨРІНЕТІН КӨРСЕТКІШТЕР',
    aboutSystem:
      'Турстат ақпараттық жүйесі республикадағы туристік қызметтің статистикалық есебін жүзеге асырады және әртүрлі бөлімдердегі туристік ағымның талдауын ұсынады. Жүйе туристік саланы дамытудың 2019-2025 жылдарға арналған мемлекеттік бағдарламасын іске асыру шеңберінде Қазақстан Республикасы Мәдениет және спорт министрлігімен құрылған. Турстат ақпараттық жүйесі республикадағы туристік қызметтің статистикалық есебін жүзеге асырады және әртүрлі бөлімдердегі туристік ағымның талдауын ұсынады. Туризм статистикасы осы қызметтің барлық аспектілерін қамтиды, бұл нарықтың объективті көрінісін алуға мүмкіндік береді. Қазақстандағы туризм индустриясы ұлттық экономиканың басым салаларының бірі болып табылады. Ол өңірлерді дамыту үшін өсу драйверіне айналуға қабілетті: шағын және орта бизнестің қызметін ынталандыру, жаңа жұмыс орындарын құру және бюджетке түсетін түсімдерді ұлғайту.',
    next: 'әрі қарай',
    search: 'Сайттан іздеу',
    statistics: 'Статистика',
    mainTask: 'Негізгі мақсаттар мен көрсеткіштер',
    tour: 'Туристік қызмет бойынша статистиканы жүргізу',
    stat: 'Cтатистикалық мәліметтердің қол жетімділігі',
    analitics: 'Туристік ағымдардын аналитикасы',
    count: 'Келушілер саны',
    bulletinstat: 'Орналастыру орындарының саны',
    bulletinCount: 'Қазақстан Республикасы бойынша барлығы - 3488 бірлік',
    date: 'Болу ұзақтығы (тур күндері)',
    sum: 'Күнтізбелік жылда туристтер шығындары',
    otherArticles: 'Басқа жаңалықтар',
    linkArticles: 'Жаңалықтар бөліміне өту',
    address: 'Мекен-жайы:',
    city: 'Астана қаласы',
    place: 'Тұран даңғ., 1 ү, 14 кеңсе',
    phone: 'Телефон:',
    bulletins: 'Есепті құрау',
    dynamic: 'Диаграмма түріндегі статистикалық деректер',
    summary: 'Диаграмма түріндегі жинақталған статистикалық деректер',
    selectOrInput: 'Тізімнен қажетті көрсеткіштерді таңдаңыз немесе енгізіңіз',
    inputName: 'Еңгізіңіз',
    selectTypeDiagramm: 'Диаграмма түрін таңдаңыз',
    selectAttribute: 'Көрсеткіштерге сәйкес параметрді таңдаңыз',
    selectCity: 'Қаланы көрсетіңіз',
    noData: 'Деректерді шығару үшін қажетті көрсеткішті таңдаңыз',
    fromDate: '*күннен бастап',
    toDate: '*күнге дейін',
    title: 'Бюллетень Тақырыбын таңдаңыз',
    statisticText:
      'Статистика дегеніміз - бұқаралық қоғамдық құбылыстардың сандық жағын сандық тұрғыдан зерттей отырып, жаппай статистикалық (сандық немесе сапалық) деректерді жинау, өлшеу, бақылау, талдау және оларды салыстырудың жалпы мәселелерін анықтайтын білім, ғылым саласы. Туризм статистикасы мемлекет үшін де, бизнес үшін де өте маңызды. Туризм - бұл маркетинг пен байыпты жоспарлауды қажет ететін экономиканың ірі саласы. Туризм елге ЖІӨ-нің өте жоғары пайызын бере алады. Алдағы жылдары мемлекет туристік саланың ЖІӨ-ге әсерін 15% - ға дейін көтеруді жоспарлап отыр.',
    numberOfPlaces: 'Орналастыру орындарының саны',
    totalOfRepublicKazakhstan: 'Қазақстан Республикасы бойынша барлығы',
    units: 'бірлік',
    percent: 'пайыз',
    occupancyHotels: 'Қонақүйлердің толымдылығы',
    namebulletin: {
      name: '',
      text: 'Қазақстан Республикасы бойынша барлығы - <strong>{0}</strong>'
    },
    taskName: 'ҚАЗАҚСТАНДАҒЫ ТУРИЗМ БОЙЫНША СТАТИСТИКА: ОЛ НЕ ҮШІН ҚАЖЕТ?',
    aboutSystemSystem: {
      p1: {
        b: 'Қазақстандағы туризм статистикасы',
        p: ' - бұл нақты бизнес шеңберінде де, жалпы Индустрия шеңберінде де дұрыс стратегиялық шешімдер қабылдау үшін негіз болып табылады. Осы ақпарат негізінде қысқа мерзімді және ұзақ мерзімді даму жоспарлары жасалады, жаңа тауашалар таңдалады және пайда болады.'
      },
      p2: {
        b: 'Туризм статистикасы',
        p: ' нарықта нақты не болып жатқанын көрсетеді. Жан-жақты объективті ақпарат сала ұйымдарына нарықтық трендтерді талдауға, болжамдар жасауға және инвестициялар, туристік өнімдер мен қызметтер спектрін кеңейту туралы шешімдер қабылдауға мүмкіндік береді.'
      },
      p3: {
        p1: 'Порталдағы ақпарат туристік индустриямен байланысты барлық адамдарға пайдалы болады. Статистикалық деректер Қазақстандағы халықаралық және',
        b: ' ішкі туризмге ',
        p2: 'қатысты, Турстат статистикасы жалпы ел мен жекелеген өңірлер деңгейіндегі негізгі көрсеткіштерді зерттеуге мүмкіндік береді.'
      },
      p4: {
        b1: 'Өңірлер бойынша Туризм статистикасы',
        p1: ' бизнесті тиімді жүргізу үшін туристік нарықтың жай-күйі туралы түсінік береді. Бұл ғылыми және оқу орындарына туризм саласын дамытудың негізгі қорытындылары мен үрдістері туралы ақпарат ретінде пайдалы болады. Туризм статистикасының осы ',
        b2: 'веб-сайтындағы ',
        p2: 'барлық деректерді дереккөзге сілтеме жасай отырып, коммерциялық, ғылыми және жеке мақсаттарда пайдалануға болады.'
      }
    }
  },
  statistics: {
    types: 'Виды Диаграмм',
    bar: 'Столбчатая (BarGraph)',
    line: 'Линейная (LineChart)',
    histogram: 'Гистограмма (BarHistograms)',
    pie: 'Круговая (PieCharts)',
    donut: 'Интерактивная кольцевая (DoughnutCharts)',
    sun: 'Лучевая (Sunburts)',
    multi: 'Гистограмма с несколькими осями (Multi-AxisCharts)',
    linkStatistics: 'СТАТИСТИКА БӨЛІМІНЕ ӨТУ ',
    onerParameter: 'Бір параметрді таңдаңыз',
    parameter: 'Параметр таңдаңыз',
    table: 'Таблица',
    selectLocation: 'Орынды таңдаңыз',
    selectOption:
      'Диаграммалардың басқа түрлерін көрсету үшін "Бір атрибут" немесе "Бір орын" опцияларын таңдаңыз.',
    byRegion: 'Аймақ бойынша',
    ByIndicators: 'Көрсеткіштер бойынша',
    OnYears: 'Жылдар бойынша'
  },
  app: {
    download: 'жүктеу',
    region: 'АЙМАҚ',
    aboutSystem: 'ЖҮЙЕ ТУРАЛЫ',
    mainSystem: 'БАСТЫ БЕТ',
    adaptiveClose: 'Жабу',
    createReport: 'ЕСЕП ҚҰРУ',
    statistic: 'СТАТИСТИКА',
    articles: 'ЖАҢАЛЫҚТАР',
    faq: 'FAQ',
    nur_sultan: 'Астана',
    almaty: 'Алматы',
    shymkent: 'Шымкент',
    aqmola: 'Ақмола облысы',
    aqtobe: 'Ақтөбе облысы',
    almatyObl: 'Алматы облысы',
    atyrau: 'Атырау облысы',
    vko: 'Шығыс Қазақстан облысы',
    jambyl: 'Жамбыл облысы',
    zko: 'Батыс Қазақстан облысы',
    karagandy: 'Қарағанды ​​облысы',
    qostanai: 'Қостанай облысы',
    qyzylorda: 'Қызылорда облысы',
    mangystau: 'Маңғыстау облысы',
    pavlodar: 'Павлодар облысы',
    sko: 'Солтүстік Қазақстан облысы',
    turkistan: 'Түркістан облысы',
    lichKab: 'Жеке кабинет',
    adminPanel: 'Админ панельге өту',
    exit: 'ШЫҒУ',
    creatingReport: 'Есепті құру',
    currentPage:
      'Осы бетте статистикалық деректер есебін өзіңізге ыңғайлы PDF немесе Excel форматында жүктей аласыз.',
    stepOne: '1 ҚАДАМ',
    insertDataToSearch: '"Іздеу" қатарына іздегіңіз келген деректі жазыңыз',
    insertText: 'Іздеу',
    stepTwo: '2 ҚАДАМ',
    selectPeriod: 'Уақыт аралығын таңдаңыз',
    stepThree: '3 ҚАДАМ',
    downloadReport: 'Есепті жүктеп алыңыз'
  },
  profile: {
    errorLoginMassage:
      'Ескі құпия сөз дұрыс енгізілмеген және / немесе құпия сөздер сәйкес келмейді. Көріңіз тағы да.',
    errorMessage: ' Сұрау қатесі. Көріңіз тағы да.',
    phone: 'Телефон номері:',
    gender: 'Жынысы:',
    mail: 'пошта:',
    edit: 'өңдеу',
    editData: 'Ақпараттарды түзету',
    FIO: 'Тегі Аты Әкесінің аты',
    man: 'Ер',
    woman: 'Әйел',
    email: 'e-mail ',
    save: 'сақтау',
    cancel: 'Кері қайту',
    changePwd: 'құпия сөзді өзгерту',
    changePwdModal: 'Құпия сөзді өзгерту',
    oldPwd: 'Ескі құпия сөз  ',
    newPwd: 'Жаңа құпия сөз ',
    confirmPwd: 'Жаңа құпия сөзді қайталаңыз',
    reports: 'Менің Есептім',
    reportsSubTitle: 'Өзіңізге ыңғайлы пішімінде есептеме құжатты таңдаңыз',
    newReports: 'жаңа есеп құру',
    logIn: 'кіру'
  },
  admin: {
    createUser: 'Пайдаланушыны қосу',
    role: 'Роль',
    admin: 'Админ',
    guide: 'Гид',
    content_manger: 'Контент-Менеджер',
    fullName: 'Тегі Аты Әкесінің аты',
    pwd: 'Құпия сөз',
    confirmPwd: 'Құпия сөзді растаңыз',
    save: 'сақтау',
    cancel: 'кері қайту',
    name: 'Аты',
    mail: 'Пошта',
    operation: 'Операциялар',
    change: 'Өңдеу',
    delete: 'Жою',
    changeData: 'Деректерді өзгерту',
    yes: 'иә',
    no: 'жоқ',
    createArticle: 'Жаңалық қосу',
    title: 'Тақырып',
    subtitle: 'Тақырыпша',
    alias: 'Қысқаша сипаттамасы',
    content: 'Контент',
    image: 'Сурет',
    deleteArticle: 'Жаңалықты жою',
    visualization: 'Визуализация',
    import: 'Импорттау',
    importManual: 'Қолмен импорттау',
    importStat: 'StatGov-тан импорттау',
    continue: 'Жалғастыру',
    dynamic: 'Динамикалық кестелер',
    edit: 'Баптау'
  },
  login: {
    back: 'кері қайту',
    lk: 'Жеке кабинетке кіру',
    pwd: 'Құпия сөз',
    enter: 'кіру',
    create: 'Жеке кабинет ашу',
    reg: 'тіркелу',
    regis: 'Тіркелу',
    confirmPwd: 'жаңа құпия сөзді растаңыз',
    confirmPwdRegistration: 'Құпия сөзді растаңыз',
    rpwd: 'Құпия сөз ойлап табыңыз',
    forgotPassword: 'Құпия сөзді ұмыттыңыз ба? ',
    errorLogin: ' Қате логин немесе Құпия сөз',
    loginSuccessful: 'Сәтті! поштаны тексеріңіз'
  },
  adminApp: {
    main: 'Басты бет',
    toSite: 'Сайтқа өту',
    users: 'Қолданушылар',
    statistics: 'Статистика',
    articles: 'Жаңалықтар',
    online: 'Сайтта',
    viewed: 'Бір айда қаралды',
    accounts: 'Тіркелді',
    download: 'Файл жүктелді',
    staticPages: 'Статикалық беттер'
  },
  panelAdminArticles: {
    createArticle: 'Жаңалық қосу',
    saveNews: ' Жаңалықты сақтау',
    Preview: 'Алдын-ала қарау',
    LoadingWait: 'Жүктеуде! Күтіңіз',
    errorOccurred: 'қате кетті'
  },
  panelAdminFaq: {
    AddaFaq: 'FAQ қосу',
    changeFaq: ' FAQ Өзгерту',
    title: 'Атауы',
    operations: 'Операциялар',
    reallyWantMessage: 'Сіз шынымен деректерді жойғыңыз келе ме?',
    editData: 'Деректерді өзгерту',
    question: 'Сұрақ',
    Answer: 'Жауаб',
    CreateTab: 'вкладка жасау'
  },
  multiLogo: {
    logoMCS: 'MCS_Logos_KAZlat.png'
  },
  touchbord: {
    total: 'Барлығы',
    title: 'ШЕТЕЛДІК ТУРИСТТІҢ ПОРТРЕТІ',
    darkStyle: 'Қараңғы режим',
    lightStyle: ' Жарық режимі',
    discription: `Нақты уақыттағы деректер орналастыру орындарынан (қонақ үйлер, жатақханалар, демалыс орталықтары) автоматтандырылған мәліметтерді жинау есебінен «eQonaq» ақпараттық жүйесімен қамтамасыз етіледі. Қазіргі уақытта жүйе Қазақстандағы орналастыру орындарының 60%-дан астамында шетелдік туристердің есебін жүргізеді.`,
    region: 'аймақ бойынша',
    city: 'қала бойынша',
    low: 'төмен',
    normal: 'қалыпты',
    high: 'жоғары',
    more: 'көп',
    touristsByCountry: 'Елдер бойынша туристер саны',
    hintContent:
      'Бұл инфографикадан туристер «eQonaq» жүйесінде тіркелген орналастыру орындарына қай елдерден келгенін көре аласыз.',
    popularCountries: 'ТОП 10 танымал елдер',
    popularRegions: 'ТОП 10 танымал аймақ',
    hintContentTop:
      'Бұл диаграмма «eQonaq» жүйесінде тіркелген орналастыру орындарына туристер келген ТОП-10 елдің рейтингін көрсетеді.',
    hintContentTopRegion:
      'Бұл диаграмма «eQonaq» жүйесінде тіркелген орналастыру орындарына туристер келген ТОП-10 аймақ рейтингін көрсетеді.',
    Portrait: 'Турист портреті',
    hintContentPortrait:
      'Бұл диаграмма «eQonaq» жүйесінде тіркелген орналастыру орындарындағы туристердің жынысын және олардың жас тобын көрсетеді.',
    gender: 'Жынысы',
    man: 'Еркек',
    woman: 'Әйел',
    TouristAge: 'Туристік жас',
    purpose: 'Туристердің Қазақстанға келу мақсаты',
    hintContentPurpose:
      'Қонақ үйге келген туристердің келу мақсаты туралы мәліметтер «eQonaq» жүйесінде тіркелген.',
    placements: 'Үздік 10 орналастыру',
    hintContentPlacements:
      'Бұл диаграмма туристер ең көп барған ТОП-10 орналастыру орындарының рейтингін көрсетеді. Рейтинг «eQonaq» жүйесінде тіркелген орналастырулар бойынша құрастырылады.',
    hintTitle: 'Облыстар бөлінісінде келген шетелдік туристер саны',
    hintDescription:
      'Осы инфографикада сіз облыстар немесе қалалар бойынша шетелдік туристердің келу арақатынасын көре аласыз. Деректер нақты уақыт режимінде "eQonaq"ақпараттық жүйесімен жіберіледі.'
  },
  breadCrumbs: {
    main: 'Басты бет',
    TourismStatistics: 'Туризм статистикасы',
    TouristServices: 'Туристік қызметтер',
    TravelExpenses: 'Іссапар шығындары',
    OutboundVisitors: 'Орналастыру орындары',
    StatisticsRoutesAndAttractions: 'Маршруттар мен аттракциондар'
  },
  fequently: {
    FrequentlyViewed: 'Жиі көрінетін көрсеткіштер'
  },
  statisticsResult: {
    indicator: 'Көрсеткіш',
    region: 'Аймақ',
    year: 'Жыл',
    download: 'Есепті жүктеу',
    table: 'Кесте',
    chart: 'Графика',
    prediction: 'Графикте қосымша болжамды деректер бар'
  },
  day: 'Бүгін',
  week: 'Апта',
  month: 'Ай',
  year: 'Жыл',
  quater: '{0} тоқсан',
  quaterTitle: 'Тоқсан',
  period: 'Период',
  regions: {
    'Западно-Казахстанская область': 'Батыс Қазақстан облысы',
    'Павлодарская область': 'Павлодар облысы',
    'Алматинская область': 'Алматы облысы',
    'Жамбылская область': 'Жамбыл облысы',
    'Акмолинская область': 'Ақмола облысы',
    'Карагандинская область': 'Қарағанды облысы',
    'Северо-Казахстанская область': 'Солтүстік Қазақстан облысы',
    'Восточно-Казахстанская область': 'Шығыс Қазақстан облысы',
    'Актюбинская область': 'Ақтөбе облысы',
    'Атырауская область': 'Атырау облысы',
    'Кызылординская область': 'Қызылорда облысы',
    'Костанайская область': 'Қостанай облысы',
    'Мангистауская область': 'Маңғыстау облысы',
    'Туркестанская область': 'Түркістан облысы',
    'Южно-Казахстанская область': 'Оңтүстік Қазақстан облысы'
  }
}
