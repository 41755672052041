const graphColors = [
  '#4DB6AC',
  '#81C784',
  '#4DD0E1',
  '#AED581',
  '#DCE775',
  '#F06292',
  '#BA68C8',
  '#E57373',
  '#FF8A65',
  '#FFB74D',
  '#FFB74D',
  '#4FC3F7',
  '#78DFEC',
  '#6AAECC',
]

export default graphColors
