import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  ref: "canvasElem",
  id: "myChart",
  width: "1200",
  height: "600"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock("canvas", _hoisted_1, null, 512))
}