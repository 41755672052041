
import { ref, computed, watch, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { api } from '@/api/index.ts'
import { useRouter, useRoute } from 'vue-router'

import TabHead from '@/components/ui/TabHead.vue'

const statTranslates = {
  en: 'name_en',
  ru: 'name',
  kz: 'name_kz'
}

export default {
  components: { TabHead },
  props: {
    body: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const { t: $t, locale } = useI18n()
    const $router = useRouter()
    const $route = useRoute()

    const BulletinIcons = [
      'koyko',
      'obem',
      'obslujen',
      'kol_nomer',
      'kol_mest',
      'kol_obs',
      'edin_vmes',
      'obs_pos',
      'zap_gost'
    ]

    const activeTab = ref(0)

    const tabs = computed(() => [
      { id: 0, name: $t('pages.statistics.tabsMain.title1'), icon: require('@/assets/icons/stat-categ-1.png'), activeIcon: require('@/assets/icons/stat-categ-1-active.png') },
      { id: 2, name: $t('pages.statistics.tabsMain.title2'), icon: require('@/assets/icons/stat-categ-2.png'), activeIcon: require('@/assets/icons/stat-categ-2-active.png') },
      { id: 1, name: $t('pages.statistics.tabsMain.title3'), icon: require('@/assets/icons/stat-categ-3.png'), activeIcon: require('@/assets/icons/stat-categ-3-active.png') },
      { id: 3, name: $t('pages.statistics.tabsMain.title4'), icon: require('@/assets/icons/stat-categ-4.png'), activeIcon: require('@/assets/icons/stat-categ-4-active.png') },
    ])
    const BULL_MAX = 4
    const bulletins = ref([])
    const bulletinLMore = computed(() => {
      return bulletins.value.length > BULL_MAX
    })
    const bulletinLActive = ref(false)

    const category = computed(() => {
      return tabs.value[activeTab.value].id + 1
    })

    const getBulletins = computed(() => {
      return bulletinLActive.value ? bulletins.value : bulletins.value.slice(0, BULL_MAX)
    })

    async function getFrequentlyViewed() {
      try {
        const response = await api.get(
          `/v1/chart/categories/${category.value}/bulletinsV2`, {
            headers: {
              'Accept-Language': null
            }
          })
        bulletins.value = response.data.data
      } catch (error) {
        console.error(error)
      }
    }

    watch(() => activeTab.value, () => {
      bulletinLActive.value = false
      getFrequentlyViewed()
    })

    // watch(() => $route.params.categoryId, () => {
    //   const ind = tabs.value.findIndex((e) => e.id == Number($route.params.categoryId))
    //   activeTab.value = ind > -1 ? ind : 0
    // }, { immediate: true })

    onMounted(() => {
      activeTab.value = localStorage.getItem('selectedBulletenId') ? +localStorage.getItem('selectedBulletenId') : 0
      getFrequentlyViewed()
    })

    function changePage(val) {
      activeTab.value = val
      localStorage.setItem('selectedBulletenId', String(val))
      $router.push('/statistics-three')
    }

    function* getImage() {
      let i = 0

      while (true) {
        if (i >= BulletinIcons.length - 1) i = 0
        else i += 1
        yield BulletinIcons[i]
      }
    }

    const gen = getImage()

    function getNextImg() {
      return gen.next().value
    }

    function getStatTreeName(stat) {
      return stat[statTranslates[locale.value]]
    }

    return {
      getStatTreeName,
      BULL_MAX,
      activeTab,
      tabs,
      getBulletins,
      bulletinLMore,
      bulletinLActive,
      category,
      getNextImg,
      changePage
    }
  }
}
