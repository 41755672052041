
export default {
  name: 'SearcBlock',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: String
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    timeout: {
      type: Number,
      default: 500
    }
  },
  data() {
    return {
      timerId: null
    }
  },
  methods: {
    updateValue(e) {
      if (this.timerId) {
        clearTimeout(this.timerId);
        this.timerId = null;
      }

      this.timerId = setTimeout(() => {
        this.$emit('update:modelValue', e.target.value);
      }, this.timeout);
    }
  },
}
