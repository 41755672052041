
  import { defineComponent, reactive, ref } from 'vue'
  import AdminMainTopLine from '@/components/AdminMainTopLine.vue'
  import LineChart from '@/components/LineChart.vue'
  import { api } from '@/api'
  import AdminAdaptiveMainTopLine from '@/components/AdminAdaptiveMainTopLine.vue'
  import router from '@/router'

  export default defineComponent({
    components: {
      AdminMainTopLine,
      LineChart,
      AdminAdaptiveMainTopLine
    },
    setup() {
      let timer
      const bulettins = ref([])
      let changeactive = ref(false)
      let changeactive1 = ref(false)
      let qid = ref('')
      const data = reactive({
        title: '',
        content: '',
        word: ''
      })
      let linkPage = ref([])

      async function getFaq() {
        try {
          const response = await api.get('/v1/bulettins')
          bulettins.value = response.data.data
          linkPage.value = response.data.data.links
          linkPage.value[0].label = '&#11160;'
          linkPage.value[linkPage.value.length - 1].label = '&#11162;'
        } catch (error) {
          console.error(error)
        }
      }
      getFaq()

      // async function changeFaqById(id) {
      //     await router.push({
      //         name: 'PanelAdminFAQChange',
      //         params: {
      //             FAQId: id,
      //             FAQTitle: data.title,
      //             FAQContent: data.content
      //         }
      //     })
      // }
      // function endAndStartTimer() {
      //     window.clearTimeout(timer)
      //     timer = window.setTimeout(function() {
      //         searchArticles()
      //     }, 300)
      // }
      // async function searchArticles() {
      //     try {
      //         const response = await api.post('/v1/admin/FAQ/search', {
      //             word: data.word
      //         })
      //         bulettins.value = response.data.data
      //     } catch (error) {}
      // }
      // async function pageLink(url) {
      //     try {
      //         const response = await api.get(url)
      //         bulettins.value = response.data.data
      //         linkPage.value = response.data.data.links
      //         linkPage.value[0].label = '&#11160;'
      //         linkPage.value[linkPage.value.length - 1].label = '&#11162;'
      //     } catch (error) {
      //         console.error(error)
      //     }
      // }
      return {
        bulettins
        // removeFaqById,
        // changeactive,
        // changeactive1,
        // qid,
        // changeFaqById,
        // data,
        // endAndStartTimer,
        // linkPage,
        // pageLink
      }
    }
  })
