
import { defineComponent, onMounted, onUpdated, ref } from 'vue'
import { useRoute } from 'vue-router'
import { getDigestid } from '@/api/digest'
import store from '@/store'
export default defineComponent({
  name: 'DigestId',
  components: {},
  setup() {
    const data = ref({ description_ru: '', name_ru: '', embed: '' })
    const frame = ref()
    let contentFrame
    const route = useRoute()
    onMounted(async () => {
      try {
        store.commit('setLoading', true)
        data.value = await getDigestid(
          route.params.categoryId,
          route.params.id
        )
      } catch (error) {
        console.error(error)
      } finally {
        store.commit('setLoading', false)
      }
    })
    onUpdated(() => {
      store.commit('setLoading', true)
      frame.value.children[0]
      contentFrame = frame.value.children[0]
      contentFrame.style.width = '100%'
      contentFrame.addEventListener('load', function (e) {
        store.commit('setLoading', false)
      })
    })
    return {
      route,
      data,
      frame
    }
  }
})
