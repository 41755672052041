
import { ref, computed, PropType } from 'vue'

export default {
  props: {
    title: {
      type: String as PropType<string>,
      required: true
    },
    modelValue: {
      type: [Object, String],
      required: true
    },
    options: {
      type: Array as PropType<any[]>,
      default() {
        return []
      }
    },
    field: {
      type: String,
      required: false
    }
  },
  emits: ['update:modelValue', 'change'],
  setup(props, context) {
    const isActive = ref(false)

    const getValue = computed(() => (elem) => {
      return typeof elem === 'object' && props.field ? elem[props.field] : elem
    })

    const getSelected = computed(() => {
      return props.modelValue ? getValue.value(props.modelValue) : getValue.value(props.options[0])
    })

    function select(value) {
      isActive.value = false
      context.emit('update:modelValue', value)
      context.emit('change', value)
    }

    return {
      isActive,
      getValue,
      getSelected,
      select
    }
  }
}
