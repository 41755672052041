
  import { defineComponent, ref } from 'vue'
  
  import BreadCrumbs from '@/components/ui/BreadCrumbs.vue'
  import SectionTitle from '@/components/ui/SectionTitle.vue'
  import AboutList from '@/components/pages/about-system/AboutList.vue'

  export default defineComponent({
    components: {
      BreadCrumbs,
      SectionTitle,
      AboutList,
    },
    setup() {
      const list = ref([
        { icon: 'about-icon-1', text: 'pages.about.list.0' },
        { icon: 'about-icon-2', text: 'pages.about.list.1' },
        { icon: 'about-icon-3', text: 'pages.about.list.2' },
        { icon: 'about-icon-4', text: 'pages.about.list.3' },
        { icon: 'about-icon-5', text: 'pages.about.list.4' },
        { icon: 'about-icon-6', text: 'pages.about.list.5' },
      ])

      return {
        list
      }
    }
  })
