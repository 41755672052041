
  import { defineComponent, ref } from 'vue'
  import SwitchLanguage from '@/components/main/SwitchLanguage.vue'
  export default defineComponent({
    components: { SwitchLanguage },
    setup() {
      const active = ref(false)
      const activeButton = ref(false)
      const activeOblost = ref(false)

      const toggleActiveButton = () => {
        activeButton.value = !activeButton.value
      }

      return {
        activeButton,
        active,
        toggleActiveButton,
        activeOblost
      }
    }
  })
