
  import { defineComponent, reactive, ref } from 'vue'
  import { api } from '@/api'
  import router from '@/router'

  export default defineComponent({
    setup() {
      const data = reactive({
        title: '',
        content: ''
      })
      const faqPost = async () => {
        try {
          const response = await api.post('/v1/admin/FAQ/create', {
            title: data.title,
            content: data.content
          })
          await router.push({ name: 'adminfaq' })
        } catch (error) {
          console.error(error)
        }
      }
      return { faqPost, data }
    }
  })
