
  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      href: {
        type: String,
      }
    }
  }
