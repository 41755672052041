
  import { defineComponent, ref } from 'vue'
  import MainTopLine from '@/components/MainTopLine.vue'
  import { api } from '@/api/index'

  export default defineComponent({
    components: {
      MainTopLine
    },
    setup() {
      let files = ref()
      let modalIsActive = ref(false)
      let checkNofile = ref(false)
      files.value = globalThis.ImportStatGovFile
      if (files.value.data.length <= 0) {
        checkNofile.value = true
      } else {
        checkNofile.value = false
      }
      async function pushFile(file) {
        try {
          const dicId = file.dicId.replace(' + ', ',')
          const response = await api.get(
            `/v1/admin/import/statgov-new?id=${files.value.id}&title=${file.names}&termIds=${file.termIds}&dicId=${dicId}&idx=${file.idx}&p_period_id=5`
          )
          console.log('response', response)
          modalIsActive.value = true
        } catch (error) {
          console.error(error)
        }
      }
      return { files, checkNofile, pushFile, modalIsActive }
    }
  })
